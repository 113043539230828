import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const BulkStudentRegi = () => {

    useTitle("수강생 일괄 등록");

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const navigate = useNavigate();

    const [files, setFiles] = useState(null);

    // 샘플파일 다운로드
    const downloadSample = () => {

        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/files/tuteeBulkSample.xlsx`;
        link.setAttribute('download', '수강생 업로드 양식 샘플.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };

    // 파일 업로드 시
    const handleFileChange = async (e) => {
        setFiles(e.target.files[0]);
    };

    // 일괄 등록
    const insertBulkTutee = async () => {

        if (!files) {
            alert("파일을 업로드해주세요.");
            return;
        }

        const formData = new FormData();
        formData.append('excel_file', files);
        
        try {
            const response = await axios.post(`${apiUrl}/tutee/insertbulk`, formData, {
                headers: {
                    token: accessToken
                }
            });

            var res = response.data;
            //console.log(res);

            if (res.success) {
                if(res.data.successcount === res.data.totalcount){
                    alert(`총 ${res.data.totalcount}명 등록 성공하였습니다.`);
                }else{
                    alert(`총 ${res.data.totalcount}명 중 ${res.data.successcount}명 등록 성공하였고, ${res.data.failcount}명 등록 실패하였습니다. \n${res.data.message}`);
                }
                
                navigate('/AllStudent');
            } else {
                alert("일괄 등록에 실패하였습니다.");
            }
        } catch (error) {
            console.log(error);
            alert("일괄 등록에 실패하였습니다.");
        }
    };

    // 이전 페이지로
    const goBack = () => {
        navigate('/AllStudent');
    }

    return (
        <>
            <PageTitle motherMenu={"회원관리"} grandMenu={"수강생관리"} grandMenuLink="/AllStudent" activeMenu={"수강생 일괄 등록하기"}  />
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">일괄 등록</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="sample_file">샘플파일</label>
                                            <button type="button" className="btn btn-primary light" onClick={downloadSample}>샘플 파일 다운로드</button>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-sm-12">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="file">일괄 등록 파일</label>
                                            <input type="file" id='file' className="form-control" data-default-file="" onChange={handleFileChange} required />
                                        </div>
                                    </div>
                                    <div className="mt-5 col-lg-12 col-md-12 col-sm-12 ">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary" onClick={insertBulkTutee}>일괄등록</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BulkStudentRegi;