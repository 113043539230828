import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Col, Row, Card, Modal, Button } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Select from 'react-select';
import { DatePicker } from 'rsuite';

import PageTitle from "../../layouts/PageTitle";

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const Schedule = () => {

    useTitle("일정관리");

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    var today = new Date();
    var nowYear = today.getFullYear();
    var nowMonth = (today.getMonth() + 1).toString().padStart(2, '0');

    const calendarRef = useRef(null);
    const [viewType, setViewType] = useState('dayGridMonth');
    const [startdate, setStartdate] = useState(null);
    const [enddate, setEnddate] = useState(null);

    const [calendarEvents, setCalendarEvents] = useState([]);

    // 모달
    const [modalCentered, setModalCentered] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const [teamList, setTeamList] = useState([]);
    const [teamname, setTeamname] = useState('');

    const [formData, setFormData] = useState({
        teamid: null,
        calendarid: '',
        contents: '',
        startdate: null,
        enddate: null,
        background: '',
    });

    useEffect(() => {

        setNewDate(new Date(nowYear + "-" + nowMonth + "-01"));

        getTeamList();

    }, []);

    useEffect(() => {

        if(enddate){
            getCalendarList();
        }

    }, [enddate]);

    // 일정 리스트 가져오기
    const getCalendarList = async () => {

        try {
            const response = await axios.get(apiUrl+'/calendar/list?startdate='+startdate+'&enddate='+enddate, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        if(info.startdate != info.enddate){
                            info.enddate = new Date(info.enddate);
                            info.enddate.setDate(info.enddate.getDate() + 1);
                        }

                        var schedule = {
                            title: info.contents,
                            content: '',
                            start: new Date(info.startdate), 
                            end: new Date(info.enddate), 
                            id: info.calendarid,
                            color: info.background,
                        }
                        
                        dataArr.push(schedule);
                    }
                }

                setCalendarEvents(dataArr);
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 팀 리스트 가져오기
    const getTeamList = async () => {

        try {
            const response = await axios.get(apiUrl+'/calendar/teamlist', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];

                        var team = {
                            value: info.teamid,
                            label: info.team
                        }
                        
                        dataArr.push(team);
                    }
                }
                
                setTeamList(dataArr);
                
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 일정 정보 가져오기
    const getCalendarInfo = async (calendarid) => {

        try {
            const response = await axios.get(apiUrl+'/calendar/info/'+calendarid, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    var selectedTeam = {};
                    for(var i = 0; i < teamList.length; i++){
                        if(resData.teamid == teamList[i].value){
                            selectedTeam = {
                                label: teamList[i].label,
                                value: teamList[i].value
                            };
                        }
                    }
                    
                    setFormData({
                        ...formData,
                        teamid: selectedTeam,
                        calendarid: resData.calendarid,
                        contents: resData.contents,
                        startdate: new Date(resData.startdate.split("T")[0]),
                        enddate: new Date(resData.enddate.split("T")[0]),
                        background: resData.background,
                    });
                }
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 일정 정보 등록
    const insertCalendarInfo = async () => {

        if(formData.teamid == null){
            alert("팀을 선택해주세요.");
            return false;
        }
        if(formData.contents == ""){
            alert("일정 상세 내용을 입력해주세요.");
            return false;
        }
        if(formData.startdate == null || formData.stardate == "Invalid Date"){
            alert("시작날짜를 선택해주세요.");
            return false;
        }
        if(formData.enddate == null || formData.enddate == "Invalid Date"){
            alert("종료날짜를 선택해주세요.");
            return false;
        }
        if(formData.background == ""){
            alert("컬러를 선택해주세요.");
            return false;
        }
        var params = {
            "contents": formData.contents,
            "startdate": formatDate(formData.startdate),
            "enddate": formatDate(formData.enddate),
            "background": formData.background,
            "teamid": formData.teamid.value
        }

        //console.log(params);
        try {
            const response = await axios.post(apiUrl+'/calendar/create/', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    alert("일정 등록이 완료되었습니다.");
                    setModalCentered(false);
                    getCalendarList();
                }
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 팀 추가
    const addNewTeam = async () => {
        
        var params = {
            "team": teamname
        }

        try {
            const response = await axios.post(apiUrl+'/calendar/createteam/', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    setTeamList([...teamList, {value: resData.teamid, label: teamname}]);
                    setFormData({
                        ...formData,
                        teamid: {
                            label: teamname,
                            value: resData.teamid
                        },
                    });
                }
            }
        
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 일정 정보 수정
    const updateCalendarInfo = async () => {

        if(formData.teamid == null){
            alert("팀을 선택해주세요.");
            return false;
        }
        if(formData.contents == ""){
            alert("일정 상세 내용을 입력해주세요.");
            return false;
        }
        if(formData.startdate == null || formData.stardate == "Invalid Date"){
            alert("시작날짜를 선택해주세요.");
            return false;
        }
        if(formData.enddate == null || formData.enddate == "Invalid Date"){
            alert("종료날짜를 선택해주세요.");
            return false;
        }
        if(formData.background == ""){
            alert("컬러를 선택해주세요.");
            return false;
        }
        var params = {
            "calendarid": formData.calendarid,
            "contents": formData.contents,
            "startdate": formatDate(formData.startdate),
            "enddate": formatDate(formData.enddate),
            "background": formData.background,
            "teamid": formData.teamid.value
        }

        //console.log(params);
        try {
            const response = await axios.put(apiUrl+'/calendar/update/', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("일정 수정이 완료되었습니다.");
                setModalCentered(false);
                getCalendarList();
            }
        
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        
        if(formData.calendarid === ""){
            insertCalendarInfo();
        }else{
            updateCalendarInfo();
        }
    };

    // 일정 삭제
    const deleteSchedule = async () => {
        var result = window.confirm("일정을 삭제하시겠습니까?");
        if(result){
            try {
                const response = await axios.delete(apiUrl+'/calendar/delete/' + formData.calendarid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("일정 삭제하였습니다.");
                    setModalCentered(false);
                    getCalendarList();
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const formatDate = (date) => {
        // 연도, 월, 일 추출
        var year = date.getFullYear();
        var month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더해줌
        var day = date.getDate();
    
        // 월과 일이 한 자리 숫자인 경우 두 자리로 만들어줌 (예: 9 -> 09)
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
    
        // "yyyy-MM-dd" 형식으로 반환
        return year + '-' + month + '-' + day;
    }

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    // 팀 선택 시
    const handleTeamChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            teamid: e
        }));
    };

    // 시작 날짜 변경 시
    const handleStartDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            startdate: e,
        }));
    };

    // 종료 날짜 변경 시
    const handleEndDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            enddate: e,
        }));
    };

    // 일정 클릭 시
    const eventClick = (eventClick) => {
        var calendarid = eventClick.event._def.publicId;
        getCalendarInfo(calendarid);
        setshowAddTeam(false);
        setModalCentered(true);
    };

    const handleDateClick = (arg) => {
        //console.log(arg.dateStr);
        setSelectedDate(arg.dateStr);
        setFormData({
            ...formData,
            teamid: null,
            calendarid: '',
            contents: '',
            startdate: new Date(arg.dateStr),
            enddate: new Date(arg.dateStr),
            background: '',
        });
        setTeamname('');
        setshowAddTeam(false);
        setModalCentered(true);
    };

    // 이전달 버튼 클릭 시
    const handlePrevClick = (arg) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.prev();
        const currentDate = calendarApi.getDate();
        setNewDate(currentDate);
    };

    // 다음달 버튼 클릭 시
    const handleNextClick = (arg) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.next();
        const currentDate = calendarApi.getDate();
        setNewDate(currentDate);
    };

    // 오늘 버튼 클릭 시
    const handleTodayClick = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.today();
        const currentDate = calendarApi.getDate();
        setNewDate(currentDate);
    };

    // 날짜 재설정
    const setNewDate = (currentDate) => {
        var year = currentDate.getFullYear();
        var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

        if(viewType === 'dayGridMonth'){
            setStartdate(year+'-'+month+'-01');

            // get last day of month
            if(month == 2){
                if(year % 4 == 0){
                    setEnddate(year+'-'+month+'-29');
                }else{
                    setEnddate(year+'-'+month+'-28');
                }
            }else if(month == 4 || month == 6 || month == 9 || month == 11){
                setEnddate(year+'-'+month+'-30');
            }else{
                setEnddate(year+'-'+month+'-31');
            }
        }else if(viewType === 'timeGridWeek'){
            var sdate = currentDate.getFullYear()+'-'+(currentDate.getMonth() + 1).toString().padStart(2, '0')+'-'+(currentDate.getDate()).toString().padStart(2, '0');
            setStartdate(sdate);
            var edate = new Date(sdate);
            edate.setDate(edate.getDate() + 6);
            edate = edate.getFullYear()+'-'+(edate.getMonth() + 1).toString().padStart(2, '0')+'-'+(edate.getDate()).toString().padStart(2, '0');
            setEnddate(edate);
        }
    };

    // 캘린더 타입 변경 시
    const handleViewDidMount = (arg) => {
        setViewType(arg.view.type);
    };

    //팀추가
    const [showAddTeam, setshowAddTeam] = useState(false); // input 보이기/숨기기 상태를 관리합니다.
    const handleButtonClick = () => {
        setshowAddTeam(true);
    };

    return (
        <Fragment>
            <PageTitle motherMenu={"홈페이지관리"} activeMenu={"일정관리"} />

            <Row>
                <Col xl={"12"}>
                    <Card >
                        <Card.Body>
                            <FullCalendar
                                ref={calendarRef} 
                                plugins={[
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    interactionPlugin,
                                ]}
                                defaultView="dayGridMonth"
                                //initialView="dayGridWeek"
                                headerToolbar={{
                                    start: "prev,next today",
                                    center: "title",
                                    end: "dayGridMonth,dayGridWeek",
                                }}
                                customButtons={{
                                    prev: {
                                        text: '이전 달',
                                        click: handlePrevClick,
                                    },
                                    next: {
                                        text: '다음 달',
                                        click: handleNextClick,
                                    },
                                    today: {
                                        text: '오늘',
                                        click: handleTodayClick,
                                    },
                                }}
                                eventDurationEditable={false}
                                editable={true}
                                droppable={true}
                                weekends={true}
                                events={calendarEvents}
                                eventContent={(eventInfo) => {
                                    return (
                                        <div className="eventBox" style={{ backgroundColor: eventInfo.event.backgroundColor }}>
                                            {eventInfo.event.title} {eventInfo.event.extendedProps.content}
                                        </div>
                                    );
                                }}
                                eventClick={eventClick} // 이벤트 클릭 동작
                                dateClick={handleDateClick} // 날짜 클릭 동작
                                viewDidMount={handleViewDidMount} // 뷰가 렌더링될 때 호출되는 이벤트
                                eventTimeFormat={false}
                                aspectRatio={2} // 캘린더의 너비와 높이 비율 조정
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* 일정등록/수정 모달 */}
            <Modal className="fade" show={modalCentered} onHide={() => setModalCentered(false)} centered>
                <Modal.Header>
                    <Modal.Title>일정 {formData.calendarid == "" ? "등록" : "수정"}하기</Modal.Title>
                    <Button onClick={()=> setModalCentered(false)}
                        variant=""
                        className="btn-close"
                        >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-2">
                        <form action="#" method="post">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <div className="d-flex gap-2">
                                            <div className="custom-react-select w-50">
                                                <Select
                                                name="teamid"
                                                isSearchable={false}
                                                options={teamList}
                                                value={formData.teamid}
                                                onChange={handleTeamChange}
                                                placeholder="팀 선택"
                                                aria-label="팀 선택" />
                                                
                                             </div>
                                             
                                            
                                            {showAddTeam && (
                                                <button type="button" className="btn btn-primary pt-0 pb-0 btn-square" onClick={() => setshowAddTeam(false)}><i className="fas fa-minus"></i></button>
                                            )}
                                            {!showAddTeam && (
                                                <button type="button" className="btn btn-primary pt-0 pb-0 btn-square" onClick={() => setshowAddTeam(true)}><i className="fas fa-plus"></i></button>
                                            )}
                                            
                                        </div>
                                        {showAddTeam && (
                                            <div className="d-flex gap-2">
                                                <input type="text" 
                                                    value={teamname}
                                                    onChange={e => setTeamname(e.target.value)}
                                                    name="teamname" 
                                                    className="form-control mt-2 w-50" 
                                                    placeholder="팀 입력" />
                                                 <Button type="button" variant="primary" className="mt-2" onClick={addNewTeam}>추가</Button>
                                            </div>
                                        )}

                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <textarea id="Address" 
                                            name="contents"
                                            value={formData.contents} 
                                            onChange={handleChange}
                                            placeholder="일정 상세 등록" 
                                            className="form-control" 
                                            rows="5"
                                            required />
                                    </div>
                                </div>
                                
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <div className="d-flex gap-2 align-items-center">
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker 
                                                    value={formData.startdate} 
                                                    onChange={handleStartDateChange}
                                                    placeholder="시작날짜 선택" 
                                                    className="picker-suit" />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                            <span> ~ </span>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker 
                                                    value={formData.enddate} 
                                                    onChange={handleEndDateChange}
                                                    placeholder="종료날짜 선택" 
                                                    className="picker-suit"  />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label" >컬러선택</label>
                                        <div className="d-flex gap-3 align-items-center">
                                            <label className="colorOption coral" htmlFor="coral">
                                                <input id="coral" type="radio" name="background" value="coral"
                                                    checked={formData.background==='coral'}
                                                    onChange={handleChange} />
                                                <span className="colorBox"></span>
                                            </label>
                                            <label className="colorOption red" htmlFor="red">
                                                <input id="red" type="radio" name="background" value="red"
                                                    checked={formData.background==='red'}
                                                    onChange={handleChange} />
                                                <span className="colorBox"></span>
                                            </label>
                                            <label className="colorOption blue" htmlFor="blue">
                                                <input id="blue" type="radio" name="background" value="blue"
                                                    checked={formData.background==='blue'}
                                                    onChange={handleChange} />
                                                <span className="colorBox"></span>
                                            </label>
                                            <label className="colorOption orange" htmlFor="orange">
                                                <input id="orange" type="radio" name="background" value="orange"
                                                    checked={formData.background==='orange'}
                                                    onChange={handleChange} />
                                                <span className="colorBox"></span>
                                            </label>
                                            <label  className="colorOption purple" htmlFor="purple">
                                                <input id="purple" type="radio" name="background" value="purple"
                                                    checked={formData.background==='purple'}
                                                    onChange={handleChange} />
                                                <span className="colorBox"></span>
                                            </label>
                                            <label  className="colorOption green" htmlFor="green">
                                                <input id="green" type="radio" name="background" value="green"
                                                    checked={formData.background==='green'}
                                                    onChange={handleChange} />
                                                <span className="colorBox"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=> setModalCentered(false)}
                        variant="light"
                        >
                        취소
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>저장</Button>
                    {formData.calendarid != '' && (
                        <Button onClick={deleteSchedule} variant="danger light">
                            삭제
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Schedule;
