import React ,{useContext} from "react";
import {  Routes, Route, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
/// Css
import './../index.css'
import './../chart.css'
import './../step.css'
import './../../assets/css/custom.css'

/// Layout
import Nav from './../layouts/nav'
import Footer from './../layouts/Footer'

import { ThemeContext } from "../../context/ThemeContext";
//Scroll To Top
import ScrollToTop from './../layouts/ScrollToTop';

/// 대시보드
import Home from "./../pages/dashboard/Home";

//회원관리
import Student from '../pages/students/AllStudent';
import StudentRegi from '../pages/students/StudentRegi';
import BulkStudentRegi from '../pages/students/BulkStudentRegi';
import Teacher from '../pages/teacher/AllTeacher';
import TeacherRegi from '../pages/teacher/TeacherRegi';
import BulkTeacherRegi from '../pages/teacher/BulkTeacherRegi';
import Belong from '../pages/belong/AllBelong';
import BelongRegi from '../pages/belong/BelongRegi';


/// 수업관리 
import LectureType from '../pages/class/lectureType';
import LectureTypeRegi from '../pages/class/lectureTypeRegi';
import Lecture from '../pages/class/AllLecture';
import LectureData from '../pages/class/LectureData';
import InstructorLecture from '../pages/class/InstructorLecture';
import InstructorLectureRegi from '../pages/class/InstructorLectureRegi';
import InstructorLectureEdit from '../pages/class/InstructorLectureEdit';
import Notice from '../pages/class/Notice';
import NoticeRegi from '../pages/class/NoticeRegi';
import Survey from '../pages/class/Survey';
import SurveyRegi from '../pages/class/SurveyRegi';
import Surveyform from '../pages/class/Surveyform';


//콘텐츠관리
import Book from '../pages/contents/AllBook';
import BookRegi from '../pages/contents/BookRegi';
import Curriculum from '../pages/contents/curriculum';
import CurriculumRegi from '../pages/contents/curriculumRegi';

//홈페이지관리
import Schedule from '../pages/homepage/Schedule';
import CourseConfirm from '../pages/homepage/CourseConfirm';
import BasicInformation from '../pages/homepage/BasicInformation';
import SuperAdmin from '../pages/homepage/SuperAdmin';
import SuperAdminRegi from '../pages/homepage/SuperAdminRegi';
import Contents from '../pages/homepage/Contents';
import ContentsRegi from '../pages/homepage/ContentsRegi';
import Banner from '../pages/homepage/Banner';
import BannerRegi from '../pages/homepage/BannerRegi';
import Logo from '../pages/homepage/Logo';
import LogoRegi from '../pages/homepage/LogoRegi';


/// Pages
import Error400 from './../pages/error/Error400'
import Error403 from './../pages/error/Error403'
import Error404 from './../pages/error/Error404'
import Error500 from './../pages/error/Error500'
import Error503 from './../pages/error/Error503'




const Markup = () => {
  const allroutes = [
    /// Dashboard
    { url: "/", component: <Home /> },    
    
    // 수강생 관리
    { url: "AllStudent", component: <Student /> },   
    { url: "StudentRegi/:id", component: <StudentRegi /> },   
    { url: "BulkStudentRegi", component: <BulkStudentRegi /> }, 
    
    // 강사관리
    { url: "AllTeacher", component: <Teacher /> },   
    { url: "TeacherRegi/:id", component: <TeacherRegi /> },   
    { url: "BulkTeacherRegi", component: <BulkTeacherRegi /> }, 

    // 소속관리
    { url: "AllBelong", component: <Belong /> }, 
    { url: "BelongRegi/:id", component: <BelongRegi /> }, 
    
    
    // 강의유형관리
    { url: "lectureType", component: <LectureType /> },    
    { url: "lectureTypeRegi/:id", component: <LectureTypeRegi /> },
    
    // 강의현황
    { url: "AllLecture", component: <Lecture /> },  
    { url: "LectureData/:id", component: <LectureData /> },
    
    // 강사 강의관리
    { url: "InstructorLecture", component: <InstructorLecture /> },
    { url: "InstructorLectureRegi/:id", component: <InstructorLectureRegi /> },
    { url: "InstructorLectureEdit/:id/:scheduleid/:tutorid", component: <InstructorLectureEdit /> },

    // 공지사항 관리
    { url: "Notice", component: <Notice /> },
    { url: "NoticeRegi/:id", component: <NoticeRegi /> },

    // 만족도 조사
    { url: "Survey", component: <Survey /> },
    { url: "SurveyRegi/:id", component: <SurveyRegi /> },
    { url: "Surveyform/:id", component: <Surveyform /> },

    //교재관리
    { url: "AllBook", component: <Book /> },
    { url: "BookRegi/:id", component: <BookRegi /> },


    // 커리큘럼관리
    { url: "curriculum", component: <Curriculum /> },   
    { url: "curriculumRegi/:id", component: <CurriculumRegi /> },

    //일정관리
    { url: "Schedule", component: <Schedule /> },
    
    //수강신청서 관리
    { url: "CourseConfirm", component: <CourseConfirm /> },

    //기본정보 관리
    { url: "BasicInformation", component: <BasicInformation /> },

    //슈퍼관리자 관리
    { url: "SuperAdmin", component: <SuperAdmin /> },
    { url: "SuperAdminRegi/:id", component: <SuperAdminRegi /> },
    
    //서비스 콘텐츠 관리
    { url: "Contents", component: <Contents /> },
    { url: "ContentsRegi/:id", component: <ContentsRegi /> },

    //배너관리
    { url: "Banner", component: <Banner /> },
    { url: "BannerRegi/:id", component: <BannerRegi /> },

    //로고관리
    { url: "Logo", component: <Logo /> },
    { url: "LogoRegi/:id", component: <LogoRegi /> },
    
    
  
  ]


  function NotFound(){    
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]    
      
    if(url.indexOf(path) <= 0){     
      return <Error404 />
    }
  }   

  return (
       <>
         <Routes>              
            <Route path='/page-error-400' element={<Error400/>} />            
            <Route path='/page-error-403' element={<Error403/>} />
            <Route path='/page-error-404' element={<Error404/>} />
            <Route path='/page-error-500' element={<Error500/>} />
            <Route path='/page-error-503' element={<Error503/>} />     
            <Route  element={<MainLayout />} > 
                {allroutes.map((data, i) => (
                  <Route
                    key={i}
                    path={`${data.url}`}
                    element={data.component}
                  />
                ))}
            </Route>                
            <Route path='*' element={<NotFound/>} />               
          </Routes>        
          <ScrollToTop />
       </>
  )
}

function MainLayout(){  
  const {sidebariconHover} = useContext(ThemeContext);
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <>
      <div id="main-wrapper" className={`show  ${sidebariconHover ? "iconhover-toggle": ""} ${ sideMenu ? "menu-toggle" : ""}`}>  
          <Nav />
          <div className="content-body" >          
            <div className="container-fluid" style={{ minHeight: window.screen.height - 45 }}>
              <Outlet />   
            </div>
          </div>
          <Footer />        
      </div>          
    </>
  )
};

export default Markup;