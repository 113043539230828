import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import CkEditorBlog from '../../elements/CkEditor/CkEditorBlog';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];

const NoticeRegi = () => {

    const accessToken = useSelector(state => state.auth.auth.accessToken);
    
    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '공지사항 수정' : '공지사항 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    var getNoticeCnt = 0;

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        lectureid: '',
        affiliationid: '',
        target: '',
        disable: '',
        title: '',
        contents: '',
        top: false,
        file: '',
        originfile: '',
        fileurl: '',
    });
    
    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [affiliationsData, setAffiliationsData] = useState([{label: "선택", value: ""}])

    const targetData = [
        { label: "수강생", value: "tutee"},
        { label: "강사", value: "tutor"},
        { label: "전체", value: "all"}
    ];

    const disableData = [
        { label: "노출", value: "노출"},
        { label: "비노출", value: "비노출"}
    ];

    const [menuName, setMenuName] = useState({
        activeMenu: '공지사항 등록하기',
        pageContent: '공지사항 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    useEffect(() => {

        // 수정 모드인 경우 데이터를 가져와서 폼에 채움
        const getUpdateData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse, noticeResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                    axios.get(apiUrl + '/notice/info/' + id, {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

                const noticeData = noticeResponse.data.data;
                //console.log(noticeData);

                if(!noticeData.contents){
                    noticeData.contents = "";
                }
                if(!noticeData.file){
                    noticeData.file = "";
                }

                var selectedAff = {};
                for(var i = 0; i < affiliations.length; i++){
                    if(noticeData.affiliationid == affiliations[i].value){
                        selectedAff = {
                            label: affiliations[i].label,
                            value: affiliations[i].value
                        };
                    }
                }
                
                setFormData(prevFormData => ({
                    ...prevFormData,
                    lectureid: noticeData.lectureid,
                    affiliationid: selectedAff,
                    target: noticeData.target,
                    disable: noticeData.disable,
                    title: noticeData.title,
                    contents: noticeData.contents,
                    top: noticeData.top,
                    file: noticeData.file,
                    originfile: noticeData.originfile,
                    fileurl: noticeData.fileurl
                }));

            } catch (error) {
                console.error('Error:', error);
            }
        };

        const getInsertData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id !== "0" && id !== 0) {
            if(getNoticeCnt == 0){
                getNoticeCnt++;
                setMode('update');
                setMenuName(prevState => ({
                    ...prevState,
                    activeMenu: '공지사항 수정하기',
                    pageContent: '공지사항 수정'
                }))
                getUpdateData();
            }
        }else{
            getInsertData();
        }
        
    }, [id]);

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'lectureid') {  
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: Number(value)
            }));
        } else if(name === "top"){
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: e.target.checked
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    // 공지사항 소속 변경 시
    const handleAffiliationChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            affiliationid: e
        }));
    };

    // 내용 변경 시
    const handleContentsChange = (event, editor) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            contents: editor.getData()
        }));
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        
        if(mode === "insert"){
            insertNotice();
        }else{
            updateNotice();
        }
    };

    // 공지사항 등록
    const insertNotice = async () => {
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        var affiliationid = params.affiliationid.value;
        params.affiliationid = affiliationid;
        
        try {
            const response = await axios.post(apiUrl+'/notice/insert', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("공지사항 등록하였습니다.");
                navigate('/Notice');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 공지사항 수정
    const updateNotice = async () => {
        var checkResult = checkFormData();

        if(!checkResult){
            return false;
        }

        var params = {...formData};
        params.noticeid = id;

        var affiliationid = params.affiliationid.value;
        params.affiliationid = affiliationid;

        //console.log(params);
        try {
            const response = await axios.put(apiUrl+'/notice/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("공지사항 수정하였습니다.");
                navigate('/Notice');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // form 유효성 검사
    const checkFormData = () => {
        if(formData.lectureid == "" || !formData.lectureid){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.affiliationid == "" || formData.affiliationid.length == 0){
            alert("회원소속을 선택해주세요.");
            return false;
        }
        if(formData.target == "" || formData.target.length == 0){
            alert("공지대상을 선택해주세요.");
            return false;
        }
        if(formData.disable == "" || formData.disable.length == 0){
            alert("노출여부를 선택해주세요.");
            return false;
        }
        if(formData.title == ""){
            alert("제목을 입력해주세요.");
            return false;
        }
        if(formData.contents == ""){
            alert("내용을 입력해주세요.");
            return false;
        }

        return true;
    }

    // 공지사항 삭제
    const deleteNotice = async () => {
        var result = window.confirm("공지사항을 삭제하시겠습니까?");
        if(result){
            var noticeid = id;
            try {
                const response = await axios.delete(apiUrl+'/notice/delete/' + noticeid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("공지사항 삭제하였습니다.");
                    navigate('/Notice');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 파일 업로드
    const uploadImage = async (e) => {
        //console.log(e);
        //console.log(e.target.files[0]);
        var fileInfo = e.target.files[0];
        fileInfo.filename = fileInfo.name;

        var fileData = new FormData();
        fileData.append('attachment', fileInfo);
        fileData.append('folder', 'notice');

        //console.log(fileData);
        
        try {
            const response = await axios.post(apiUrl+'/file/single', fileData, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setFormData({
                    ...formData,
                    file: res.data.key,
                    originfile: fileInfo.filename,
                    fileurl: ''
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 이전 페이지로
    const goBack = () => {
        navigate('/Notice');
    }

    return (
        <>
            <PageTitle  motherMenu={"수업관리"} grandMenu={"공지사항 관리"} grandMenuLink="/Notice" activeMenu={menuName.activeMenu}/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="lectureType">강의유형</label>
                                            {lecturetypeData.map((item, index) =>(
                                                <div key={index} className="form-check form-check-inline">
                                                    <label className="form-check-label"
                                                        htmlFor={`lectureid-${index}`}>
                                                        <input className="form-check-input" type="radio"
                                                            name="lectureid" id={`lectureid-${index}`}
                                                            value={item.lectureid}
                                                            checked={formData.lectureid===item.lectureid}
                                                            onChange={handleChange} />
                                                        {item.lecturetype}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">소속</label>
                                            <Select 
                                                isSearchable={false}
                                                className="custom-react-select" 
                                                options={affiliationsData}
                                                value={formData.affiliationid}
                                                onChange={handleAffiliationChange}
                                                placeholder="선택"
                                                aria-label="회원소속 선택"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="NoticePartner">공지대상</label>
                                            {targetData.map((item, index) =>(
                                                <div key={index} className="form-check form-check-inline">
                                                    <label className="form-check-label"
                                                        htmlFor={`target-${index}`}>
                                                        <input className="form-check-input" type="radio"
                                                            name="target" id={`target-${index}`}
                                                            value={item.value}
                                                            checked={formData.target===item.value}
                                                            onChange={handleChange} />
                                                        {item.label}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="Exposure">노출여부</label>
                                            {disableData.map((item, index) =>(
                                                <div key={index} className="form-check form-check-inline">
                                                    <label className="form-check-label"
                                                        htmlFor={`disable-${index}`}>
                                                        <input className="form-check-input" type="radio"
                                                            name="disable" id={`disable-${index}`}
                                                            value={item.value}
                                                            checked={formData.disable===item.value}
                                                            onChange={handleChange} />
                                                        {item.label}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="title">제목</label>
                                            <div className='d-flex gap-2'>
                                                <input id="title"
                                                    type="text" 
                                                    className="form-control" 
                                                    name="title"
                                                    placeholder="제목 입력" 
                                                    value={formData.title} onChange={handleChange}
                                                    required />

                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor='top-exposure'>
                                                        <input className="form-check-input" type="checkbox"
                                                            name="top" id='top-exposure' checked={formData.top}
                                                            onChange={handleChange}
                                                             />
                                                        최상단 노출
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="ekeditor">내용</label>
                                             <div className="custom-ekeditor">
                                                 <CkEditorBlog 
                                                    data={formData.contents} 
                                                    onChange={handleContentsChange}/>
                                             </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="profile">파일 첨부</label>
                                            <input type="file" id='file' 
                                                className="form-control" 
                                                data-default-file="" 
                                                aria-label="공지사항 파일 첨부"
                                                onChange={uploadImage}
                                            />
                                            {formData.fileurl && (
                                                <img src={formData.fileurl} alt={formData.file} className="imagePreview" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary me-1" onClick={handleSubmit}>저장</button>
                                        {mode != 'insert' && (
                                            <button onClick={deleteNotice} className="btn btn-danger light me-1" variant="danger">
                                                삭제
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoticeRegi;