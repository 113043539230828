import React, {useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Row, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Select from 'react-select';
import { DatePicker } from 'rsuite';

import PageTitle from '../../layouts/PageTitle';

import axios from 'axios';

import config from '../../../config';
import { set } from 'rsuite/esm/utils/dateUtils';
const apiUrl = config.apiUrl;

var dataList = [];

const theadData = [
    {heading: '강의유형', sortingValue:""},
    {heading: '소속', sortingValue:""},
    {heading: '강사명', sortingValue:""},
    {heading: '날짜', sortingValue:"datetxt"},
    {heading: '요일', sortingValue:"weektxt"},
    {heading: '시작시간', sortingValue:"starttxt"},
    {heading: '종료시간', sortingValue:"endtxt"},
    {heading: '시간간격', sortingValue:"duration"},
    {heading: '상태', sortingValue:"status"},
    {heading: '수강생', sortingValue:""},
    {heading: '교재', sortingValue:""},
];
// 수강생 Multi-Select
const CustomClearText = () => "모두 지우기";
const ClearIndicator = (props) => {
   const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
   } = props;
   return (
      <div
         {...restInnerProps}
         ref={ref}
         style={getStyles("clearIndicator", props)}
      >
         <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
   );
};
const ClearIndicatorStyles = (base, state) => ({
   ...base,
   cursor: "pointer",
   color: state.isFocused ? "blue" : "black",
});


const InstructorLectureEdit = () => {

    const [id, setId] = useState(useParams()); // URL 매개변수에서 ID 가져오기
    const isEditing = id.id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '강사 강의 수정' : '강사 강의 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    const accessToken = useSelector(state => state.auth.auth.accessToken);
    const navigate = useNavigate();

    const [tuteeData, setTuteeData] = useState([{label: "선택", value: ""}]);
    const [curriculumData, setCurriculumData] = useState({label: "선택", value: ""});
    const [bookData, setBookData] = useState([{label: "선택", value: ""}]);
    const [affiliations, setAffiliations] = useState('');
    const [lectureid, setLectureid] = useState(0);

    const [formData, setFormData] = useState({
        tuteeid: '',
        curriculumid: '',
        bookid: '',
    });

    const [menuName, setMenuName] = useState({
        activeMenu: '강사 강의 수정하기',
        pageContent: '강사 강의 수정'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    useEffect(() => {

        // 수정 모드인 경우 데이터를 가져와서 폼에 채움
        const getUpdateData = async () => {
            var tutoringid = id.id;
            var scheduleid = id.scheduleid;

            try {
                /*
                const [curriculumResponse, tuteeResponse] = await Promise.all([
                    axios.get(apiUrl + '/curriculum'),
                    axios.get(apiUrl + '/tutee/list?affiliations=', {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);
                const tutees = tuteeResponse.data.data.map(tutee => ({
                    label: tutee.name,
                    value: tutee.tuteeid
                }));
                setTuteeData(tutees);

                const curriculums = curriculumResponse.data.data.map(curriculum => ({
                    label: curriculum.title,
                    value: curriculum.curriculumid
                }));
                setCurriculumData(curriculums);
                */

                const scheduleResponse = await axios.get(apiUrl + `/tutoring/schedule?tutoringid=${tutoringid}&scheduleid=${scheduleid}`, {
                    headers: {
                        token: accessToken
                    }
                });
    
                const res = scheduleResponse.data;
                var dataArr = [];
                if (res.success) {
                    const resData = scheduleResponse.data.data;
                    //console.log(resData);
                    if (resData) {
                        
                        for(var i = 0; i < resData.length; i++){
                            var info = resData[i];
                            var starttxt = info.starttxt;
                            var starttxtArr = starttxt.split(":");
                            var startampm = "오전";
                            var starthour = Number(starttxtArr[0]);
                            if(starthour == 12){
                                startampm = "오후";
                            }else if(starthour > 12 && starthour < 24){
                                starthour = starthour - 12;
                                startampm = "오후";
                            }else if(starthour == 0 || starthour == 24){
                                startampm = "오전";
                                starthour = 12;
                            }
                            starthour = starthour.toString().padStart(2, '0');
                            info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                            
                            var endtxt = info.endtxt;
                            var endtxtArr = endtxt.split(":");
                            var endampm = "오전";
                            var endhour = Number(endtxtArr[0]);
                            if(endhour == 12){
                                endampm = "오후";
                            }else if(endhour > 12 && endhour < 24){
                                endhour = endhour - 12;
                                endampm = "오후";
                            }else if(endhour == 0 || endhour == 24){
                                endampm = "오전";
                                endhour = 12;
                            }
                            endhour = endhour.toString().padStart(2, '0');
                            info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];

                            if(info.tuteename){
                                if(info.tuteename.includes(",")){ 
                                    info.tuteenameArr = info.tuteename.split(",");
                                }else{
                                    info.tuteenameArr = [info.tuteename];
                                }
                            }else{
                                info.tuteenameArr = [];
                            }
    
                            dataArr.push(info);
                        }

                        dataList = dataArr;

                        setTableData(dataList);
    
                        setFormData({
                            ...formData,
                            lectureid: resData.lectureid,
                        });
                    }
                }

            } catch (error) {
                console.error('Error:', error);
            }
        };

        // 강사 정보 가져오기
        const getTutorData = async () => {
            var tutorid = id.tutorid;

            try {
                const response = await axios.get(apiUrl+`/tutor/${tutorid}`, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    var resData = response.data.data;
                    //console.log(resData);
                    if(resData){
                        setAffiliations(resData.affiliations);
                        setLectureid(resData.lectureid);
                    }
                }

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id.id !== "0" && id.id !== 0) {
            setMode('update');
            getUpdateData();
            getTutorData();
        }

    }, [id]);

    // 수강생 리스트 가져오기
    useEffect(() => {
        
        const getTuteeList = async () => {
            try {
                const response = await axios.get(apiUrl+'/tutee/list?affiliations='+affiliations + '&onlytutee=1', {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    var resData = response.data.data;
                    var dataArr = [];
                    if(resData){
                        for(var i = 0; i < resData.length; i++){
                            var info = resData[i];
                            info.label = info.name;
                            info.value = info.tuteeid;

                            dataArr.push(info);
                        }
                    }
                    setTuteeData(dataArr);
                }
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        if(affiliations != ""){
            getTuteeList();
        }

    }, [affiliations]);

    // 커리큘럼 리스트
    useEffect(() => {

        const getCurriculumList = async () => {
            try {
                const response = await axios.get(apiUrl+'/curriculum?lectureid='+lectureid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    var resData = response.data.data;
                    var dataArr = [];
                    if(resData){
                        for(var i = 0; i < resData.length; i++){
                            var info = resData[i];
                            info.label = info.title;
                            info.value = info.curriculumid;

                            dataArr.push(info);
                        }
                    }
                    setCurriculumData(dataArr);
                }
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        if(lectureid != 0){
            getCurriculumList();
        }

    }, [lectureid]);

    // 교재 리스트
    const getBookList = async () => {
        try {
            const response = await axios.get(apiUrl+'/book/list?curriculumid='+formData.curriculumid.value, {
                headers: {
                    token: accessToken
                },
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                var dataArr = [];
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.title;
                        info.value = info.bookid;

                        dataArr.push(info);
                    }
                }
                setBookData(dataArr);
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    useEffect(() => {

        if(formData.curriculumid.value != ""){
            getBookList();
        }

    }, [formData.curriculumid]);

    // 수강생 변경 시
    const handleTuteeChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            tuteeid: e
        }));
    };

    // 커리큘럼 변경 시
    const handleCurriculumChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            curriculumid: e,
            bookid: ''
        }));
    };

    // 교재 변경 시
    const handleBookChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            bookid: e
        }));
    };

    // 테이블
    const [tableData, setTableData] = useState([...dataList]);

    //수강생 툴팁
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };
    
    const renderTooltip = (props, data) => ( // 그외 나머지 수강생 리스트
        <Tooltip id="tooltip" {...props} className="custom-tooltip">
            <ul className='tooltip-list'> 
                {data.tuteenameArr.map((item, ind)=>(
                    <li key={ind}>{item}</li>
                ))}
            </ul>
        </Tooltip>
    );

    // 수업 등록 유효성 검사
    const checkClassData = () => {

        if(formData.tuteeid == ""){
            alert("수강생을 선택해주세요.");
            return false;
        }
        if(formData.bookid == ""){
            alert("교재를 선택해주세요.");
            return false;
        }

        return true;
    };

    // 등록
    const saveClass = async () => {

        var result = checkClassData();

        if(result){

            var tuteeArr = formData.tuteeid;
            var tuteeStr = "";
            for(var i = 0; i < tuteeArr.length; i++){
                if(i == 0){
                    tuteeStr += tuteeArr[i].value;
                }else{
                    tuteeStr += "," + tuteeArr[i].value;
                }
            }

            var params = {
                tuteeid: tuteeStr,
                bookid: formData.bookid.value,
            };

            var scheduleid = id.scheduleid;

            //console.log(params);

            try {
                const response = await axios.put(apiUrl+'/tutoring/schedule/tutees/'+scheduleid, params, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    confirmClass();
                }else{
                    alert(res.msg);
                }
    
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 최종승인
    const confirmClass = async () => {
        var tuteeArr = formData.tuteeid;
        var tuteeStr = "";
        for(var i = 0; i < tuteeArr.length; i++){
            if(i == 0){
                tuteeStr += tuteeArr[i].value;
            }else{
                tuteeStr += "," + tuteeArr[i].value;
            }
        }

        var params = {
            tuteeid: tuteeStr,
            bookid: formData.bookid.value,
        };

        var scheduleid = id.scheduleid;

        //console.log(params);

        try {
            const response = await axios.put(apiUrl+'/tutoring/schedule/tuteesConfirm/'+scheduleid, params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("강의 수정 되었습니다.");
                navigate('/InstructorLecture');
            }else{
                alert(res.msg);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 뒤로가기
    const goBack = () => {
        navigate('/InstructorLecture');
    }
    
    return (
        <>
            <PageTitle motherMenu={"수업관리"} grandMenu={"강사 강의 관리"} grandMenuLink="/InstructorLecture"  activeMenu={menuName.pageContent} />

            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className='form-label'>수강생 선택</label>
                                            <Select
                                                className='z4'
                                                isSearchable={true}
                                                placeholder="수강생 선택 및 검색"
                                                closeMenuOnSelect={false}
                                                name='tuteeid'
                                                value={formData.tuteeid}
                                                onChange={handleTuteeChange}
                                                components={{ ClearIndicator }}
                                                styles={{ clearIndicator: ClearIndicatorStyles }}
                                                isMulti
                                                options={tuteeData}/>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className='form-label'>커리큘럼 선택</label>
                                            <Select
                                                className='z4'
                                                placeholder="커리큘럼 선택"
                                                value={formData.curriculumid}
                                                name='curriculumid'
                                                onChange={handleCurriculumChange}
                                                options={curriculumData}
                                                isSearchable = {true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className='form-label'>교재 선택</label>
                                            <Select
                                                className='z4'
                                                placeholder="교재 선택 및 검색"
                                                value={formData.bookid}
                                                name='bookid'
                                                onChange={handleBookChange}
                                                options={bookData}
                                                isSearchable = {true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Row>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-end flex-wrap gap-2">
                            <button type='button' className="me-2 btn btn-success btn-square" onClick={saveClass}>등록</button>
                            <button type='button' className="me-2 btn btn-light btn-square" onClick={goBack}>취소</button>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            {mode != "insert" && (
                                                <tr>
                                                    {theadData.map((item, ind)=>(
                                                        <th key={ind}>
                                                            {item.heading}
                                                        </th>
                                                    ))}
                                                </tr>
                                            )}
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td>{data.lecturetype}</td>
                                                    <td>{data.affiliations_name}</td>
                                                    <td>{data.tutorname}</td>
                                                    <td>{data.datefulltxt}</td>
                                                    <td>{data.weektxt}</td>
                                                    <td>{data.starttxtStr}</td>
                                                    <td>{data.endtxtStr}</td>
                                                    <td>{data.durationtxt}</td>
                                                    <td>{data.status}</td>
                                                    <td>{data.tuteename}</td>
                                                    {/* 
                                                    {data.tuteenameArr.length > 0 && (
                                                        <td>
                                                            <>
                                                                <OverlayTrigger placement="top" overlay={(props) => renderTooltip(props, data)}
                                                                    show={tooltipOpen}>
                                                                    <span onMouseEnter={toggleTooltip}
                                                                        onMouseLeave={toggleTooltip}>
                                                                        <b
                                                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}>{data.tuteecnt}명</b>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </>
                                                        </td>
                                                    )}
                                                    */}
                                                    <td>{data.booktitle}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    );
};

export default InstructorLectureEdit;