/* eslint-disable no-loop-func */
import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Form, Dropdown } from 'react-bootstrap';
import { DatePicker } from 'rsuite';
import CkEditorBlog from '../../elements/CkEditor/CkEditorBlog';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
import { set } from "rsuite/esm/utils/dateUtils";
const apiUrl = config.apiUrl;



// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const Surveyform = () => {

    useTitle("만족도 조사 폼 등록");

    const accessToken = useSelector(state => state.auth.auth.accessToken);
    
    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드
    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

     const customStyles = { // react-select style
         container: (provided) => ({
             ...provided,
         }),
         menuPortal: (provided) => ({
             ...provided,
             zIndex: 9999
         }),
     };

    useEffect(() => {

        document.title = isEditing ? '만족도 조사 폼 수정' : '만족도 조사 폼 등록'; // 페이지 제목 설정

        if (id !== "0" && id !== 0) {
            setMode('update');
            setMenuName(prevState => ({
                ...prevState,
                activeMenu: '만족도 조사 폼 수정하기',
                pageContent: '만족도 조사 폼 수정'
            }));
        }

    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    const [menuName, setMenuName] = useState({
        activeMenu: '만족도 조사 폼 등록하기',
        pageContent: '만족도 조사 폼 등록'
    });

    const navigate = useNavigate();

    const Questiontype = [ //질문유형
        { value: '단답형', label: '단답형' },
        { value: '장문형', label: '장문형' },
        { value: '객관식', label: '객관식(단일선택)' },
        { value: '복수', label: '객관식(복수선택)' },
        { value: '드롭다운', label: '드롭다운' },
        { value: '선형배율', label: '선형배율' },
        { value: '날짜', label: '날짜' },
        { value: '시간', label: '시간' },
    ];
    
    const [draggedItems, setDraggedItems] = useState([]);
    
    // 수정 모드인 경우 데이터를 가져와서 폼에 채움
    useEffect(() => {
        
        if(id != ""){
            getForm();
        }

    }, [id]);

    // 폼 데이터 가져오기
    const getForm = async () => {

        try {
            const response = await axios.get(apiUrl+'/research/info/'+id, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            var resData = res.data;
            //console.log(resData);
            //console.log(resData.sections);
            if(resData.sections.length > 0){
                var sectionList = resData.sections;
                for(var i = 0; i < sectionList.length; i++){
                    var questList = sectionList[i].quest;
                    if(questList.length > 0){
                        // 수정모드 action 지정
                        questList.map((item, index) => {
                            questList[index].action = 'update';
                            questList[index].number = index + 1;
                        });
                        sectionList[i].quest = questList;
                    }
                    sectionList[i].number = i + 1;
                    sectionList[i].action = 'update';
                    sectionList[i].isSectionVisible = true;
                }
                //console.log(sectionList);

                setDraggedItems([...draggedItems, ...sectionList]);
            }else{

                // 첫 섹션 추가
                addSection('insert');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 섹션 추가
    const addSection = async (mode, sectionData = null) => {
        var params = {
            title: "제목 없는 섹션",
            sectiondescription: "",
            researchid: id,
        };
        if(mode == "copy"){
            params.title = sectionData.sectionname;
            params.sectiondescription = sectionData.sectiondescription;
        }
        try {
            const response = await axios.post(apiUrl+'/research/createSection/', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var sectionid = res.data.sectionid;
                var data = null;
                if(mode == "copy"){
                    data = {
                        "action": "create",
                        "quest": sectionData.quest,
                        "sectionid": sectionid,
                        "title": sectionData.sectionname,
                        "sectionname": sectionData.sectionname,
                        "sectiondescription": sectionData.sectiondescription,
                        "number": draggedItems.length + 1,
                        "isSectionVisible": sectionData.isSectionVisible,
                    };
                }else{
                    data = {
                        "action": "create",
                        "quest": [],
                        "sectionid": sectionid,
                        "title": "제목 없는 섹션",
                        "sectionname": "제목 없는 섹션",
                        "sectiondescription": "",
                        "number": draggedItems.length + 1,
                        "isSectionVisible": true,
                    };
                }

                setDraggedItems([...draggedItems, data]);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 질문 추가
    const addQuest = (sectionindex) => {
        var questLen = draggedItems[sectionindex].quest.length + 1;
        var data = {
            "action": "create",
            "question": "",
            "file": "",
            "optionobj": "",
            "required": false,
            "optionjson": {},
            "movesection": false,
            "optionshuffle": false,
            "sectionid": draggedItems[sectionindex].sectionid,
            "number": questLen
        };
        var questList = draggedItems[sectionindex].quest;
        questList.push(data);

        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest = questList;
            return newState;
        });
    };

    // 섹션 복사하기
    const copySection = (sectionindex) => {
        var data = draggedItems[sectionindex];        
        addSection('copy', data);
    };

    // 섹션 삭제하기
    const deleteSection = (sectionindex) => {
        if(draggedItems[sectionindex].action === 'create'){
            setDraggedItems(prevState => {
                const newState = prevState.filter((_, index) => index !== sectionindex);
                return newState;
            });
        }else{
            // action 을 delete 로 변경
            setDraggedItems(prevState => {
                const newState = prevState.map((item, index) => {
                    if (index === sectionindex) {
                        return { ...item, action: 'delete' };
                    }
                    return item;
                });
                return newState;
            });
        }
    };

    // 섹션 제목 변경 시
    const setSectionName = (e) => {
        var target = e.target;
        var sectionindex = target.getAttribute('sectionindex');
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].sectionname = target.value;
            return newState;
        });
    };

    // 섹션 설명 변경 시
    const setSectionDescription = (e) => {
        var target = e.target;
        var sectionindex = target.getAttribute('sectionindex');
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].sectiondescription = target.value;
            return newState;
        });
    };

    // 값 변경 시
    const setQuestionTitle = (e) => {
        var target = e.target;
        var sectionindex = target.getAttribute('sectionindex');
        var ind = target.getAttribute('ind');
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest[ind].question = target.value;
            return newState;
        });
    };

    // 질문 타입 변경 시
    const setQuestionOption = (selectedOption, sectionindex, ind) => {
        var optionjson = {};
        if(selectedOption.value === '객관식' || selectedOption.value === '복수' || selectedOption.value === '드롭다운'){
            if(selectedOption.value === '객관식' || selectedOption.value === '드롭다운'){
                optionjson = {
                    option: [
                        { value: '', text: '', move_seq: 999 },
                        { value: '', text: '', move_seq: 999 },
                        { value: '', text: '', move_seq: 999 },
                        { value: '', text: '', move_seq: 999 },
                    ]
                };
            }else{
                optionjson = {
                    option: [
                        { value: '', text: '' },
                        { value: '', text: '' },
                        { value: '', text: '' },
                        { value: '', text: '' },
                    ]
                };
            }
            
        }else if(selectedOption.value === '선형배율'){
            optionjson = {
                option: [
                    {
                        minvalue: 1,
                        maxvalue: 5,
                        mintext: '',
                        maxtext: ''
                    }
                ]
            };
        }
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest[ind].optionobj = selectedOption.value;
            newState[sectionindex].quest[ind].optionjson = optionjson;
            return newState;
        });
    };

    // 필수응답 변경 시
    const setRequired = (e, sectionindex, ind) => {
        const { checked } = e.target;
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest[ind].required = checked;
            return newState;
        });
    };

    // 객관식 옵션 추가
    const addOption = (sectionindex, ind) => {
        var questList = draggedItems[sectionindex].quest;
        var optionjson = questList[ind].optionjson;
        optionjson.option.push({ value: '', text: '', move_seq: 999 });
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest = questList;
            return newState;
        });
    };

    // 객관식 옵션 변경 시
    const setOption = (e, sectionindex, ind, option_idx) => {
        var target = e.target;
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest[ind].optionjson.option[option_idx].value = target.value;
            newState[sectionindex].quest[ind].optionjson.option[option_idx].text = target.value;
            return newState;
        });
    };

    // 객관식 옵션 삭제
    const deleteOption = (sectionindex, ind, option_idx) => {
        var questList = draggedItems[sectionindex].quest;
        questList[ind].optionjson.option.splice(option_idx, 1);
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest = questList;
            return newState;
        });
    };

    // 선형배율 범위 텍스트 변경 시
    const setRangeText = (e, type, sectionindex, ind) => {
        var target = e.target;
        setDraggedItems(prevState => {
            const newState = [...prevState];
            if(type === 'mintext'){
                newState[sectionindex].quest[ind].optionjson.mintext = target.value;
            }else{
                newState[sectionindex].quest[ind].optionjson.maxtext = target.value;
            }
            return newState;
        });
    };

    // 질문 이동 옵션
    const modifiedOptions = [
        { label: "다음 섹션으로 진행하기", value: 999 },
        ...draggedItems.map((item, index) => ({
            ...item,
            label: `${index + 1} 섹션(${item.sectionname})으로 이동`,
            value: index
        })),
        { label: "설문지 제출", value: -1 }
    ];

    // 질문 이동 옵션 선택 시
    const setMoveQuestion = (selectedOption, sectionindex, ind, option_idx) => {
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest[ind].optionjson.option[option_idx].move_seq = selectedOption.value;
            return newState;
        });
    };

    // 질문 복사하기
    const copyQuest = (sectionindex, ind) => {
        //var data = draggedItems[ind];
        var data = draggedItems[sectionindex].quest[ind];
        data.number = draggedItems[sectionindex].quest.length + 1;
        data.action = 'create';
        var questList = draggedItems[sectionindex].quest;
        questList.push(data);
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].quest = questList;
            return newState;
        });
    };

    // 질문 삭제하기
    const deleteQuest = (sectionindex, ind) => {
        if(draggedItems[sectionindex].quest[ind].action === 'create'){
            /*
            setDraggedItems(prevState => {
                const newState = prevState.filter((_, index) => index !== ind);
                return newState;
            });
            */
            var questList = draggedItems[sectionindex].quest;
            questList.splice(ind, 1);
            setDraggedItems(prevState => {
                const newState = [...prevState];
                newState[sectionindex].quest = questList;
                return newState;
            });
        }else{
            // action 을 delete 로 변경
            /*
            setDraggedItems(prevState => {
                const newState = prevState.map((item, index) => {
                    if (index === ind) {
                        return { ...item, action: 'delete' };
                    }
                    return item;
                });
                return newState;
            });
            */
            setDraggedItems(prevState => {
                const newState = [...prevState];
                newState[sectionindex].quest[ind].action = 'delete';
                return newState;
            });
        }
    };

    // 저장
    const saveQuest = async () => {

        //console.log(draggedItems);
        var sections = [];
        var questions = [];
        for(var i = 0; i < draggedItems.length; i++){
            var section = draggedItems[i];
            if(section.action != "delete"){
                if(section.sectionname === ''){
                    alert('섹션 제목을 입력해주세요.');
                    return false;
                }

                if(section.quest.length == 0){
                    alert(`${i+1} 섹션에 질문을 추가해주세요.`);
                    return false;
                }

                for(var j = 0; j < section.quest.length; j++){
                    var quest = section.quest[j];
                    if(quest.question === ''){
                        alert('질문을 입력해주세요.');
                        return false;
                    }
                    if(quest.optionobj === ''){
                        alert('질문 타입을 선택해주세요.');
                        return false;
                    }
                    if(quest.optionobj == "객관식" || quest.optionobj == "복수" || quest.optionobj == "드롭다운"){
                        var optionCnt = 0;
                        for(var k = 0; k < quest.optionjson.option.length; k++){
                            if(quest.optionjson.option[k].text != ''){
                                optionCnt++;
                            }
                        }
                        if(optionCnt < 2){
                            alert('옵션을 2개 이상 입력해주세요.');
                            return false;
                        }
                    }
                    questions.push(quest);
                }
            }
            
            sections.push({
                action: draggedItems[i].action,
                sectionid: draggedItems[i].sectionid,
                sectionname: draggedItems[i].sectionname,
                sectiondescription: draggedItems[i].sectiondescription,
            });
        }

        var params = {
            question: questions,
            section: sections,
        };
        //console.log(params);

        try {
            const response = await axios.post(apiUrl+'/research/createquest/'+id, params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("저장되었습니다.");
                navigate('/SurveyRegi/'+id);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 드래그드롭 아이템
    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스

    // 드래그 시작될 때 실행
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    // 드래그가 끝날 때 실행
    const dragEnd = () => {
        const newList = [...draggedItems];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        setDraggedItems(newList);
    };

    // 드래그오버 (드랍할 위치에 커서 이동시)
    const dragOver = (position) => {
        dragOverItem.current = position;
    };

    // 답벼 기준으로 섹션 이동 체크박스 선택시 show/hide
    const handleCheckboxChange = (event, sectionindex, idx) => {
        const { id, checked } = event.target;
        if(id.includes('sectionMoveChk')){
            setDraggedItems(prevState => {
                const newState = [...prevState];
                newState[sectionindex].quest[idx].movesection = checked;
                return newState;
            });
        }else if(id.includes('randomChk')){
            setDraggedItems(prevState => {
                const newState = [...prevState];
                newState[sectionindex].quest[idx].optionshuffle = checked;
                return newState;
            });
        }
    };

    // 하위메뉴 토글
    const toggleSectionVisibility = (sectionindex) => {
        var isSectionVisible = !draggedItems[sectionindex].isSectionVisible;
        setDraggedItems(prevState => {
            const newState = [...prevState];
            newState[sectionindex].isSectionVisible = isSectionVisible;
            return newState;
        });
    };
    
    const goBack = () => {
        navigate('/SurveyRegi/'+id); // 뒤로가기
    };

    return (
        <>
            <PageTitle motherMenu={"수업관리"} grandMenu={"만족도 조사 관리"} grandMenuLink="/Survey" activeMenu={menuName.pageContent} />
                <div className="formBox">
                
                    {draggedItems &&
                    draggedItems.map((section, index) => (
                    
                        /* 상위 질문 */
                        <div className="questBox" key={index}>
                            <div className="col-xl-12 col-xxl-12 col-sm-12" 
                                /*
                                draggable
                                onDragStart={(e)=> dragStart(e, index)}
                                onDragOver={() => dragOver(index)}
                                onDragEnd={dragEnd}
                                */
                                // item.action이 delete이면 가리기
                                style={{display: section.action === 'delete' ? 'none' : 'block'}}
                                >
                                {/* 섹션표시 */}
                                <div className="sectionNumBox">
                                    <div className="sectionNum"><span>{draggedItems.length}중 {section.number}섹션</span></div>
                                </div>
                                <div className="card">

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between mb-3">
                                                <div>
                                                    {/* 접기버튼 */}
                                                    <button type="button" className="btn btn-primary light" onClick={() => toggleSectionVisibility(index)}>
                                                        <i className={`fas fa-angle-${section.isSectionVisible ? 'up' : 'down'}`}></i>
                                                    </button>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <button type="button" onClick={() => copySection(index)} className="btn btn-light me-2">섹션 복제</button>
                                                    <button type="button" onClick={() => deleteSection(index)} className="btn btn-danger light">섹션 삭제</button>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="question">제목</label>
                                                    <input id="question"
                                                        value={section.sectionname}
                                                        placeholder="제목을 작성해주세요"
                                                        type="text"
                                                        className="form-control"
                                                        sectionindex={index}
                                                        onChange={setSectionName}
                                                        required />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="question">설명</label>
                                                    <input id="question"
                                                        value={section.sectiondescription}
                                                        placeholder="설명(선택사항)"
                                                        type="text"
                                                        className="form-control"
                                                        sectionindex={index}
                                                        onChange={setSectionDescription}
                                                        required />
                                                </div>
                                            </div>

                                            {/* 하위 질문 */}
                                            {section.isSectionVisible && (
                                                <div className="sub_questBox on">
                                                    
                                                    {section.quest &&
                                                        section.quest.map((item, idx) => (
                                                            
                                                        <div className="card" key={idx} style={{display: item.action === 'delete' ? 'none' : 'block'}}>
                                                            {/* action == "delete" 가 아닌 것만 보여지기 */}
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between mb-3">
                                                                        <div className="d-flex justify-content-between gap-2">
                                                                            <div className="form-check custom-checkbox check-xs">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id={"sectionMoveChk_"+index+"_"+idx}
                                                                                    checked={item.movesection}
                                                                                    onChange={(e) => handleCheckboxChange(e, index, idx)}
                                                                                />
                                                                                <label className="form-check-label"
                                                                                    htmlFor={"sectionMoveChk_"+index+"_"+idx}>
                                                                                    답변 기준으로 섹션이동
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check custom-checkbox check-xs">
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id={"randomChk_"+index+"_"+idx}
                                                                                    checked={item.optionshuffle}
                                                                                    onChange={(e) => handleCheckboxChange(e, index, idx)}
                                                                                />
                                                                                <label className="form-check-label"
                                                                                    htmlFor={"randomChk_"+index+"_"+idx}>
                                                                                    옵션 순서 무작위로 섞기
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex justify-content-between">
                                                                            <button type="button" onClick={() => copyQuest(index, idx)} className="btn btn-light me-2">복사하기</button>
                                                                            <button type="button" onClick={() => deleteQuest(index, idx)} className="btn btn-danger light">삭제</button>
                                                                            <div className="form-check form-check-inline">
                                                                                <Form className="d-flex align-items-center gap-2">
                                                                                    <Form.Switch
                                                                                        id={`custom-switch-${idx}`}
                                                                                        checked={item.required}
                                                                                        onChange={(e) => setRequired(e, index, idx)} />
                                                                                    <label htmlFor="">필수응답</label>
                                                                                </Form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label" htmlFor="question">질문 {item.number}</label>
                                                                            <input id="question"
                                                                                value={item.question}
                                                                                placeholder="질문을 작성해주세요"
                                                                                type="text"
                                                                                className="form-control"
                                                                                ind={idx}
                                                                                sectionindex={index}
                                                                                onChange={setQuestionTitle}
                                                                                required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">질문 타입</label>
                                                                            <Select isSearchable={false} options={Questiontype} styles={customStyles}
                                                                                className="w-50"
                                                                                onChange={(e) => setQuestionOption(e, index, idx)}
                                                                                value={Questiontype.find(option => option.value === item.optionobj)}
                                                                                ind={idx}placeholder="선택"
                                                                                aria-label="소속 선택" />
                                                                        </div>
                                                                    </div>

                                                                    <hr />

                                                                    <div className="sub_questBox"></div>

                                                                    { (item.optionobj === '객관식' || item.optionobj === '복수') && (
                                                                        <div className="col-xl-5 col-lg-6 col-sm-12">
                                                                            <div className="form-group">
                                                                                <label className="form-label d-block">
                                                                                    {item.optionobj === '객관식' ? '객관식(단일선택)' : '객관식(복수선택)'}
                                                                                </label>
                                                                                <ul className="onlyone">
                                                                                    {item.optionjson.option.map((option, option_idx) => (
                                                                                        <li key={option_idx} className="d-flex gap-2 align-items-center mb-2" >
                                                                                            <span className="option"></span>
                                                                                            <input id="" type="text"
                                                                                                onChange={(e) => setOption(e, index, idx, option_idx)}
                                                                                                value={item.optionjson.option[option_idx].value}
                                                                                                placeholder="옵션 입력"
                                                                                                className="form-control"
                                                                                                required />
                                                                                            <button type="button" onClick={() => deleteOption(index, idx, option_idx)} className="btn p-1"><i className="fas fa-xmark"></i></button>
                                                                                        
                                                                                            {item.movesection && item.optionobj === '객관식' &&  (
                                                
                                                                                                <Select
                                                                                                    isSearchable={false} options={modifiedOptions}
                                                                                                    onChange={(e) => setMoveQuestion(e, index, idx, option_idx)}
                                                                                                    value={modifiedOptions.find(option => option.value === item.optionjson.option[option_idx].move_seq)}
                                                                                                    ind={idx}
                                                                                                    className="w-50"
                                                                                                    placeholder="선택"
                                                                                                    aria-label="선택 시 특정 섹션으로 이동" />
                                                                                            )}
                                                
                                                                                        </li>
                                                                                    ))}
                                                
                                                                                </ul>
                                                
                                                                                {/* 옵션 추가 */}
                                                                                {(item.optionobj === '객관식' || item.optionobj === '복수') && (
                                                                                    <li className="text-center">
                                                                                        <button type="button" onClick={() => addOption(index, idx)} className="btn btn-primary light">옵션 추가</button>
                                                                                    </li>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    { item.optionobj === '드롭다운' && (
                                                                        <div className="col-xl-5 col-lg-6 col-sm-12">
                                                                            <div className="form-group">
                                                                                <label className="form-label">드롭다운형</label>
                                                                                <ul className="onlyone">
                                                                                    {item.optionjson.option.map((option, option_idx) => (
                                                                                        <li key={option_idx} className="d-flex gap-2 align-items-center mb-2">
                                                                                            <span className="option"></span>
                                                                                            <input id="" type="text"
                                                                                                onChange={(e) => setOption(e, index, idx, option_idx)}
                                                                                                value={item.optionjson.option[option_idx].value}
                                                                                                placeholder="옵션 입력"
                                                                                                className="form-control"
                                                                                                required />
                                                                                            <button type="button" onClick={() => deleteOption(index, idx, option_idx)} className="btn p-1"><i className="fas fa-xmark"></i></button>
                                                                                            {item.movesection &&  (
                                                                                                <Select
                                                                                                    isSearchable={false} options={modifiedOptions}
                                                                                                    onChange={(e) => setMoveQuestion(e, index, idx, option_idx)}
                                                                                                    value={modifiedOptions.find(option => option.value === item.optionjson.option[option_idx].move_seq)}
                                                                                                    ind={idx}
                                                                                                    className="w-50"
                                                                                                    placeholder="선택"
                                                                                                    aria-label="선택 시 특정 질문으로 이동" />
                                                                                            )}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    { item.optionobj === '선형배율' && (
                                                                        <div className="col-xl-5 col-lg-6 col-sm-12">
                                                                            <div className="form-group">
                                                                                <label className="form-label">선형배율형</label>
                                                                                <ul className="onlyone">
                                                                                    <li className="d-flex gap-2 align-items-center mb-2">
                                                                                        <span>1</span>
                                                                                        <input onChange={(e) => setRangeText(e, 'mintext', index, idx)} 
                                                                                            id="" placeholder="라벨" type="text" className="form-control"
                                                                                            value={item.optionjson.mintext}
                                                                                            required />
                                                                                    </li>
                                                                                    <li className="d-flex gap-2 align-items-center mb-2">
                                                                                        <span>5</span>
                                                                                        <input onChange={(e) => setRangeText(e, 'maxtext', index, idx)} 
                                                                                            id="" placeholder="라벨" type="text" className="form-control"
                                                                                            value={item.optionjson.maxtext}
                                                                                            required />
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {/*
                                                                        <div className="col-xl-5 col-lg-6 col-sm-12">
                                                                            <div className="form-group">
                                                                                <label className="form-label">날짜형</label>
                                                                                <div className="input-hasicon mb-xl-0 mb-3">
                                                                                    <DatePicker placeholder="월/일/년" className="picker-suit" aria-label="날짜형"
                                                                                        readOnly />
                                                                                    <div className="icon"><i className="far fa-calendar" /></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-5 col-lg-6 col-sm-12">
                                                                            <div className="form-group">
                                                                                <label className="form-label">시간형</label>
                                                                                <div className="input-hasicon mb-xl-0 mb-3">
                                                                                    <DatePicker placeholder="시:분" className="picker-suit" aria-label="시간형"
                                                                                        readOnly />
                                                                                    <div className="icon"><i className="fas fa-clock" /></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div>
                                                        <button type="button" onClick={() => addQuest(index)} className="btn btn-primary light">질문 추가</button>
                                                    </div>
                                                </div>
                                            )}
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ))}
                    
                <button type="button" onClick={() => addSection('insert')} className="btn btn-primary" aria-label="섹션 추가하기 버튼">섹션 <i className="fas fa-plus"></i></button>
            
            </div>

            <div className="d-flex justify-content-center">
                <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                <button type="button" className="btn btn-primary me-1" onClick={saveQuest}>저장</button>
            </div>
        </>
    );
};

export default Surveyform;