import React, {useState, useRef, useEffect} from 'react';
import { Row, Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

const theadData = [
    {heading: '관리자명', sortingValue:"name"},
    {heading: '아이디', sortingValue:"loginid"},
    {heading: '관리', sortingValue:"action"},
];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const SuperAdmin = () => {

    useTitle("슈퍼관리자 관리");

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const navigate = useNavigate();

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const totalPages = Math.ceil(totalCnt / row);
    
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const [tableData, setTableData] = useState([...dataList]);

    const [selectedAdminid, setSelectedAdminid] = useState('');
    const [password, setPassword] = useState('');
    const [mode, setMode] = useState('');

    // 슈퍼관리자 리스트 가져오기
    const getAdmins = async () => {
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }

        try {
            const response = await axios.get(apiUrl+`/admin/list/?page=${activePage.current}&rows=${row}&sort=${sortStr}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    }; 

    useEffect(() => {

        getAdmins();
        
    }, []);

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getAdmins();
        }else{
            activePage.current = 1;
            getAdmins();
        }
    }, [row]);

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getAdmins();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            onClick(activePage.current - 2);
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages ) {
            onClick(activePage.current);
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages , startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getAdmins();
        }
    }, [sort]);


    // 변경,삭제 클릭시 나오는 비밀번호 모달창
    const [modalCentered, setModalCentered] = useState(false);

    // 슈퍼관리자 삭제
    const deleteAdmin = async () => {
        var result = window.confirm("슈퍼관리자를 삭제하시겠습니까?");
        if(result){
            try {
                const response = await axios.delete(apiUrl+'/admin/delete/' + selectedAdminid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("슈퍼관리자 삭제하였습니다.");
                    setModalCentered(true);
                    activePage.current = 1;
                    getAdmins();
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 변경/삭제 버튼 클릭 시
    const editInfo = (adminid, mode) => {
        setModalCentered(true);
        setMode(mode);
        setSelectedAdminid(adminid);
    };

    // 비밀번호 확인하기
    const checkPassword = async () => {

        var params = {
            adminid: selectedAdminid,
            password: password
        }

        try {
            const response = await axios.post(apiUrl+'/admin/check-password', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                if(mode == 'edit'){
                    navigate(`/SuperAdminRegi/${selectedAdminid}`);
                }else{
                    deleteAdmin();
                }
            }else{
                alert("비밀번호가 일치하지 않습니다.");
                return false;
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    return (
        <>
            <PageTitle motherMenu={"홈페이지관리"} activeMenu={"슈퍼 관리자 관리"}  />
            <Row>
                <div className="col-lg-12">
                    <div className="card">
                    <div className="card-header d-flex justify-content-end flex-wrap gap-2">
                            <Link to={"/SuperAdminRegi/0"} className="btn btn-primary btn-sm">+ 등록</Link>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                등록된 관리자 : {totalCnt}
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table table-bordered">
                                        <thead>
                                            <tr>
                                                {theadData.map((item, ind)=>(
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td>{data.name}</td>
                                                    <td>{data.loginid}</td>
                                                    <td className='btns'>
                                                        <button type="button"
                                                            className="btn btn-primary me-1 light" onClick={() => editInfo(data.adminid, 'edit')}>변경</button>
                                                        <button type="button"
                                                            className="btn btn-danger me-1 light" onClick={() => editInfo(data.adminid, 'delete')}>삭제</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'>
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

            {/* 비밀번호 입력 모달창 */}
            <Modal className="fade" show={modalCentered} onHide={setModalCentered} centered>
                <Modal.Body>
                    <div className="p-2">
                        <form onSubmit={(e)=> e.preventDefault()}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">비밀번호 입력</label>
                                        <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} className="form-control mt-2" placeholder="비밀번호를 입력해주세요" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=> setModalCentered(false)}
                        variant="light"
                        >
                        취소
                    </Button>
                    <Button variant="primary" onClick={checkPassword}>확인</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SuperAdmin;