import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var curriculums = [];

const BookRegi = () => {

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '교재 수정' : '교재 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    var getBookCnt = 0;

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        lectureid: '',
        curriculumid: '',
        title: '',
        target: '',
        note: '',
    });

    const [files, setFiles] = useState([]);

    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [curriculumData, setCurriculumData] = useState([...curriculums])

    const [menuName, setMenuName] = useState({
        activeMenu: '교재등록',
        pageContent: '교재 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    useEffect(() => {

        // 수정 모드인 경우 데이터를 가져와서 폼에 채움
        const getUpdateData = async () => {
            try {
                const [lecturetypeResponse, curriculumResponse, bookResponse, fileResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/curriculum', {
                        headers: {
                            token: accessToken
                        }
                    }),
                    axios.get(apiUrl + '/book/info/' + id, {
                        headers: {
                            token: accessToken
                        }
                    }),
                    axios.get(apiUrl + '/book/filelist/' + id, {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                curriculums = curriculumResponse.data.data.map(curriculum => ({
                    label: curriculum.title,
                    value: curriculum.curriculumid
                }));

                setCurriculumData(curriculums);

                const bookData = bookResponse.data.data;

                if(!bookData.note){
                    bookData.note = "";
                }

                var selectedCurr = {};
                for(var i = 0; i < curriculums.length; i++){
                    if(bookData.curriculumid == curriculums[i].value){
                        selectedCurr = {
                            label: curriculums[i].label,
                            value: curriculums[i].value
                        };
                    }
                }
                
                setFormData(prevFormData => ({
                    ...prevFormData,
                    lectureid: bookData.lectureid,
                    curriculumid: selectedCurr,
                    title: bookData.title,
                    target: bookData.target,
                    note: bookData.note,
                }));

                var fileData = fileResponse.data.data;
                for(var i = 0; i < fileData.length; i++){
                    fileData[i].name = fileData[i].originfile;
                }
                setFiles(fileData);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        const getInsertData = async () => {
            try {
                const [lecturetypeResponse, curriculumResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/curriculum'),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                curriculums = curriculumResponse.data.data.map(curriculum => ({
                    label: curriculum.title,
                    value: curriculum.curriculumid
                }));

                setCurriculumData(curriculums);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id !== "0" && id !== 0) {
            if(getBookCnt == 0){
                getBookCnt++;
                setMode('update');
                setMenuName(prevState => ({
                    ...prevState,
                    activeMenu: '교재수정하기',
                    pageContent: '교재 수정'
                }))
                getUpdateData();
            }
        }else{
            getInsertData();
        }
        
    }, [id]);

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'lectureid') {  
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: Number(value)
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    // 교재 소속 변경 시
    const handleCurriculumChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            curriculumid: e
        }));
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        
        if(mode === "insert"){
            insertBook();
        }else{
            updateBook();
        }
    };

    // 교재 등록
    const insertBook = async () => {

        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        var curriculumid = params.curriculumid.value;
        params.curriculumid = curriculumid;

        var fileLen = files.length;
        var uploadCnt = 0;
        for(var i = 0; i < fileLen; i++){
            params.file = files[i].file;
            params.originfile = files[i].originfile;

            //console.log(params);

            try {
                const response = await axios.post(apiUrl+'/book/registerFile', params, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    uploadCnt++;

                    if(uploadCnt == fileLen){
                        alert("교재 저장하였습니다.");
                        navigate('/AllBook');
                    }
                }
    
            } catch (error) {
                console.error('Error:', error);
            }

        }
    };

    // 교재 수정
    const updateBook = async () => {
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var params = {...formData};
        params.bookid = id;

        var curriculumid = params.curriculumid.value;
        params.curriculumid = curriculumid;

        params.file = files[0].file;
        params.originfile = files[0].originfile;

        //console.log(params);

        try {
            const response = await axios.put(apiUrl+'/book/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("교재 저장했습니다.");
                navigate('/AllBook');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // form 유효성 검사
    const checkFormData = () => {
        if(formData.lectureid == "" || !formData.lectureid){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.curriculumid == "" || !formData.curriculumid){
            alert("커리큘럼을 선택해주세요.");
            return false;
        }
        if(formData.target == "" || !formData.target){
            alert("노출대상을 선택해주세요.");
            return false;
        }
        if(files.length == 0){
            alert("교재파일을 업로드해주세요.");
            return false;
        }

        return true;
    }

    // 교재 삭제
    const deleteBook = async () => {
        var result = window.confirm("교재를 삭제하시겠습니까?");
        if(result){
            var bookid = id;
            try {
                const response = await axios.delete(apiUrl+'/book/delete/' + bookid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("교재 삭제하였습니다.");
                    navigate('/AllBook');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 파일 업로드 시
    const handleFileChange = async (e) => {
        const fileList = e.target.files;
        var updatedFiles = [];

        for (let i = 0; i < fileList.length; i++) {
            var file = {};
            var fileInfo = fileList[i];
            fileInfo.filename = fileInfo.name;

            var fileData = new FormData();
            fileData.append('attachment', fileInfo);
            fileData.append('folder', 'book');

            //console.log(fileData);
            
            try {
                const response = await axios.post(apiUrl+'/file/single', fileData, {
                    headers: {
                        token: accessToken
                    }                
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    file.bookfileid = 0;
                    file.bookid = id;
                    file.file = res.data.key;
                    file.originfile = fileInfo.filename;
                    file.name = fileInfo.filename;

                    updatedFiles.push(file);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }

        setFiles(updatedFiles);
    };

    // 파일 삭제
    const handleFileDelete = async (index) => {
        setFiles([]);
        /*
        const updatedFiles = [...files];

        if(mode == "insert"){
            updatedFiles.splice(index, 1);
            setFiles(updatedFiles);
        }else{
            var fileInfo = updatedFiles[index];
            
            try {
                const response = await axios.delete(apiUrl+'/book/deleteFile/'+fileInfo.bookfileid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    updatedFiles.splice(index, 1);
                    setFiles(updatedFiles);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
        */
    };

    // 이전 페이지로
    const goBack = () => {
        navigate('/AllBook');
    }

    return (
        <>
            <PageTitle  motherMenu={"콘텐츠관리"} grandMenu={"교재관리"} grandMenuLink="/AllBook" activeMenu={menuName.activeMenu}/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="lectureid">강의유형</label>
                                            <div className='d-flex gap-2'>
                                                <div>
                                                    {lecturetypeData.map((item, index) =>(
                                                        <div key={index} className="form-check form-check-inline">
                                                            <label className="form-check-label"
                                                                htmlFor={`lectureid-${index}`}>
                                                                <input className="form-check-input" type="radio"
                                                                    name="lectureid" id={`lectureid-${index}`}
                                                                    value={item.lectureid}
                                                                    checked={formData.lectureid===item.lectureid}
                                                                    onChange={handleChange} />
                                                                {item.lecturetype}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <Link to="/lectureTypeRegi/0" className="btn btn-primary light">+
                                                강의유형 추가</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">커리큘럼</label>
                                            <div className='d-flex gap-2'>
                                                 <Select 
                                                    isSearchable={false}
                                                    /* defaultValue={options[1]} */
                                                    options={curriculumData} 
                                                    value={formData.curriculumid}
                                                    onChange={handleCurriculumChange}
                                                    className="custom-react-select" 
                                                    placeholder="선택"
                                                    aria-label="커리큘럼 선택"
                                                />
                                                <Link to="/curriculumRegi/0" className="btn btn-primary light">+
                                                    커리큘럼 추가</Link>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="title">교재명</label>
                                            <input 
                                                id="title" 
                                                placeholder="교재명 입력" 
                                                type="text" 
                                                className="form-control" 
                                                name="title"
                                                value={formData.title} onChange={handleChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="NoticePartner">노출대상</label>
                                            <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="target"
                                                        id='student'
                                                        value='tutee'
                                                        checked={formData.target === 'tutee'}
                                                        onChange={handleChange}
                                                        />
                                                    <label className="form-check-label" htmlFor='student'>수강생</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="target"
                                                        id='teacher'
                                                        value='tutor'
                                                        checked={formData.target === 'tutor'}
                                                        onChange={handleChange}
                                                        />
                                                    <label className="form-check-label" htmlFor='teacher'>강사</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="target"
                                                        id='all'
                                                        value='all'
                                                        checked={formData.target === 'all'}
                                                        onChange={handleChange}
                                                        />
                                                    <label className="form-check-label" htmlFor='all'>전체</label>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Address">비고</label>
                                            <textarea id="note" 
                                                placeholder="비고 입력" 
                                                name="note"
                                                value={formData.note} onChange={handleChange}
                                                className="form-control" 
                                                rows="5"  
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12 mutifile">
                                        {(mode == 'insert') ? (
                                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                                <Form.Label>파일선택(복수 파일 업로드 가능)</Form.Label>
                                                <Form.Control type="file" multiple onChange={handleFileChange} className="w-auto" />
                                            </Form.Group>
                                        ) : (
                                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                                <Form.Label>파일선택</Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} className="w-auto" />
                                            </Form.Group>
                                        )}
                                        {files.length > 0 && (
                                            <div className="fileList">
                                                <ul className="">
                                                    {files.map((file, index) => (
                                                    <li key={index}>
                                                        {file.name}
                                                        <button type="button" className="btn" 
                                                            >
                                                            <i className="fas fa-xmark"onClick={()=>
                                                            handleFileDelete(index)} aria-label="파일삭제 " ></i>
                                                        </button>
                                                    </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary me-1" onClick={handleSubmit}>등록</button>
                                        {mode != 'insert' && (
                                            <button onClick={deleteBook} className="btn btn-danger light me-1" variant="danger">
                                                삭제
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookRegi;