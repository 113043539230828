import React, {useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { DatePicker } from 'rsuite';

// 위젯캘린더 https://github.com/wojtekmaj/react-calendar
import Calendar from 'react-calendar';
import moment from 'moment';
import styled from 'styled-components';
import "react-calendar/dist/Calendar.css";

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

const StyledCalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledCalendar = styled(Calendar)`
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.13);
  padding: 3% 5%;
  background-color: white;
`;

//Charts
const DualArea  = loadable(() =>
 	pMinDelay(import("./chart"), 500)
);

const options = [
    { value: '1', label: '한국어' },
    { value: '2', label: '외국어' },
    { value: '3', label: '기타' }
]

var lecturetypes = [];
var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const Home = () => {	

	useTitle("대시보드");

	const accessToken = useSelector(state => state.auth.auth.accessToken);
    const navigate = useNavigate();

	const [tuteeCnt, setTuteeCnt] = useState(0);
	const [affiliationCnt, setAffiliationCnt] = useState(0);

	var today = new Date();
    var nowYear = today.getFullYear();
    var nowMonth = (today.getMonth() + 1).toString().padStart(2, '0');

	//캘린더
    const calendarRef = useRef(null);
    const [startdate, setStartdate] = useState(null);
    const [enddate, setEnddate] = useState(null);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dailyList, setDailyList] = useState([]);

	// 차트
	const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes]);
	const [lectureid, setLectureid] = useState("");
	const [loading, setLoading] = useState(true);
	const [chartData, setChartData] = useState({});
	const [chartTableData, setChartTableData] = useState([]);
    const [chartStartdate, setChartStartdate] = useState(null);
    const [chartEnddate, setChartEnddate] = useState(null);

	useEffect(() => {
		// 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype');
                //console.log(response.data.data);
                var resData = response.data.data;
                lecturetypes = resData;
				for(var i = 0; i < lecturetypes.length; i++){
					lecturetypes[i].value = lecturetypes[i].lectureid;
					lecturetypes[i].label = lecturetypes[i].lecturetype;
				}
                setLecturetypeData(lecturetypes);
				setLectureid(lecturetypes[0].lectureid);
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        getLectureTypes();
		setNewDate(new Date(nowYear + "-" + nowMonth + "-01"));
		getMyClassSchedule();
	}, []);

	useEffect(() => {

        if(lectureid != ""){
            getTuteeStat();
        }

    }, [lectureid]);

	useEffect(() => {

        if(enddate){
            getCalendarList();
        }

    }, [enddate]);

	// 일정 리스트 가져오기
    const getCalendarList = async () => {

        try {
            const response = await axios.get(apiUrl+'/calendar/list?startdate='+startdate+'&enddate='+enddate, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];

						// startdate ~ enddate 까지의 일정을 다 넣어줌
						var sdate = new Date(info.startdate.split("T")[0]);
						var edate = new Date(info.enddate.split("T")[0]);
						var diffTime = Math.abs(edate - sdate);
						var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

						if(diffDays > 0){
							for(var j = 0; j <= diffDays; j++){
								// sdate부터 edate 까지 하루씩 더하기
								if(j == 0){
									var year = sdate.getFullYear();
									var month = sdate.getMonth() + 1;
									var day = sdate.getDate() + j;
									var schedule = {
										date: new Date(year, month-1, day), 
										color: info.background,
									}
									dataArr.push(schedule);
								}else{
									var newdate = new Date(sdate);
									newdate.setDate(sdate.getDate() + j);
									var year = newdate.getFullYear();
									var month = newdate.getMonth() + 1;
									var day = newdate.getDate();
									var schedule = {
										date: new Date(year, month-1, day), 
										color: info.background,
									}
									dataArr.push(schedule);
								}
							}
						}else{
							var sdate = new Date(info.startdate.split("T")[0]);
							var year = sdate.getFullYear();
							var month = sdate.getMonth() + 1;
							var day = sdate.getDate();
							var schedule = {
								date: new Date(year, month-1, day), 
								color: info.background,
							}
                        
                        	dataArr.push(schedule);
						}
							
                    }
                }

                setCalendarEvents(dataArr);
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

	useEffect(() => {

		if(selectedDate != null){
			getDailyCalendarList();
		}

	}, [selectedDate]);

	// 캘린더 날짜 변경
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
	};

	// 이전 달 / 다음 달 버튼 클릭 시
	const handleActiveStartDateChange = (data) => {
		setNewDate(data.activeStartDate);
	};
	
	// 캘린더의 날짜 표시
    const tileClassName = ({ date, view }) => {
		if (view === 'month') {
			const event = calendarEvents.find(event => event.date.getTime() === date.getTime());
			if (event) {
				return `event-${event.color}`;
			}
			const currentDate = new Date();
			if (date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()) {
				return 'today';
			}
		}
		return null;
	};

	// 일별 일정 가져오기
    const getDailyCalendarList = async () => {

		var selecteddate = formatDate(selectedDate);

		try {
			const response = await axios.get(apiUrl+'/calendar/dailylist?selecteddate='+selecteddate, {
				headers: {
					token: accessToken
				}
			});
			var res = response.data;
			//console.log(res);
			if(res.success){
				var resData = res.data;
				//console.log(resData);
				setDailyList(resData);
			}
		
		} catch (error) {
			console.error('Error:', error);
		}
    };

	// 날짜 포맷 변경
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
        const day = String(d.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };

	// 날짜 재설정
    const setNewDate = (currentDate) => {
        var year = currentDate.getFullYear();
        var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

		setStartdate(year+'-'+month+'-01');

		// get last day of month
		if(month == 2){
			if(year % 4 == 0){
				setEnddate(year+'-'+month+'-29');
			}else{
				setEnddate(year+'-'+month+'-28');
			}
		}else if(month == 4 || month == 6 || month == 9 || month == 11){
			setEnddate(year+'-'+month+'-30');
		}else{
			setEnddate(year+'-'+month+'-31');
		}
    };

	// 수강생 수 통계 차트
	const getTuteeStat = async () => {

		var sdate = "";
		var edate = "";

		if(chartStartdate && !chartEnddate){
			alert("종료날짜를 선택해주세요.");
			return false;
		}
		if(!chartStartdate && chartEnddate){
			alert("시작날짜를 선택해주세요.");
			return false;
		}
		if(chartStartdate && chartEnddate){
			if(chartStartdate > chartEnddate){
				alert("시작날짜가 종료날짜보다 클 수 없습니다.");
				return false;
			}
			sdate = formatDate(chartStartdate);
			edate = formatDate(chartEnddate);
		}

		try {
			const response = await axios.get(apiUrl+'/statistics/dashboard/'+lectureid + '?startdate=' + sdate + '&enddate=' + edate, {
				headers: {
					token: accessToken
				}
			});
			var res = response.data;
			//console.log(res);
			if(res.success){
				var resData = res.data;
				//console.log(resData);
				if(resData){
					setTuteeCnt(resData.tutee_count);
					setAffiliationCnt(resData.affiliation_count);

					// 날짜 데이터에서 T 제거
					var date_array = resData.chart_data.date_array;
					for(var i = 0; i < date_array.length; i++){
						date_array[i] = date_array[i].split("T")[0];
					}
					var chart_data = {
						labels: date_array,
						datasets: [
							{
								label: "수강생 수",
								data: resData.chart_data.tutee_data_array,
								borderColor: "rgba(106,115,250,1)",
								borderWidth: 2,
								backgroundColor: "rgba(106,115,250,0.2)",
								tension: 0.4,
								fill: false,
							},
							{
								label: "강사 수",
								data: resData.chart_data.tutor_data_array,
								borderColor: "rgba(255,97,117, 1)",
								borderWidth: 2,
								backgroundColor: "rgba(255,97,117, 0.1)",
								tension: 0.4,
								fill: false,
							},
						],
					};

					setChartData(chart_data);

					// 차트 테이블 데이터
					var tableArray = resData.statistics_table;
					for(var i = 0; i < tableArray.length; i++){
						tableArray[i].date = tableArray[i].date.split("T")[0];
					}

					setChartTableData(tableArray);
				}
			}
		
		} catch (error) {
			console.error('Error:', error);
		} finally {
			setLoading(false);
		}
    };

	// 차트 시작 날짜 변경 시
    const handleStartDateChange = (e) => {
        setChartStartdate(e);
    };

	// 차트 시작 날짜 변경 시
    const handleEndDateChange = (e) => {
        setChartEnddate(e);
    };

	useEffect(() => {

		if(chartStartdate && chartEnddate){
			getTuteeStat();
		}

	}, [chartStartdate, chartEnddate]);

	const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);

	// 강의 현황 리스트 가져오기
    const getMyClassSchedule = async () => {

		var startDate = new Date();
		startDate = formatDate(startDate);
		var endDate = new Date();
		endDate.setDate(endDate.getDate() + 7);
		endDate = new Date(endDate);
		endDate = formatDate(endDate);

        try {
            const response = await axios.get(apiUrl+'/tutoring/MyClassSchedule?page='+activePage.current+'&rows='+row+'&sort=datetxt,asc&start='+startDate+'&end='+endDate, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
        console.error('Error:', error);
        }
    };

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
		getMyClassSchedule();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
			getMyClassSchedule();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages ) {
            activePage.current += 1; // 1페이지씩 이동
			getMyClassSchedule();
        }
    };
    
    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages , startPage + maxButtons - 1);

        const paginationButtons = [];

    	for (let i = startPage; i <= endPage; i++) {
			paginationButtons.push(
				<Link
					key={i}
					to='#'
					className={`paginate_button ${currentPage === i ? 'current' : ''}`}
					onClick={() => onClick(i - 1)}
				>
					{i}
				</Link>
			);
		}

        return paginationButtons;
    };

	// 강의실 입장하기
    const enterClassroom = async (join_url) => {
        window.open(join_url, "_blank");        
    };

	if (loading) {
		return <div>Loading...</div>; // 로딩 중일 때 표시할 내용
	}

	return(
		<>
			<Row role="region" aria-label="대시보드 섹션">
				<Col xl={'3'} xxl={'4'} sm={'12'}>
					<div className="widget-stat card" role="region" aria-label="수강생 및 소속 정보">
						<div className="card-body">
							<div className='d-flex flex-column justify-content-between gap-3'>
								<div className="media bg-primary">
									<span className="me-3">
										<i className="la la-users" aria-hidden="true"></i>
									</span>
									<div className="media-body text-white">
										<h5 className="mb-1 text-white">수강생 수</h5>
										<p className="text-white"><b>{tuteeCnt}</b> 명 +</p>
										<div className="progress mb-2 bg-primary">
											<div className="progress-bar progress-animated bg-white" style={{width: '100%'}}></div>
										</div>
									</div>
								</div>

								<div className="media bg-red">
									<span className="me-3">
										<i className="fas fa-building" aria-hidden="true"></i>
									</span>
									<div className="media-body text-white">
										<h5 className="mb-1 text-white">소속 수</h5>
										<p className="text-white"><b>{affiliationCnt}</b> 개 +</p>
										<div className="progress mb-2 bg-red">
											<div className="progress-bar progress-animated bg-white" style={{width: '100%'}}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Col>

				<Col xl={'3'} xxl={'3'} sm={'6'}>
					<StyledCalendarWrapper className='card' role="region" aria-label="달력">
						<StyledCalendar
							value={selectedDate}
							onChange={handleDateChange}
							formatDay={(locale, date) => moment(date).format("D")} // 일 제거 숫자만 보이게
							formatYear={(locale, date) => moment(date).format("YYYY")} // 네비게이션 눌렀을때 숫자 년도만 보이게
							formatMonthYear={(locale, date) => moment(date).format("YYYY. MM")} // 네비게이션에서 2023. 12 이렇게 보이도록 설정
							calendarType="gregory" // 일요일 부터 시작
							next2Label={null} // +1년 & +10년 이동 버튼 숨기기
							prev2Label={null} // -1년 & -10년 이동 버튼 숨기기
							minDetail="year" // 10년단위 년도 숨기기
							tileClassName={tileClassName}
							onActiveStartDateChange={handleActiveStartDateChange} 
						/>
					</StyledCalendarWrapper>
				</Col>

				<Col xl={'6'} xxl={'5'} sm={'6'}>
					<div className='card' role="region" aria-label="일정 상세 내용">
						<div className="card-body">
							<h4 className="card-intro-title mb-0">일정 상세 내용</h4>
							<p>
								달력에 표시되어있는 날짜를 클릭하여 상세 일정을 확인하세요.
							</p>
							<div id="external-events" className="my-3">
								{dailyList.map((item, index)=>(
									<div key={index} className='fc-event external-event light btn-primary'>
										<span>✔ ({item.team}) {item.contents}</span>        
									</div>
								))}	
							</div>
						</div>
					</div>
				</Col>
		
				<Col xl={'6'} xxl={'6'} lg={'6'} sm={'6'}>
					<div className="card" role="region" aria-label="통계">
						<div className="card-header">
							<h3 className="card-title d-flex justify-content-between w-100">
								<span>Statistics</span>
								<div className='d-flex gap-3 align-items-center'>
									<div className="input-hasicon mb-xl-0 mb-3">
										<DatePicker
											value={chartStartdate}
											onChange={handleStartDateChange}
											placeholder="시작날짜 선택"
											className="picker-suit" />
										<div className="icon"><i className="far fa-calendar" /></div>
									</div>
									<span> ~ </span>
									<div className="input-hasicon mb-xl-0 mb-3">
										<DatePicker
											value={chartEnddate}
											onChange={handleEndDateChange}
											placeholder="종료날짜 선택"
											className="picker-suit"  />
										<div className="icon"><i className="far fa-calendar" /></div>
									</div>
								</div>
								<Select
									isSearchable={false}
									defaultValue={lecturetypeData[0]}
									options={lecturetypeData}
									onChange={(selectedOption) => setLectureid(selectedOption.value)}
									className="custom-react-select"
								/>
							</h3>
						</div>
						<div className="card-body">
							{chartData ? <DualArea data={chartData} />	: 'Loading...' }
						</div>
					</div>
				</Col>

				<Col xl={'6'} xxl={'6'} lg={'6'} sm={'6'}>
					<div className="card" role="region" aria-label="테이블">
						<div className="card-header">
							<h3 className="card-title w-100">
								<span>Statistics</span>
							</h3>
						</div>
						<div className="card-body">
								<div className="table-responsive recentOrderTable">
								<table className="table table-sm mb-0 table-striped text-nowrap">
									<thead>
										<tr>
											<th scope="col">구분</th>
											<th scope="col">수강생 수</th>
											<th scope="col">강사 수</th>
										</tr>
									</thead>
									<tbody>
										{chartTableData.map((item, index)=>(
											<tr key={index}>
												<td>{item.lecturetype}</td>
												<td>{item.tutee_count} 명</td>
												<td>{item.tutor_count} 명</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>	
						</div>
					</div>
				</Col>
				
				<Col xl={'12'} xxl={'12'} lg={'12'} sm={'12'}>
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">&nbsp;</h4>
							<Link to={"/InstructorLecture"} className="btn btn-primary">전체보기</Link>
						</div>
						<div className="card-body">
							<div className="table-responsive">
								<div id="content_wrapper" className="dataTables_wrapper no-footer">
									<div className='justify-content-between d-sm-flex'>
										<div className='dataTables_length'>
											<label className='d-flex align-items-center'>
												Total : <b>{totalCnt}</b> 건 &nbsp;
											</label>
										</div>
									</div>
									<table className="table table-responsive-lg  table-condensed flip-content">
										<thead>
											<tr>
												<th scope="col">날짜</th>
												<th scope="col">요일</th>
												<th scope="col">강사</th>
												<th scope="col">시작시간</th>
												<th scope="col">종료시간</th>
												<th scope="col">교재</th>
												<th scope="col">차시</th>
												<th scope="col">강의실</th>
											</tr>
										</thead>
										<tbody>
											{tableData.map((data, index)=>(
											<tr key={index}>
												<td>{data.datefulltxt}</td>
												<td>{data.weektxt}</td>
												<td>{data.tutorname}</td>
												<td>{data.starttxt}</td>
												<td>{data.endtxt}</td>
												<td>
													{data.booktitle}
												</td>
												<td>{data.round}</td>
												<td>
													{data.join_url ? (
														<button type='button' onClick={() => enterClassroom(data.join_url)} className="btn btn-success light">
															입장하기
														</button>
													):  null}
												</td>
											</tr>
											))}
										</tbody>
									</table>
									<div className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
										<div className='dataTables_paginate paging_simple_numbers' id='example5_paginate'>
											<Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Col>
			</Row>				
		</>
	)
}
export default Home;
