export const MenuList = [
    //Main Menu
    {
        title:"Main Menu",
        classsChange: "menu-title",
        extraclass:"first"
    },
    //Dashboard
    {
        title: '대시보드',	        
        to: "/",
        iconStyle: <i className="la la-home" />, 

    },
    


    {
        title:"회원관리",
        classsChange: "menu-title"
    },

    {
        title: '수강생 관리',	        
        to:"/AllStudent",
        iconStyle: <i className="la la-users" />,  
        content: [
            {
                title: '수강생 등록',
                to: "/StudentRegi",
            },
            {
                title: '수강생 수정',
                to: "/StudentRegi/:id",
            },
            {
                title: '수강생 일괄 등록',
                to: "/BulkStudentRegi",
            },
            
        ]
    },

    {
        title: '강사관리',	        
        to:"/AllTeacher",
        iconStyle: <i className="la la-user" />,    
        content: [
            {
                title: '강사 등록',
                to: "/TeacherRegi/0",
            },
            {
                title: '강사 수정',
                to: "/TeacherRegi/:id",
            },
            {
                title: '강사 일괄 등록',
                to: "/BulkTeacherRegi",
            },
            
        ]
    },

    {
        title: '소속관리',	        
        to:"/AllBelong",
        iconStyle: <i className="la la-building" />, 
        content: [
            {
                title: '소속 등록',
                to: "/BelongRegi/0",
            },
            {
                title: '소속 수정',
                to: "/BelongRegi/:id",
            },
            
        ]
       
    },
    



      //수업관리
    {
        title:"수업관리",
        classsChange:"menu-title"
    },
    {
        title: '강의유형 관리',	 
        to:"/lectureType",
        iconStyle: <i className="la la-gift" />, 
        content: [
            {
                title: '강의유형 관리 등록',
                to: "/lectureTypeRegi/0",
            },
            {
                title: '강의유형 관리 수정',
                to: "/lectureTypeRegi/:id",
            },
            
        ]
    },
    {
        title: '강의 현황',	        
        to:"/AllLecture",
        iconStyle: <i className="la la-paste" />, 
         content: [
            {
                title: '강의 자료',
                to: "/LectureData",
            },
            
        ]
        
    },
     {
        title: '강사 강의 관리',	        
        to:"/InstructorLecture",
         iconStyle: <i className="la la-graduation-cap" />,  
        content: [
            {
                title: '강사 강의 등록',
                to: "/InstructorLectureRegi",
            },
            {
                title: '강사 강의 수정',
                to: "/InstructorLectureRegi/:id",
            },
            
        ]
    },
    {
        title: '공지사항 관리',	        
        to:"/Notice",
        iconStyle: <i className="la la-bullhorn" />,  
        content: [
            {
                title: '공지사항 등록',
                to: "/NoticeRegi",
            },
            {
                title: '공지사항 수정',
                to: "/NoticeRegi/:id",
            },
            
        ]
    },
    {
        title: '만족도조사 관리',	        
        to:"/Survey",
        iconStyle: <i className="la la-thumbs-o-up" />,  
        content: [
            {
                title: '만족도조사 등록',
                to: "/SurveyRegi",
            },
            {
                title: '만족도조사 수정',
                to: "/SurveyRegi/:id",
            },
            {
                title: '만족도조사 폼 생성',
                to: "/Surveyform",
            },
        ]
    },
    

     //콘텐츠관리
    {
        title:"콘텐츠관리",
        classsChange:"menu-title"
    },
    {
        title: '교재 관리',	        
        to:"/AllBook",
        iconStyle: <i className="la la-book" />, 
        content: [
            {
                title: '교재 등록',
                to: "/BookRegi",
            },
            {
                title: '교재 수정',
                to: "/BookRegi/:id",
            },
        ]
    },
    {
        title: '커리큘럼 관리',	        
        to:"/curriculum",
        iconStyle: <i className="la la-list" />, 
        content: [
            {
                title: '커리큘럼 등록',
                to: "/curriculumRegi/0",
            },
            {
                title: '커리큘럼 수정',
                to: "/curriculumRegi/:id",
            },
        ]
    },


    //홈페이지 관리
    {
        title:"홈페이지관리",
        classsChange:"menu-title"
    },
    {
        title: '일정 관리',	        
        to:"/Schedule",
        iconStyle: <i className="la la-calendar" />,        
    },
    {
        title: '수강확인서 관리',	        
        to:"/CourseConfirm",
        iconStyle: <i className="la la-certificate" />,        
    },
    {
        title: '기본정보 관리',	        
        to:"/BasicInformation",
        iconStyle: <i className="la la-smile-o" />,        
    },
     {
        title: '슈퍼 관리자 관리',	        
        to:"/SuperAdmin",
        iconStyle: <i className="la la-user-secret" />,        
    },
    {
        title: '서비스 콘텐츠 관리',	        
        to:"/Contents",
        iconStyle: <i className="la la-folder-open" />, 
        content: [
            {
                title: '서비스 콘텐츠 등록',
                to: "/ContentsRegi/0",
            },
            {
                title: '서비스 콘텐츠 수정',
                to: "/ContentsRegi/:id",
            },
        ]
        
    },
    {
        title: '배너 관리',	        
        to:"/Banner",
        iconStyle: <i className="la la-copy" />,  
        content: [
            {
                title: '배너 등록',
                to: "/BannerRegi/0",
            },
            {
                title: '배너 수정',
                to: "/BannerRegi/:id",
            },
        ]
    },
    {
        title: '로고 관리',	        
        to:"/Logo",
        iconStyle: <i className="la la-flag-o" />, 
        content: [
            {
                title: '로고 등록',
                to: "/LogoRegi/0",
            },
            {
                title: '로고 수정',
                to: "/LogoRegi/:id",
            },
        ]
    },

 
   
    
]