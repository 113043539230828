import React, {useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Row, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Select from 'react-select';
import { DatePicker } from 'rsuite';

import PageTitle from '../../layouts/PageTitle';

import axios from 'axios';

import config from '../../../config';
import { set } from 'rsuite/esm/utils/dateUtils';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var dataList = [];

const theadData = [
    {heading: 'No.', sortingValue:"scheduleid"},
    {heading: '강의유형', sortingValue:""},
    {heading: '소속', sortingValue:""},
    {heading: '강사명', sortingValue:""},
    {heading: '날짜', sortingValue:"datetxt"},
    {heading: '요일', sortingValue:"weektxt"},
    {heading: '시작시간', sortingValue:"starttxt"},
    {heading: '종료시간', sortingValue:"endtxt"},
    {heading: '시간간격', sortingValue:"duration"},
    {heading: '삭제', sortingValue:""},
];

const updateTheadData = [
    {heading: '강의유형', sortingValue:""},
    {heading: '소속', sortingValue:""},
    {heading: '강사명', sortingValue:""},
    {heading: '날짜', sortingValue:"datetxt"},
    {heading: '요일', sortingValue:"weektxt"},
    {heading: '시작시간', sortingValue:"starttxt"},
    {heading: '종료시간', sortingValue:"endtxt"},
    {heading: '시간간격', sortingValue:"duration"},
    {heading: '수강생', sortingValue:""},
    {heading: '교재', sortingValue:""},
];

// 수강생 Multi-Select
const CustomClearText = () => "모두 지우기";
const ClearIndicator = (props) => {
   const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
   } = props;
   return (
      <div
         {...restInnerProps}
         ref={ref}
         style={getStyles("clearIndicator", props)}
      >
         <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
   );
};
const ClearIndicatorStyles = (base, state) => ({
   ...base,
   cursor: "pointer",
   color: state.isFocused ? "blue" : "black",
});


const InstructorLectureRegi = () => {

    const [id, setId] = useState(useParams()); // URL 매개변수에서 ID 가져오기
    const isEditing = id.id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '강사 강의 수정' : '강사 강의 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    const accessToken = useSelector(state => state.auth.auth.accessToken);
    const navigate = useNavigate();

    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes]);
    const [affiliationsData, setAffiliationsData] = useState([{label: "선택", value: ""}]);
    const [tutorData, setTutorData] = useState([{label: "선택", value: ""}])

    const [formData, setFormData] = useState({
        lectureid: 0,
        affiliations: null,
        tutorid: null,
        tuteeid: '',
        bookid: '',
        startdate: null,
        enddate: null,
        duration: '30',
        holidayclass: 'open',
        selecttimes: [],
    });

    // 요일
    const [selectedDays, setSelectedDays] = useState([false, false, false, false, false, false, false]); 
    
    // 오전/오후 선택
    const ampmlist = ['오전', '오후'];
    // 시 선택
    const hourlist = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    // 분 선택
    const minutelist = ['00', '30'];

    // 시작 시간
    const [starttime, setStarttime] = useState({
        ampm: '',
        hour: '',
        minute: '',
    });

    // 종료 시간
    const [endtime, setEndtime] = useState({
        ampm: '',
        hour: '',
        minute: '',
    });
    
    const radioRef = useRef(null);

    const [menuName, setMenuName] = useState({
        activeMenu: '강사 강의 등록하기',
        pageContent: '강사 강의 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    const [resetFormSelectedOption, setResetFormSelectedOption] = useState(false); // 드롭다운 리셋 상태
    const [resetSelectedOption, setResetSelectedOption] = useState(false); // 드롭다운 리셋 상태

    const [getTutoringCnt, setGetTutoringCnt] = useState(0);

    useEffect(() => {

        // 수정 모드인 경우 데이터를 가져와서 폼에 채움
        const getUpdateData = async () => {
            var tutoringid = id.id;

            try {
                const [curriculumResponse, tutorResponse, tuteeResponse, bookResponse] = await Promise.all([
                    axios.get(apiUrl + '/curriculum', {
                        headers: {
                            token: accessToken
                        }
                    }),
                    axios.get(apiUrl + '/tutor', {
                        headers: {
                            token: accessToken
                        }
                    }),
                    axios.get(apiUrl + '/tutee/list', {
                        headers: {
                            token: accessToken
                        }                    
                    }),
                    axios.get(apiUrl + '/book/list', {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);
                
                const tutors = tutorResponse.data.data.map(tutor => ({
                    label: tutor.name,
                    value: tutor.tutorid
                }));
                setTutorData(tutors);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        const getInsertData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse, tutorResponse, tuteeResponse, bookResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                    axios.get(apiUrl + '/tutor', {
                        headers: {
                            token: accessToken
                        }
                    }),
                    axios.get(apiUrl + '/tutee/list', {
                        headers: {
                            token: accessToken
                        }                    
                    }),
                    axios.get(apiUrl + '/book/list', {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);

                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);
                
                const tutors = tutorResponse.data.data.map(tutor => ({
                    label: tutor.name,
                    value: tutor.tutorid
                }));
                setTutorData(tutors);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id.id !== "0" && id.id !== 0 && getTutoringCnt == 0) {
            setGetTutoringCnt(1);
            setMode('update');
            setMenuName(prevState => ({
                ...prevState,
                activeMenu: '강사 강의 수정하기',
                pageContent: '강사 강의 수정'
            }));

            getUpdateData();
            getTutoringList();
        }else if(id.id == "0" || id.id == 0){
            setGetTutoringCnt(1);
            getInsertData();
        }

        if((id.id !== "0" || id.id !== 0) && getTutoringCnt > 0){
            getTutoringList();
        }

    }, [id, getTutoringCnt]);

    useEffect(() => {

        if(formData.affiliations != null && formData.affiliations != undefined && formData.affiliations.value != ""){
            // 강사 리스트 가져오기
            getTutorList();

        }
        
    }, [formData.affiliations]);

    // 강사 리스트
    const getTutorList = async () => {
        try {
            const response = await axios.get(apiUrl+'/tutor?affiliations='+formData.affiliations.value);
            var res = response.data;
            //console.log(res);
            if(res.success){
                const tutors = res.data.map(tutor => ({
                    label: tutor.name,
                    value: tutor.tutorid
                }));
                setTutorData(tutors);
                setFormData(prevFormData => ({ 
                    ...prevFormData,
                    tutorid: ''
                }));
            }
        
        } catch (error) {
           console.error('Error:', error);
        }

    };


    // 강사소속 변경 시
    const handleAffiliationChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            affiliations: e
        }));
    };

    // 강사 변경 시
    const handleTutorChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            tutorid: e
        }));
    };

    // 교재 변경 시
    const handleBookChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            bookid: e
        }));
    };

    // 수강생 변경 시
    const handleTuteeChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            tuteeid: e
        }));
    };

    // 날짜 변경 시
    const handleStartDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            startdate: e
        }));
    };

    // 날짜 변경 시
    const handleEndDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            enddate: e
        }));
    };

    // 시간 select 값 변경 시
    const handleTimeChange = (event) => {
        const {name,value} = event.target;
        const nameArr = name.split("_");

        if (nameArr[0] === "starttime") {
            //console.log(nameArr)
            setStarttime({
                ...starttime,
                [nameArr[1]]: value
            });
        } else {
            setEndtime({
                ...endtime,
                [nameArr[1]]: value
            });
        }
    };

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name == "lectureid"){
            setFormData(prevState => ({
                ...prevState,
                [name]: Number(value)
            }));
        }else{
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));    
        }
        
         // 엔터키 처리
        if (e.key === 'Enter') {
            e.target.click(); // 라디오 버튼 클릭 시키기
        }
    };

    const weekdayRef = useRef(null);

    // 요일 선택
    const toggleDay = (index) => {
        const updatedDays = [...selectedDays];
        updatedDays[index] = !updatedDays[index];
        setSelectedDays(updatedDays);
    };

    const renderButtons = () => {
        const days = ['일', '월', '화', '수', '목', '금', '토'];
        return days.map((day, index) => (
            <button
                type="button"
                key={index} 
                className={`btn ${selectedDays[index] ? 'btn-primary' : 'btn-light'}`} // 수정된 조건부 클래스 추가
                onClick={() => toggleDay(index)}
                aria-pressed={selectedDays[index]} // 각 버튼의 토글 상태에 따라 aria-pressed 속성 설정
                aria-label={`${day} 선택`}
                ref={index === 0 ? weekdayRef : null} // 첫 번째 요일 버튼에 Ref 설정
            >
                <span className='p-button-label p-c'>{day}</span>
            </button>
        ));
    };

    
    // 타임라인 등록
    const addTimeline = () => {

        var result = checkTimelineData();

        if(result){
            
            const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

            // true인 요소의 인덱스를 찾아 해당하는 요일 이름을 가져옵니다.
            const selectedDayArr = selectedDays.reduce((acc, curr, index) => {
                if (curr) {
                    acc.push(daysOfWeek[index]);
                }
                return acc;
            }, []);

            // 가져온 요일 이름들을 하나의 문자열로 조합합니다.
            const week = selectedDayArr.join(",");
            const weekStr = selectedDayArr.join(", ");
            
            var startampm = starttime.ampm;
            var starthour = starttime.hour;
            var startminute = starttime.minute;

            if(startampm == "오후"){
                if(starthour != '12'){
                    starthour = Number(starthour) + 12;
                }
            }else{
                if(starthour == '12'){
                    starthour = Number(starthour) + 12;
                }
                starthour = starthour.toString().padStart(2, '0');
            }

            var starttimeStr = starthour + ":" + startminute;

            var endampm = endtime.ampm;
            var endhour = endtime.hour;
            var endminute = endtime.minute;

            if(endampm == "오후"){
                if(endhour != '12'){
                    endhour = Number(endhour) + 12;
                }
            }else{
                if(endhour == '12'){
                    endhour = Number(endhour) + 12;
                }
                endhour = endhour.toString().padStart(2, '0');
            }

            var endtimeStr = endhour + ":" + endminute;

            var timeline = {
                week: week,
                weekStr: weekStr,
                starttime: starttimeStr,
                endtime: endtimeStr
            };

            // AddTimelineconfirm 함수 호출 시 timeline 객체 전달
            AddTimelineconfirm(timeline);

        }
    };

    // 일정 선택 클릭시 나오는 안내 alert
    const AddTimelineconfirm = (timeline) => {
        // timeline 객체의 속성이 정의되어 있는지 확인
        if (timeline && timeline.weekStr && timeline.starttime && timeline.endtime) {

            const { weekStr, starttime, endtime } = timeline;

            var result = window.confirm(`선택하신 일정은 ${weekStr} ${starttime} ~ ${endtime} 입니다. 선택하시겠습니까?`);

            if(result){
                setTimeout(() => {
                    confirmTimeline(timeline);
                }, 100);
            }else{
                // 요일 선택 칸으로 포커스 이동
                setTimeout(() => {
                    if (weekdayRef.current) {
                        //console.log('요일 선택 칸으로 이동해랏');
                        weekdayRef.current.focus();
                    }
                }, 200); 
            }
            
        } else {
            console.error('Invalid timeline object:', timeline);
        }

    };

    // 일정 추가 안내 alert
    const confirmTimeline = (timeline) => {
    
        var result = window.confirm('수업 요일 및 시간을 추가로 선택하시겠습니까?');
        if(result){
            updateTimeline(timeline)

                // 요일 선택 칸으로 포커스 이동
                setTimeout(() => {
                    if (weekdayRef.current) {
                        //console.log('요일 선택 칸으로 이동해랏');
                        weekdayRef.current.focus();
                    }
                }, 200);
        }else{
            updateTimeline(timeline)

            // 시간 간격 라디오 칸으로 포커스 이동
            setTimeout(() => {
                if (radioRef.current) {
                    radioRef.current.focus();
                }
                
            }, 200);
        }
    };

    // 타임라인 행 추가 및 input/selectbox 초기화
    const updateTimeline = (timeline) => {
        var newSelecttimes = formData.selecttimes;
        newSelecttimes.push(timeline);

        setFormData({
            ...formData,
            newSelecttimes
        });

        resetTimelineData();
    };

    // 타임라인 데이터 초기화
    const resetTimelineData = () => {
        setSelectedDays([false, false, false, false, false, false, false]);
        setStarttime({
            ...starttime,
            ampm: '',
            hour: '',
            minute: '',
        });
        setEndtime({
            ...endtime,
            ampm: '',
            hour: '',
            minute: '',
        }); 
        
        setResetSelectedOption(true); // 상태를 true로 설정하여 드롭다운 초기화
        // 다시 false로 설정하여 초기화 상태를 재설정
        setTimeout(() => {
            setResetSelectedOption(false);
        }, 0);
    };


    // 타임라인 추가 시 유효성 검사
    const checkTimelineData = () => {
        const hasTrue = selectedDays.some(item => item === true);
       
        if(!hasTrue){
            alert("요일을 선택해주세요.");
            return false;
        }
        if (starttime.ampm == "") {
            alert("시작시간 오전/오후를 선택해주세요.");
            return false;
        }
        if(starttime.hour == ""){
            alert("시작시간 시간를 선택해주세요.");
            return false;
        }
        if(starttime.minute == ""){
            alert("시작시간 분을 선택해주세요.");
            return false;
        }
        if(endtime.ampm == ""){
            alert("종료시간 오전/오후를 선택해주세요.");
            return false;
        }
        if(endtime.hour == ""){
            alert("종료시간 시간을 선택해주세요.");
            return false;
        }
        if(endtime.minute == ""){
            alert("종료시간 분을 선택해주세요.");
            return false;
        }

        return true;
    };

    // 타임라인 삭제
    const deleteTimeline = (index) => {
        var newSelecttimes = formData.selecttimes;
        newSelecttimes.splice(index, 1);

        setFormData({
            ...formData,
            newSelecttimes
        });
    };

    // 테이블
    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [sort, setSortData] = useState({ column: '', ascending: ''});
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);

    // 수업 예약 리스트 가져오기
    const getTutoringList = async () => {

        var tutoringid = id.id;

        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }

        try {
            const response = await axios.get(apiUrl+`/tutoring/schedule?tutoringid=${tutoringid}&page=${activePage.current}&rows=${row}&sort=${sortStr}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour == 12){
                            startampm = "오후";
                        }else if(starthour > 12 && starthour < 24){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }else if(starthour == 0 || starthour == 24){
                            startampm = "오전";
                            starthour = 12;
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                        
                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour == 12){
                            endampm = "오후";
                        }else if(endhour > 12 && endhour < 24){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }else if(endhour == 0 || endhour == 24){
                            endampm = "오전";
                            endhour = 12;
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];

                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getTutoringList();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
            getTutoringList();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            activePage.current += 1; // 1페이지씩 이동
            getTutoringList();
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getTutoringList();
        }
    }, [sort]);
    
    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getTutoringList();
        }else{
            activePage.current = 1;
            getTutoringList();
        }
    }, [row]);

    //수강생 툴팁
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
            setTooltipOpen(!tooltipOpen);
    };
    
    const renderTooltip = (props) => ( // 그외 나머지 수강생 리스트
        <Tooltip id="tooltip" {...props} className="custom-tooltip">
            <ul className='tooltip-list'> 
                <li>학생1</li>
                <li>학생2</li>
                <li>학생3</li>
            </ul>
        </Tooltip>
    );

    // 수업 등록 및 미리보기 리스트
    const previewClass = async () => {

        var result = checkClassData();

        if(result){

            var sdate = formatDate(formData.startdate);
            var edate = formatDate(formData.enddate);

            var params = {
                lectureid: formData.lectureid,
                affiliationid: formData.affiliations.value,
                tutorid: formData.tutorid.value,
                startdate: sdate,
                enddate: edate,
                duration: formData.duration,
                holidayclass: formData.holidayclass,
                selecttimes: formData.selecttimes
            };

            //console.log(params);

            try {
                const response = await axios.post(apiUrl+'/tutoring/insert', params, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    if(res.data.schedule_count == 0){
                        alert("중복된 일정이 있습니다.");
                        return false;
                    }else{
                        setId({
                            id: res.data.tutoringid
                        });
                    }
                    
                }
    
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 일정 개별 삭제
    const deleteSchedule = async (scheduleid) => {
        var result = window.confirm('일정을 삭제 하시겠습니까?');

        if(result){
            var tutoringid = id.id;
            try {
                const response = await axios.delete(apiUrl+`/tutoring/deleteSchedule?scheduleid=${scheduleid}&tutoringid=${tutoringid}`, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("삭제 되었습니다.");
                    getTutoringList();
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 수업 등록 유효성 검사
    const checkClassData = () => {

        if(formData.lectureid == null || formData.lectureid == undefined){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.affiliations == null || formData.affiliations == undefined){
            alert("강사 소속을 선택해주세요.");
            return false;
        }
        if(formData.tutorid == null || formData.tutorid == undefined){
            alert("강사를 선택해주세요.");
            return false;
        }

        var sdate = formData.startdate;
        sdate = formatDate(sdate);
        var startdateValid = isValidDate(sdate);
        if(!startdateValid){
            alert("시작일을 올바르게 선택해주세요.");
            return false;
        }
        /*
        var today = new Date();
        sdate = new Date(sdate);
        if(sdate < today){
            alert("시작일을 오늘 이후 날짜로 선택해주세요.");
            return false;
        }
        */
        var edate = formData.enddate;
        edate = formatDate(edate);
        var enddateValid = isValidDate(edate);
        if(!enddateValid){
            alert("종료일을 올바르게 선택해주세요.");
            return false;
        }
        edate = new Date(edate);
        if(edate < sdate){
            alert("종료일을 시작일 이후 날짜로 선택해주세요.");
            return false;
        }
        if(formData.selecttimes.length == 0){
            alert("시간을 추가해주세요.")
            return false;
        }

        return true;
    };

    // 올바른 날짜인지 확인하기
    function isValidDate(dateString) {
        // yyyy-mm-dd 형식의 문자열을 날짜 객체로 변환
        var dateParts = dateString.split("-");
        var year = parseInt(dateParts[0], 10);
        var month = parseInt(dateParts[1], 10) - 1; // 월은 0부터 시작하므로 1을 빼줌
        var day = parseInt(dateParts[2], 10);
        
        // Date 객체 생성
        var date = new Date(year, month, day);
        
        // 날짜 유효성 확인
        return date.getFullYear() == year && date.getMonth() == month && date.getDate() == day;
    };

    // 날짜 포맷 변경
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
        const day = String(d.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };

    // 수업 등록 취소하기
    const cancelClass = async () => {
        var tutoringid = id.id;
        
        if(tutoringid != 0 && tutoringid != "0"){
            try {
                const response = await axios.delete(apiUrl+`/tutoring/deleteSchedule?scheduleid=&tutoringid=${tutoringid}`, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("취소 처리 되었습니다.");
                    goBack();
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }else{
            goBack();
        }
    };

    // 전체 삭제 클릭시
    const deleteAll = async () => {
        var result = window.confirm('일정 전체를 삭제 하시겠습니까?');

        if(result){
            try {
                const response = await axios.delete(apiUrl+`/tutoring/deleteSchedule?scheduleid=&tutoringid=${id.id}`, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("삭제가 완료되었습니다.");
                    if(mode == "insert"){
                        window.location.reload();
                    }else{
                        goBack();
                    }
                }
    
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 최종등록 클릭시 
    const finalRegi = async () => {

        var result = window.confirm('최종 등록 전 일정을 다시 한번 꼼꼼히 확인해주세요! 수업이 예약된 이후 일정 취소 및 변경 불가합니다. 등록하시겠습니까?');

        if(result){
            try {
                const response = await axios.put(apiUrl+`/tutoring/confirm/${id.id}`, {}, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("최종 등록 완료되었습니다.");
                    goBack();
                }
    
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 뒤로가기
    const goBack = () => {
        navigate('/InstructorLecture');
    }
    
    return (
        <>
            <PageTitle motherMenu={"수업관리"} grandMenu={"강사 강의 관리"} grandMenuLink="/InstructorLecture"  activeMenu={menuName.pageContent} />

            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="lectureType">강의유형</label>
                                            {lecturetypeData.map((item, index) =>(
                                                <div key={index} className="form-check form-check-inline">
                                                    <label className="form-check-label"
                                                        htmlFor={`lectureid-${index}`}>
                                                        <input className="form-check-input" type="radio"
                                                            name="lectureid" id={`lectureid-${index}`}
                                                            value={item.lectureid}
                                                            checked={formData.lectureid===item.lectureid}
                                                            onChange={handleChange} />
                                                        {item.lecturetype}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">강사소속</label>
                                            <Select
                                                isSearchable = {true}
                                                options={affiliationsData}
                                                value={formData.affiliations}
                                                onChange={handleAffiliationChange}
                                                name='affiliations'
                                                placeholder="강사 소속 선택"
                                                className='z4'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className='form-label'>강사선택</label>
                                            <Select
                                                isSearchable = {true}
                                                options={tutorData}
                                                value={formData.tutorid}
                                                onChange={handleTutorChange}
                                                name='tutorid'
                                                placeholder="강사 선택 및 검색"
                                                className='z4'
                                            />
                                        </div>
                                    </div> 
                                </div>
                                
                                    {/* 
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className='form-label'>수강생 선택</label>
                                            <Select
                                                className='z4'
                                                isSearchable={true}
                                                placeholder="수강생 선택 및 검색"
                                                closeMenuOnSelect={false}
                                                name='tuteeid'
                                                value={formData.tuteeid}
                                                onChange={handleTuteeChange}
                                                components={{ ClearIndicator }}
                                                styles={{ clearIndicator: ClearIndicatorStyles }}
                                                isMulti
                                                options={tuteeData}/>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className='form-label'>교재선택</label>
                                            <Select
                                                className='z4'
                                                placeholder="교재 선택 및 검색"
                                                value={formData.bookid}
                                                name='bookid'
                                                onChange={handleBookChange}
                                                options={bookData}
                                                isSearchable = {true}
                                            />
                                        </div>
                                    </div> 
                                    */}
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="startdate">시작일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="수강시작일 선택"
                                                    className="picker-suit"
                                                    value={formData.startdate} 
                                                    onChange={handleStartDateChange}
                                                    name="startdate"
                                                    aria-label="수강시작일 선택"
                                                />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="enddate">종료일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="수강종료일 선택"
                                                    className="picker-suit"
                                                    value={formData.enddate} 
                                                    onChange={handleEndDateChange}
                                                    name="enddate"
                                                    aria-label="수강종료일 선택"
                                                />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="week">요일</label>
                                            <div className="weekday-buttons" role="group" aria-hidden="false" aria-labelledby="weekday">
                                                {renderButtons()}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div>시작시간</div>
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="time">시간 선택</label>
                                            <select name="starttime_ampm" id="starttime_ampm" className="customSel"
                                                onChange={handleTimeChange} aria-label="오전오후 선택" value={starttime.ampm}>
                                                <option value="" disabled>오전/오후</option>
                                                {ampmlist.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="hour">시 선택</label>
                                            <select name="starttime_hour" id="starttime_hour" className="customSel"
                                                onChange={handleTimeChange} aria-label="시 선택" value={starttime.hour}>
                                                <option value="" disabled>시 선택</option>
                                                {hourlist.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="minute">분 선택</label>
                                            <select name="starttime_minute" id="starttime_minute" className="customSel"
                                                onChange={handleTimeChange} aria-label="분 선택" value={starttime.minute}>
                                                <option value="" disabled>분 선택</option>
                                                {minutelist.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div>종료시간</div>
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="time">시간 선택</label>
                                            <select name="endtime_ampm" id="endtime_ampm" className="customSel"
                                                onChange={handleTimeChange} aria-label="오전오후 선택" value={endtime.ampm}>
                                                <option value="" disabled>오전/오후</option>
                                                {ampmlist.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="hour">시 선택</label>
                                            <select name="endtime_hour" id="endtime_hour" className="customSel"
                                                onChange={handleTimeChange} aria-label="시 선택" value={endtime.hour}>
                                                <option value="" disabled>시 선택</option>
                                                {hourlist.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="minute">분 선택</label>
                                            <select name="endtime_minute" id="endtime_minute" className="customSel"
                                                onChange={handleTimeChange} aria-label="분 선택" value={endtime.minute}>
                                                <option value="" disabled>분 선택</option>
                                                {minutelist.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label d-block labelhidden" htmlFor="">&nbsp;</label>
                                            <button type="button" onClick={addTimeline} className="me-2 btn btn-primary btn-rounded">등록</button>
                                            <button type="button" className="me-2 btn btn-light btn-rounded">취소</button>
                                        </div>
                                    </div>
                                    {/* 등록한 내용 */}
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group selectedBox">
                                            {formData.selecttimes.map((item, index) => (
                                                <p key={index}>
                                                    {item.week} {item.starttime} ~ {item.endtime} 
                                                    <button type='button' onClick={() => deleteTimeline(index)} className='xicon' aria-label={`${item.weekStr} ${item.starttime} ~ ${item.endtime} 삭제하기 버튼`}>X</button>
                                                </p>    
                                            ))}
                                        </div>
                                    </div>
                                    {/* 시간 추가 end */}

                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="timeinterval">시간 간격</label>
                                            <div className='d-flex gap-2'>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            ref={radioRef}
                                                            onChange={handleChange}
                                                            checked={formData.duration === '30'}
                                                            aria-checked={formData.duration === '30'}
                                                            aria-labelledby="30분-label"
                                                            className="form-check-input" 
                                                            type="radio"
                                                            name="duration"
                                                            value="30"
                                                            id='30min' />
                                                        <label className="form-check-label"
                                                            htmlFor='30min'>30분</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            onChange={handleChange}
                                                            checked={formData.duration === '60'}
                                                            aria-checked={formData.duration === '60'}
                                                            aria-labelledby="60분-label"
                                                            className="form-check-input" 
                                                            type="radio"
                                                            name="duration"
                                                            value="60"
                                                            id='60min' />
                                                        <label className="form-check-label"
                                                            htmlFor='60min'>60분</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="holiday">공휴일</label>
                                            <div className='d-flex gap-2'>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            onChange={handleChange}
                                                            checked={formData.holidayclass === 'open'}
                                                            aria-checked={formData.holidayclass === 'open'}
                                                            aria-labelledby="수업함-label"
                                                            className="form-check-input" 
                                                            type="radio"
                                                            name="holidayclass"
                                                            value="open"
                                                            id='classOn' />
                                                        <label className="form-check-label"
                                                            htmlFor='classOn'>수업 함</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input 
                                                            onChange={handleChange}
                                                            checked={formData.holidayclass === 'close'}
                                                            aria-checked={formData.holidayclass === 'close'}
                                                            aria-labelledby="수업안함-label"
                                                            className="form-check-input" 
                                                            type="radio"
                                                            name="holidayclass"
                                                            value="close"
                                                            id='classOff' />
                                                        <label className="form-check-label"
                                                            htmlFor='classOff'>수업 안함</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary" onClick={previewClass}>등록</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Row>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-end flex-wrap gap-2">
                            <>
                                <button type='button' className="me-2 btn btn-primary btn-square"onClick={deleteAll}>전체 삭제</button>
                                <button type='button' className="me-2 btn btn-success btn-square" onClick={finalRegi}>최종 등록</button>
                                <button type='button' className="me-2 btn btn-light btn-square" onClick={cancelClass}>취소</button>
                            </>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('30')}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            <tr>
                                                {theadData.map((item, ind)=>(
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td><strong>{data.num}</strong></td>
                                                    <td>{data.lecturetype}</td>
                                                    <td>{data.affiliations_name}</td>
                                                    <td>{data.tutorname}</td>
                                                    <td>{data.datefulltxt}</td>
                                                    <td>{data.weektxt}</td>
                                                    <td>{data.starttxtStr}</td>
                                                    <td>{data.endtxtStr}</td>
                                                    <td>{data.durationtxt}</td>
                                                    <td className='btns'>
                                                        <Button onClick={() => deleteSchedule(data.scheduleid)} type="button" className="btn btn-danger me-1 light">삭제</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div
                                        className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'>
                                        <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    );
};

export default InstructorLectureRegi;