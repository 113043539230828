import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CkEditorBlog from '../../elements/CkEditor/CkEditorBlog';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

const ContentsRegi = () => {

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '콘텐츠 수정' : '콘텐츠 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        disable: '',
        intro: '',
        thumbnail: '',
        fileurl: '',
    });

    const [menuName, setMenuName] = useState({
        activeMenu: ' 콘텐츠 등록',
        pageContent: '콘텐츠 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    // 수정 모드인 경우 데이터를 가져와서 폼에 채움
    useEffect(() => {
        if (id != 0) {
            // 여기서는 데이터를 가져오는 비동기 작업을 수행하고 formData를 채워야 함
            setMode('update');
            setMenuName(prevState => ({
                ...prevState,
                activeMenu: '콘텐츠 수정',
                pageContent: '콘텐츠 수정'
            }))
            getContent();
        }
    }, [id]);

    // 값 변경 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    // 내용 변경 시
    const handleIntroChange = (event, editor) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            intro: editor.getData()
        }));
    };

    // 저장하기
    const handleSubmit = async (e) => {
        //console.log(id)
        if(formData.title === ""){
            alert("제목을 입력해주세요.");
            return false;
        }
        if(formData.description === ""){
            alert("설명을 입력해주세요.");
            return false;
        }
        if(formData.disable === ""){
            alert("노출여부를 선택해주세요.");
            return false;
        }
        if(formData.intro === ""){
            alert("내용을 입력해주세요.");
            return false;
        }
        if(formData.thumbnail === ""){
            alert("썸네일 이미지를 등록해주세요.");
            return false;
        }

        if(mode === "insert"){
            insertContent();
        }else{
            updateContent();
        }
        
    };

    // 서비스 콘텐츠 정보 가져오기
    const getContent = async () => {
        
        try {
            const response = await axios.get(apiUrl+'/service/'+id);
            var res = response.data;
            //console.log(res);
            var resData = res.data;
            setFormData({
                ...formData,
                title: resData.title,
                description: resData.description,
                disable: resData.disable,
                intro: resData.intro,
                thumbnail: resData.thumbnail,
                fileurl: resData.fileurl
            });

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 서비스 콘텐츠 등록
    const insertContent = async () => {
        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        try {
            const response = await axios.post(apiUrl+'/service/insert', params);
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("서비스 콘텐츠 등록하였습니다.");
                navigate('/Contents');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 서비스 콘텐츠 수정
    const updateContent = async () => {
        var params = {...formData};
        params.serviceid = id;
        //console.log(params)

        try {
            const response = await axios.put(apiUrl+'/service/update', params);
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("서비스 콘텐츠 수정하였습니다.");
                navigate('/Contents');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 서비스 콘텐츠 삭제
    const deleteContent = async () => {
        var result = window.confirm("서비스 콘텐츠를 삭제하시겠습니까?");
        if(result){
            var serviceid = id;
            try {
                const response = await axios.delete(apiUrl+'/service/delete/' + serviceid);
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("서비스 콘텐츠 삭제하였습니다.");
                    navigate('/Contents');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 파일 업로드
    const uploadImage = async (e) => {
        //console.log(e);
        //console.log(e.target.files[0]);
        var fileInfo = e.target.files[0];
        fileInfo.filename = fileInfo.name;

        var fileData = new FormData();
        fileData.append('attachment', fileInfo);
        fileData.append('folder', 'service');

        //console.log(fileData);
        
        try {
            const response = await axios.post(apiUrl+'/file/single', fileData, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setFormData({
                    ...formData,
                    thumbnail: res.data.key,
                    fileurl: '',
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 에디터 이미지 업로드
    const uploadEditorImage = async (e) => {
        var fileInfo = e.target.files[0];
        fileInfo.filename = fileInfo.name;

        var fileData = new FormData();
        fileData.append('attachment', fileInfo);
        fileData.append('folder', 'editor');
        
        try {
            const response = await axios.post(apiUrl+'/file/single', fileData, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var encodedKey = encodeURIComponent(res.data.key);
                var imgTag = `<img src='${apiUrl}/file/getRedirectUrl/${encodedKey}'/>`;
                setFormData(prevData => ({
                    ...prevData,
                    intro: formData.intro + imgTag
                }));
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const goBack = () => {
        navigate('/Contents');
    }

    return (
        <>
            <PageTitle motherMenu={"홈페이지관리"} grandMenu={"서비스 콘텐츠 관리"} grandMenuLink="/Contents" activeMenu={menuName.activeMenu} />
            
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                     <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="title">제목</label>
                                            <input id="title" placeholder="제목 입력" type="text" 
                                                name="title"
                                                value={formData.title} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="description">설명</label>
                                            <input id="description" placeholder="소내용 입력" type="text" 
                                                name="description"
                                                value={formData.description} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="disable">노출여부</label>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="disable"
                                                    id='disable' value='show' 
                                                    checked={formData.disable==='show'}
                                                    onChange={handleChange} />
                                                <label className="form-check-label" htmlFor='disable'>노출</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="disable"
                                                    id='hide' value='hide' 
                                                    checked={formData.disable==='hide'}
                                                    onChange={handleChange} />
                                                <label className="form-check-label" htmlFor='hide'>비노출</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-6 col-sm-12">
                                        <div className="form-group fallback ">
                                            <label className="form-label" htmlFor="file">파일등록</label>
                                            <input type="file" id='file' 
                                                className="form-control" 
                                                data-default-file="" 
                                                onChange={uploadImage}
                                                required 
                                            />
                                            {formData.fileurl && (
                                                <img src={formData.fileurl} alt={formData.thumbnail} className="imagePreview" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 ">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="ekeditor">내용</label>
                                             <div className="custom-ekeditor">
                                                 <CkEditorBlog 
                                                    data={formData.intro} 
                                                    onChange={handleIntroChange}
                                                />
                                             </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-6 col-sm-12">
                                        <div className="form-group fallback ">
                                            <label className="form-label" htmlFor="file">에디터 이미지 업로드</label>
                                            <input type="file" id='file' 
                                                className="form-control" 
                                                data-default-file="" 
                                                onChange={uploadEditorImage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center">
                <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                <button type="submit" className="btn btn-primary me-1" onClick={handleSubmit}>등록</button>
                {mode != 'insert' && (
                    <button onClick={deleteContent} className="btn btn-danger light me-1" variant="danger">
                        삭제
                    </button>
                )}
            </div>
        </>
    );
};

export default ContentsRegi;