import React, { useReducer, useContext, useEffect, useState } from "react";
import { Collapse } from 'react-bootstrap';
/// Link
import { Link, useLocation } from "react-router-dom";
import { MenuList } from './Menu';

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
// import LogoutPage from './Logout';
/// Image
// import profile from "../../../assets/images/profile/pic1.jpg";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "대시보드",
  activeSubmenu: "",
}

const SideBar = () => {
  let dat = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);

  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
        MenuList.forEach((data) => {
            const isActive = path === data.to || (data.content && data.content.some(item => item.to === path));
            if (isActive) {
                setState({ active: data.title });
                return;
            }
        });
    }, [path]);

  const isActiveMenuItem = (menuItem) => {
      return menuItem.to === path || (menuItem.content && menuItem.content.some(submenu => {
          // 정적 링크와 일치하는 경우
          if (submenu.to === path) return true;
          
          // 동적 매개변수를 사용하는 경우
          if (submenu.to.includes("/:id")) {
              const staticPart = submenu.to.split("/:id")[0];
              const dynamicPart = path.split(staticPart)[1];
              return !!dynamicPart; // 동적 매개변수가 존재하는 경우 true 반환
          }

          return false;
      }));
  };
  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
        }`}
    >
      <div className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
           {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={`nav-label ${menuClass} ${data.extraclass}`} key={index} >{data.title}</li>
              )
            } else {
              return (
                <li className={isActiveMenuItem(data) ? 'mm-active' : ''} key={index}>
                                    <Link to={data.to} aria-current={isActiveMenuItem(data) ? 'page' : null}>
                                        {data.iconStyle}
                                        <span className="nav-text">{data.title}</span>
                                    </Link>
                                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
