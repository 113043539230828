import React, {useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Row, Col } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var affiliations = [];
var dataList = [];

const theadData = [
    {heading: 'No.', sortingValue:"seq"},
    {heading: '이름', sortingValue:"name"},
    {heading: '소속', sortingValue:"affiliations"},
    {heading: '장애유형', sortingValue:"disabilitytype"},
    {heading: '연락처', sortingValue:"tel"},
    {heading: '이메일', sortingValue:"email"},
    {heading: '강의유형', sortingValue:"lectureid"},
    {heading: '상태', sortingValue:""},
    {heading: '관리', sortingValue:""},
];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const AllTeacher = () => {

    useTitle("강사관리");

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);
    const [open, setOpen] = useState(true); // filter 영역 on/off

    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [affiliationsData, setAffiliations] = useState([...affiliations])
    const [formData, setFormData] = useState({
        lectureid: 0,
        status: '',
        affiliations: '',
        searchtext: ''
    });

    // 강의유형 ASC 정렬
    const changeLectureType = () => {
        const sortedData = [...lecturetypes].sort((a, b) => {
            if (a.lectureid < b.lectureid) return -1;
            if (a.lectureid > b.lectureid) return 1;
            return 0;
        });
        setLecturetypeData(sortedData);
    }

    // 소속 ASC 정렬
    const changeAffiliation = () => {
        const sortedData = [...affiliations].sort((a, b) => {
            if (a.seq < b.seq) return -1;
            if (a.seq > b.seq) return 1;
            return 0;
        });
        setAffiliations(sortedData);
    }

    // 강사 리스트 가져오기
    const getTutors = async () => {
        var lectureid = formData.lectureid;
        var status = formData.status;
        var affiliations = formData.affiliations;
        var searchtext = formData.searchtext;
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }
        
        try {
            const response = await axios.get(apiUrl+'/tutor?searchtext='+searchtext+'&lectureid='+lectureid+'&status='+status+'&affiliations='+affiliations+'&page='+activePage.current+'&rows='+row+'&sort='+sortStr, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 엑셀 다운로드
    const downloadExcel = async () => {
        var lectureid = formData.lectureid;
        var status = formData.status;
        var affiliations = formData.affiliations;
        var searchtext = formData.searchtext;
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }
        
        try {
            const response = await axios.get(apiUrl+'/tutor/excel?searchtext='+searchtext+'&lectureid='+lectureid+'&status='+status+'&affiliations='+affiliations+'&sort='+sortStr, {
                headers: {
                    token: accessToken
                },
                responseType: 'blob', // 응답 타입을 Blob으로 설정
            });
            var res = response.data;
            //console.log(res);
            
            // Blob 데이터를 그대로 저장
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, '강사.xlsx');

        } catch (error) {
           console.error('Error:', error);
        }
    }

    useEffect(() => {

        // 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype');
                //console.log(response.data.data);
                var resData = response.data.data;
                lecturetypes = resData;
                changeLectureType();
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        // 소속 리스트 가져오기
        const getAffiliations = async () => {
            try {
                const response = await axios.get(apiUrl+'/affiliation');
                //console.log(response.data.data);
                var resData = response.data.data;
                affiliations = [];
                if(resData){
                    affiliations.push({label: "전체", value: "", seq: 0});
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.name;
                        info.value = info.affiliationid;
                        affiliations.push(info);
                    }
                }

                changeAffiliation();
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

        getLectureTypes();

        getAffiliations();

        //getTutors();
        
    }, [])

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getTutors();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'lectureid') {  
            setFormData({
               ...formData,
               [name]: Number(value)
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // 소속 검색 변경 시
    const handleAffiliationChange = (e) => {
        //console.log(e);
        const value = e.value;

        setFormData({
            ...formData,
            affiliations: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
        }
    };

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getTutors();
        }else{
            activePage.current = 1;
            getTutors();
        }
    }, [row]);
    

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getTutors();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
            getTutors();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            activePage.current += 1; // 1페이지씩 이동
            getTutors();
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getTutors();
        }
    }, [sort]);

    // 전체 선택, 개별선택
    const [selectAll, setSelectAll] = useState(false);
    const [isChecked, setIsChecked] = useState(new Array(dataList.length).fill(false));
    const [checkedArr, setCheckedArr] = useState([]);

    // 전체 선택 체크박스를 클릭
    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        setIsChecked(new Array(tableData.length).fill(checked));
    };

    // 개별 선택 체크박스를 클릭
    const handleCheckboxChange = (index) => {
        const newIsChecked = [...isChecked];
        newIsChecked[index] = !newIsChecked[index];
        setIsChecked(newIsChecked);
        
        var checkedIndex = [];
        for(var i = 0; i < newIsChecked.length; i++){
            if(newIsChecked[i]){
                checkedIndex.push(i);
            }
        }
        setCheckedArr(checkedIndex);
    };

    // 강사 삭제
    const deleteRows = async () => {
        var selectedids = "";
        if(selectAll){
            for(var i = 0; i < tableData.length; i++){
                if(i == 0){
                    selectedids = tableData[i].tutorid;
                }else{
                    selectedids += "," + tableData[i].tutorid;
                }
            }
        }else{
            if(checkedArr.length == 0){
                alert("삭제할 항목을 선택해주세요.");
                return false;
            }else{
                for(var i = 0; i < checkedArr.length; i++){
                    if(i == 0){
                        selectedids = tableData[checkedArr[i]].tutorid;
                    }else{
                        selectedids += "," + tableData[checkedArr[i]].tutorid;
                    }
                }
            }
        }

        var result = window.confirm("선택된 항목을 삭제하시겠습니까?");
        if(result){
            
            try {
                const response = await axios.delete(apiUrl+'/tutor/delete/' + selectedids, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("삭제하였습니다.");
                    activePage.current = 1;
                    getTutors();
                    setSelectAll(false);
                    setIsChecked(new Array(tableData.length).fill(false))
                    setCheckedArr([]);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    return (
        <>
            <PageTitle motherMenu={"회원관리"} activeMenu={"강사관리"}  />
            <Row>
                <Col lg={12}>
                    <div className="filter cm-content-box box-primary">
                        <div className="content-title"
                            onClick={() => setOpen(!open)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    setOpen(!open);
                                }
                            }}
                            role="button"
                            tabIndex="0"
                        >
                            <div className="cpa">
                                <i className="fas fa-filter me-2"></i>Filter
                            </div>
                            <div className="tools">
                                <Link to={"#"} className={`SlideToolHeader ${open ? 'collapse' : 'expand' }`}                                   
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        
                        <Collapse in={open}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row filter-row">
                                        <div className="col-xl-12 col-sm-12">
                                            <div className='form-group'>
                                                <label className='form-label d-block'>강의유형</label>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor='lectureid-all'>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="lectureid"
                                                            id='lectureid-all'
                                                            value='0'
                                                            checked={formData.lectureid === 0}
                                                            onChange={handleChange}
                                                            />
                                                        전체</label>
                                                </div>
                                                {lecturetypeData.map((item, index) =>(
                                                    <div key={index} className="form-check form-check-inline">
                                                        <label className="form-check-label" htmlFor={`lectureid-${index}`}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="lectureid"
                                                            id={`lectureid-${index}`}
                                                            value={item.lectureid}
                                                            checked={formData.lectureid === item.lectureid}
                                                            onChange={handleChange}
                                                            />
                                                        {item.lecturetype}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-sm-12">
                                            <div className='form-group'>
                                                <label className='form-label d-block'>강사상태</label>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="status"
                                                        id='all'
                                                        value=''
                                                        checked={formData.status === ''}
                                                        onChange={handleChange}
                                                        />
                                                    <label className="form-check-label" htmlFor='all'>전체</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="status"
                                                        id='normal'
                                                        value='normal'
                                                        checked={formData.status === 'normal'}
                                                        onChange={handleChange}
                                                        />
                                                    <label className="form-check-label" htmlFor='normal'>정상</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="status"
                                                        id='secession'
                                                        value='secession'
                                                        checked={formData.status === 'secession'}
                                                        onChange={handleChange}
                                                        />
                                                    <label className="form-check-label" htmlFor='secession'>탈퇴</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="form-group">
                                                <label className='form-label'>강사소속</label>
                                                <Select
                                                    isSearchable = {false}
                                                    options={affiliationsData}
                                                    className="custom-react-select"
                                                    placeholder="선택"
                                                    name="affiliations"
                                                    onChange={handleAffiliationChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="searchtext" className='form-label'>검색어</label>
                                                <input
                                                    type="text"
                                                    name="searchtext"
                                                    value={formData.searchtext}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeyPress}
                                                    className="form-control"
                                                    placeholder="검색어를 입력해주세요"
                                                    />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6 align-self-end">
                                            <div className="form-group"><button className="btn btn-primary rounded-sm me-1" title="검색어를 입력해주세요" type="submit" onClick={submitSearch}><i className="fa fa-search me-1" />검색</button></div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </Collapse>   
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between flex-wrap gap-2">
                  
                            <button onClick={downloadExcel} className="btn btn-primary btn-sm">EXCEL 다운로드</button>
              
                            <div className=' d-flex justify-content-end gap-2'>
                                <Link to={"/TeacherRegi/0"} className="btn btn-primary btn-sm">+ 등록</Link>
                                <Link to={"/BulkTeacherRegi"} className="btn btn-primary btn-sm">+ 일괄 등록</Link>
                                <button type='button' onClick={deleteRows} className="btn btn-danger btn-sm">삭제</button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('5')}>5</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('30')}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            <tr>
                                                 <th>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="checkAll"
                                                        checked={selectAll} 
                                                        onChange={handleSelectAll} 
                                                        aria-label="모두 선택"
                                                    />
                                                </th>
                                                {theadData.map((item, ind)=>(
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={isChecked[ind]}
                                                            onChange={() => handleCheckboxChange(ind)} 
                                                            aria-label={`선택: ${data.name}`}
                                                        />
                                                    </td>
                                                    <td><strong>{data.num}</strong></td>
                                                    <td>{data.name}</td>
                                                    <td>{data.affiliations_name}</td>
                                                    <td>{data.disabilitytype}</td>
                                                    <td>{data.tel}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.lecturetype}</td>
                                                    <td>
                                                        {data.status === "normal" ? (
                                                            <span className="badge badge-primary light">
                                                                정상
                                                            </span>
                                                        ) :
                                                            data.status === "secession" ? (
                                                            <span className="badge badge-danger light">
                                                                탈퇴
                                                            </span>
                                                        ) :  null}
                                                    </td>
                                                    <td className='btns'>
                                                        <Link to={"/TeacherRegi/"+data.tutorid}
                                                            className="btn btn-primary me-1 light">수정</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'>
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동">
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    );
};

export default AllTeacher;