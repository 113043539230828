import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];


const StudentRegi = () => {
    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '수강생 수정' : '수강생 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트


    const accessToken = useSelector(state => state.auth.auth.accessToken);

    var getTuteeCnt = 0;

    const navigate = useNavigate();
    

    const [formData, setFormData] = useState({
        tuteeid: '',
        name: '',
        loginid: '',
        password: '',
        email: '',
        lectureid: '',
        status: '',
        affiliationid: [],
        tutorid: '',
        roundno: '',
        status: '',
        note: '',
        file: '',
        originfile: '',
        fileurl: '',
    });
    
    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [affiliationsData, setAffiliationsData] = useState([{label: "선택", value: ""}])
    const [tutorData, setTutorData] = useState([{label: "선택", value: ""}])

    const [menuName, setMenuName] = useState({
        activeMenu: '수강생등록하기',
        pageContent: '수강생 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    useEffect(() => {

        // 수정 모드인 경우 데이터를 가져와서 폼에 채움
        const getUpdateData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse, tutorResponse, tuteeResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                    axios.get(apiUrl + '/tutor', {
                        headers: {
                            token: accessToken
                        }
                    }),
                    axios.get(apiUrl + '/tutee/info/' + id, {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

                const tutors = tutorResponse.data.data.map(tutor => ({
                    label: tutor.name,
                    value: tutor.tutorid
                }));
                setTutorData(tutors);

                const tuteeData = tuteeResponse.data.data;
                //console.log(tuteeData);

                if(!tuteeData.note){
                    tuteeData.note = "";
                }
                if(!tuteeData.roundno){
                    tuteeData.roundno = "";
                }
                if(!tuteeData.tutorid){
                    tuteeData.tutorid = "";
                }
                if(!tuteeData.file){
                    tuteeData.file = "";
                }

                var selectedAff = {};
                for(var i = 0; i < affiliations.length; i++){
                    if(tuteeData.affiliationid == affiliations[i].value){
                        selectedAff = {
                            label: affiliations[i].label,
                            value: affiliations[i].value
                        };
                    }
                }

                var selectedTutor = {};
                for(var i = 0; i < tutors.length; i++){
                    
                    if(tuteeData.tutorid == tutors[i].value){
                        selectedTutor = {
                            label: tutors[i].label,
                            value: tutors[i].value
                        };
                    }
                }
                
                setFormData(prevFormData => ({
                    ...prevFormData,
                    name: tuteeData.name,
                    loginid: tuteeData.loginid,
                    email: tuteeData.email,
                    lectureid: tuteeData.lectureid,
                    status: tuteeData.status,
                    affiliationid: selectedAff,
                    tutorid: selectedTutor,
                    roundno: tuteeData.roundno,
                    status: tuteeData.status,
                    note: tuteeData.note,
                    file: tuteeData.file,
                    originfile: tuteeData.originfile,
                    fileurl: tuteeData.fileurl
                }));

            } catch (error) {
                console.error('Error:', error);
            }
        };

        const getInsertData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse, tutorResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                    axios.get(apiUrl + '/tutor'),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

                const tutors = tutorResponse.data.data.map(tutor => ({
                    label: tutor.name,
                    value: tutor.tutorid
                }));
                setTutorData(tutors);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id !== "0" && id !== 0) {
            if(getTuteeCnt == 0){
                getTuteeCnt++;
                setMode('update');
                setMenuName(prevState => ({
                    ...prevState,
                    activeMenu: '수강생수정하기',
                    pageContent: '수강생 수정'
                }))
                getUpdateData();
            }
        }else{
            getInsertData();
        }
        
    }, [id]);

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'roundno' || name === 'lectureid') {  
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: Number(value)
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    // 수강생 소속 변경 시
    const handleAffiliationChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            affiliationid: e
        }));
    };

    // 담당강사 변경 시
    const handleTutorChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            tutorid: e
        }));
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        
        if(mode === "insert"){
            insertTutee();
        }else{
            updateTutee();
        }
    };

    // 수강생 등록
    const insertTutee = async () => {
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        var affiliationid = params.affiliationid.value;
        params.affiliationid = affiliationid;

        if(!params.tutorid){
            var tutorid = params.tutorid.value;
            params.tutorid = tutorid;
        }else{
            params.tutorid = "";
        }

        if(formData.roundno == "" || !formData.roundno){
            params.roundno = 0;
        }
        
        try {
            const response = await axios.post(apiUrl+'/tutee/insert', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("수강생 등록하였습니다.");
                navigate('/AllStudent');
            }else{
                alert(res.msg);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 수강생 수정
    const updateTutee = async () => {
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var params = {...formData};
        params.tuteeid = id;

        var affiliationid = params.affiliationid.value;
        params.affiliationid = affiliationid;

        if(!params.tutorid){
            var tutorid = params.tutorid.value;
            params.tutorid = tutorid;
        }else{
            params.tutorid = "";
        }
        
        if(formData.roundno == "" || !formData.roundno){
            params.roundno = 0;
        }
        
        params.moddate = new Date();

        try {
            const response = await axios.put(apiUrl+'/tutee/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("수강생 수정하였습니다.");
                navigate('/AllStudent');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // form 유효성 검사
    const checkFormData = () => {
        if(formData.name == ""){
            alert("수강생명을 입력해주세요.");
            return false;
        }
        if(formData.loginid == ""){
            alert("아이디를 입력해주세요.");
            return false;
        }
        if(mode == "insert"){
            if(formData.password == ""){
                alert("비밀번호를 입력해주세요.");
                return false;
            }
        }
        if(formData.email == ""){
            alert("이메일을 입력해주세요.");
            return false;
        }
        if(formData.lectureid == "" || !formData.lectureid){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.affiliationid == "" || formData.affiliationid.length == 0){
            alert("회원소속을 선택해주세요.");
            return false;
        }
        if(formData.status == ""){
            alert("회원상태를 선택해주세요.");
            return false;
        }
        /*
        if(formData.tutorid == "" || !formData.tutorid){
            alert("담당강사를 선택해주세요.");
            return false;
        }
        if(formData.roundno == "" || !formData.roundno){
            alert("수강회차를 입력해주세요.");
            return false;
        }
        if(formData.file == ""){
            alert("프로필을 등록해주세요.");
            return false;
        }
        */

        return true;
    }

    // 파일 업로드
    const uploadImage = async (e) => {
        //console.log(e);
        //console.log(e.target.files[0]);
        var fileInfo = e.target.files[0];
        fileInfo.filename = fileInfo.name;

        var fileData = new FormData();
        fileData.append('attachment', fileInfo);
        fileData.append('folder', 'tutee');

        //console.log(fileData);
        
        try {
            const response = await axios.post(apiUrl+'/file/single', fileData, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setFormData({
                    ...formData,
                    file: res.data.key,
                    originfile: fileInfo.filename,
                    fileurl: '',
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 이전 페이지로
    const goBack = () => {
        navigate('/AllStudent');
    }

    return (
        <>
            <PageTitle  motherMenu={"회원관리"} grandMenu={"수강생관리"} grandMenuLink="/AllStudent" activeMenu={menuName.pageContent}/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Enter_Name"><span className="danger">*</span> 회원명</label>
                                            <input id="Enter_Name" 
                                                placeholder="회원명 입력" 
                                                type="text" 
                                                name="name"
                                                value={formData.name} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Enter_ID"><span className="danger">*</span> 회원아이디</label>
                                            <input id="Enter_ID" 
                                                placeholder="회원아이디 입력" 
                                                type="text" 
                                                name="loginid"
                                                value={formData.loginid} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Enter_password">
                                                {mode === 'insert' && (
                                                <span className="danger">*</span> 
                                                )}
                                                회원 비밀번호
                                            </label>
                                            <input id="Enter_password" 
                                                placeholder="회원 비밀번호 입력" 
                                                type="password" 
                                                name="password"
                                                value={formData.password} onChange={handleChange}
                                                className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Enter_Email"><span className="danger">*</span> 회원 이메일</label>
                                            <input id="Enter_Email" 
                                                placeholder="회원 이메일 입력" 
                                                type="email" 
                                                name="email"
                                                value={formData.email} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="lectureType"><span className="danger">*</span> 강의유형</label>
                                            <div className='d-flex gap-2 flex-wrap'>
                                                {lecturetypeData.map((item, index) =>(
                                                    <div key={index} className="form-check form-check-inline">
                                                        <label className="form-check-label"
                                                            htmlFor={`lectureid-${index}`}>
                                                            <input className="form-check-input" type="radio"
                                                                name="lectureid" id={`lectureid-${index}`}
                                                                value={item.lectureid}
                                                                checked={formData.lectureid===item.lectureid}
                                                                onChange={handleChange} />
                                                            {item.lecturetype}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label"><span className="danger">*</span> 회원소속</label>
                                            <div className='d-flex gap-2'>
                                                
                                                <Select 
                                                    isSearchable={false}
                                                    className="custom-react-select" 
                                                    options={affiliationsData}
                                                    value={formData.affiliationid}
                                                    onChange={handleAffiliationChange}
                                                    placeholder="선택"
                                                    aria-label="회원소속 선택"
                                                />
                                                <Link to="/BelongRegi/0" className="btn btn-primary light">+ 소속추가</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">담당강사</label>
                                            <Select 
                                                isSearchable={false}
                                                /* defaultValue={options[1]} */
                                                className="custom-react-select" 
                                                options={tutorData}
                                                value={formData.tutorid}
                                                onChange={handleTutorChange}
                                                placeholder="선택"
                                                aria-label="담당강사 선택"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor='classRound' className="form-label d-block">수강회차</label>
                                            <div className=' form-check-inline'>
                                                <input id="classRound"
                                                    placeholder="숫자입력" 
                                                    type="number" 
                                                    className="form-control" 
                                                    name="roundno"
                                                    value={formData.roundno} onChange={handleChange}
                                                />
                                            </div>
                                           <span>회차</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="status"><span className="danger">*</span> 회원상태</label>
                                            <div className="form-check form-check-inline">
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name="status" 
                                                    id='Notapproved' 
                                                    value='미승인' 
                                                    checked={formData.status==='미승인'}
                                                    onChange={handleChange}
                                                />
                                                <label className="form-check-label" htmlFor='Notapproved'>미승인</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name="status" 
                                                    id='approval' 
                                                    value='승인' 
                                                    checked={formData.status==='승인'}
                                                    onChange={handleChange}
                                                    />
                                                <label className="form-check-label" htmlFor='approval'>승인</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name="status" 
                                                    id='takingClass' 
                                                    value='수강' 
                                                    checked={formData.status==='수강'}
                                                    onChange={handleChange}
                                                    />
                                                <label className="form-check-label" htmlFor='takingClass'>수강</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name="status" 
                                                    id='NotTakingClass' 
                                                    value='미수강' 
                                                    checked={formData.status==='미수강'}
                                                    onChange={handleChange}
                                                    />
                                                <label className="form-check-label" htmlFor='NotTakingClass'>미수강</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name="status" 
                                                    id='secession' 
                                                    value='탈퇴' 
                                                    checked={formData.status==='탈퇴'}
                                                    onChange={handleChange}
                                                    />
                                                <label className="form-check-label" htmlFor='secession'>탈퇴</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="note">비고</label>
                                            <textarea id="note" 
                                                placeholder="비고 입력" 
                                                name="note"
                                                value={formData.note} onChange={handleChange}
                                                className="form-control" 
                                                rows="5"  
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-sm-12">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="file">회원 프로필</label>
                                            <input type="file" id='file' 
                                                className="form-control" 
                                                data-default-file="" 
                                                aria-label="회원 프로필 파일 첨부"
                                                onChange={uploadImage}
                                            />
                                            {formData.fileurl && (
                                                <img src={formData.fileurl} alt={formData.file} className="imagePreview" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>등록</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentRegi;