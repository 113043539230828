import React, {useState, useRef, useEffect} from 'react';
import { Row, Col, Dropdown, Button } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {Link} from 'react-router-dom';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

const theadData = [
    {heading: 'No.', sortingValue:"lectureid"},
    {heading: '강의유형', sortingValue:"lecturetype"},
    {heading: '노출여부', sortingValue:""},
    {heading: '수정', sortingValue:""},
];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const LectureType = () => {

    useTitle("강의유형 관리");

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const totalPages = Math.ceil(totalCnt / row);
    
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const [tableData, setTableData] = useState([...dataList]);
    const [open, setOpen] = useState(true); //filter

    const [formData, setFormData] = useState({
        searchtext: ''
    });

    // 강의유형 리스트 가져오기
    const getLectureTypes = async () => {
        var searchtext = formData.searchtext;
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }

        try {
            const response = await axios.get(apiUrl+'/lecturetype?searchtext='+searchtext+'&page='+activePage.current+'&rows='+row+'&sort='+sortStr);
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var disableArr = info.disable.split(",");
                        var disable = "";
                        for(var j = 0; j < disableArr.length; j++){
                            if(disableArr[j] == "메인"){
                                var pageName = "메인";
                            }else if(disableArr[j] == "수업예약"){
                                var pageName = "수업예약";
                            }else if(disableArr[j] == "출석현황"){
                                var pageName = "출석현황";
                            }
                            if(j == 0){
                                disable += pageName;
                            }else{
                                disable += ", " + pageName;
                            }
                        }
                        info.disable = disable;
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    useEffect(() => {

        getLectureTypes();
        
    }, []);

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getLectureTypes();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
        }
    };

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getLectureTypes();
        }else{
            activePage.current = 1;
            getLectureTypes();
        }
    }, [row]);
    
    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getLectureTypes();
    }
    
    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            onClick(activePage.current - 2);
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            onClick(activePage.current);
        }
    };

    // 페이지 버튼 렌더링 
     const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };    

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getLectureTypes();
        }
    }, [sort]);

    // 강의유형 삭제
    const deleteLectureType = async (lectureid) => {
        var result = window.confirm("강의유형을 삭제하시겠습니까?");
        if(result){
            try {
                const response = await axios.delete(apiUrl+'/lecturetype/delete/' + lectureid);
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("강의유형 삭제하였습니다.");
                    activePage.current = 1;
                    getLectureTypes();
                }else{
                    alert(res.msg);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    return (
        <>
            <PageTitle  motherMenu={"수업관리"} activeMenu={"강의유형 관리"}/>
            <Row>
                <Col lg={12}>
                    <div className="filter cm-content-box box-primary">
                        <div className="content-title"
                            onClick={() => setOpen(!open)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    setOpen(!open);
                                }
                            }}
                            role="button"
                            tabIndex="0"
                        >
                            <div className="cpa">
                                <i className="fas fa-filter me-2"></i>Filter
                            </div>
                            <div className="tools">
                                <Link to={"#"} className={`SlideToolHeader ${open ? 'collapse' : 'expand' }`}                                   
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        
                        <Collapse in={open}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row filter-row">
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="searchtext" className='form-label'>검색어</label>
                                                <input
                                                    type="text"
                                                    name="searchtext"
                                                    value={formData.searchtext}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeyPress}
                                                    className="form-control"
                                                    placeholder="검색어를 입력해주세요"
                                                    />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6 align-self-end">
                                            <div className="form-group">
                                                <button className="btn btn-primary rounded-sm me-1" title="검색어를 입력해주세요" type="submit" onClick={submitSearch}><i className="fa fa-search me-1" />검색</button>
                                            </div>
                                        </div> 
                                    </div>
                                
                                </div>
                            </div>
                        </Collapse>   
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">&nbsp;</h4>
                            <Link to={"/lectureTypeRegi/0"} className="btn btn-primary">+ 등록</Link>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>                                    
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('5')}>5</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('30')}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm" >
                                        <thead>
                                            <tr>
                                                {theadData.map((item, ind) => (
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td><strong>{data.num}</strong> </td>
                                                    <td>{data.lecturetype}</td>                                                    
                                                    <td>{data.disable}</td>
                                                                                          
                                                    <td className='btns'>
                                                        <Link to={"/lectureTypeRegi/"+data.lectureid} className="btn btn-primary me-1 light">수정</Link>
                                                        <Button className='btn btn-danger light' onClick={() => deleteLectureType(data.lectureid)} >삭제</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        
                                        <div
                                            className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'
                                        >
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동">
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default LectureType;