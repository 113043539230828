import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import PageTitle from "../../layouts/PageTitle";

import config from '../../../config';
const apiUrl = config.apiUrl;

const labelStyle = {
    cursor: "point",
};

const LectureTypeRegi = () => {

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '강의유형 수정' : '강의유형 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        lecturetype: '',
        disable: []
    });

    const [menuName, setMenuName] = useState({
        activeMenu: '강의유형 관리 등록',
        motherMenu: '강의유형 관리',
        pageContent: '강의유형 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    // 수정 모드인 경우 데이터를 가져와서 폼에 채움
    useEffect(() => {
        if (id > 0) {
            // 여기서는 데이터를 가져오는 비동기 작업을 수행하고 formData를 채워야 함
            setMode('update');
            setMenuName(prevState => ({
                ...prevState,
                activeMenu: '강의유형 관리 수정',
                motherMenu: '강의유형 관리',
                pageContent: '강의유형 수정'
            }))
            getLectureType();
        }
    }, [id]);

    // 값 변경 시
    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        
        if (name === 'disable') {
            let newOptions;
            if (checked) {
                newOptions = [...formData.disable, value];
            } else {
                newOptions = formData.disable.filter(option => option !== value);
            }

            setFormData({
                ...formData,
                disable: newOptions
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        if(formData.lecturetype === ""){
            alert("강의유형을 입력해주세요.");
            return false;
        }
        if(!formData.disable || formData.disable.length === 0){
            alert("노출여부를 선택해주세요.");
            return false;
        }
        
        if(mode === "insert"){
            insertLectureType();
        }else{
            updateLectureType();
        }
    };

    // 강의유형 정보 가져오기
    const getLectureType = async () => {
        
        try {
            const response = await axios.get(apiUrl+'/lecturetype/'+id);
            var res = response.data;
            //console.log(res);
            var resData = res.data;
            var disableArr = resData.disable.split(",");
            setFormData({
                ...formData,
                lecturetype: resData.lecturetype,
                disable: disableArr
            });

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 강의유형 등록
    const insertLectureType = async () => {
        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.isdelete = false;
        params.regdate = regdate;

        try {
            const response = await axios.post(apiUrl+'/lecturetype/insert', params);
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("강의유형 등록하였습니다.");
                navigate('/lectureType');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 강의유형 수정
    const updateLectureType = async () => {
        var params = {...formData};
        params.lectureid = Number(id);
        //console.log(params)

        try {
            const response = await axios.put(apiUrl+'/lecturetype/update', params);
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("강의유형 수정하였습니다.");
                navigate('/lectureType');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 강의유형 삭제
    const deleteLectureType = async () => {
        var result = window.confirm("강의유형을 삭제하시겠습니까?");
        if(result){
            var lectureid = id;
            try {
                const response = await axios.delete(apiUrl+'/lecturetype/delete/' + lectureid);
                var res = response.data;
                //console.log(res);
                if(res.success){
                alert("강의유형 삭제하였습니다.");
                navigate('/lectureType');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const goBack = () => {
        navigate('/lectureType');
    }

    return (
        <Fragment>
            <PageTitle  motherMenu={"수업 관리"} grandMenu={"강의유형 관리"} grandMenuLink="/lectureType" activeMenu={"강의유형 등록"}/>

            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">강의유형 등록</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">

                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                        <label className="form-label" htmlFor="lectureType">강의유형</label>
                                        <input type="text" id="lectureType" name="lecturetype" value={formData.lecturetype} onChange={handleChange}
                                            className="form-control input-default " placeholder="강의유형을 입력하세요." />
                                        </div>
                                    </div>

                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                        <label className="form-label d-block" htmlFor="Exposure">홈페이지 노출 여부</label>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" className="form-check-input" name="disable" value="메인" 
                                                checked={formData.disable.includes("메인")} onChange={handleChange} id="mainCheckbox" />
                                            <label className="form-check-label" htmlFor="mainCheckbox" style={labelStyle}>메인</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" className="form-check-input" name="disable" value="수업예약"
                                                checked={formData.disable.includes("수업예약")} onChange={handleChange} id="reserveCheckbox" />
                                            <label className="form-check-label" htmlFor="reserveCheckbox">수업예약</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" className="form-check-input" name="disable" value="출석현황"
                                                checked={formData.disable.includes("출석현황")} onChange={handleChange} id="statusCheckbox" />
                                            <label className="form-check-label" htmlFor="statusCheckbox">출석현황</label>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary me-1" onClick={handleSubmit}>등록</button>
                                        {mode != 'insert' && (
                                        <button onClick={deleteLectureType} className="btn btn-danger light me-1" variant="danger">
                                        삭제
                                        </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LectureTypeRegi;