import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/react.html



const CkEditorBlog = ({ data, onChange }) => {
    return (
        <>
            <CKEditor
                editor={ClassicEditor}    
                data={data} //데이터값 전달
                 config={{ // 에디터 설정
                    toolbar: [
                        'undo', 'redo',
                        '|',
                        'heading',
                        '|',
                        'bold', 'italic', 
                        '|',
                        'link', 'blockQuote', 
                        '|',
                        'bulletedList', 'numberedList', 'outdent', 'indent'
                    ],
                    simpleUpload: {
                        // 이미지 업로드를 위한 설정
                        uploadUrl: 'https://your-upload-endpoint',
                        headers: {
                            'X-CSRF-TOKEN': 'CSRF-Token',
                            Authorization: 'Bearer <JSON Web Token>'
                        }
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn', 'tableRow', 'mergeTableCells'
                        ]
                    }
                }}
                onReady={ editor => {                    
                    
                } }
                onChange={ ( event, editor ) => {
                    if (onChange) {
                        onChange(event, editor); // 부모 컴포넌트의 onChange 호출
                    }
                    // const data = editor.getData();
                } }
                onBlur={ ( event, editor ) => {
                    
                } }
                onFocus={ ( event, editor ) => {
                    
                } }
            /> 
        </>
    );
};

export default CkEditorBlog;