import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var menus = ['dashboard', 'member', 'lecture', 'contents', 'homepage'];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const SuperAdminRegi = () => {

    useTitle("슈퍼관리자 등록");

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const navigate = useNavigate();
    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const [formData, setFormData] = useState({
        name: '',
        loginid: '',
        password: '',
        passwordchk: '',
        menus: []
    });

    const [menuData, setMenuData] = useState([...menus])

    const [menuName, setMenuName] = useState({
        pageContent: '슈퍼 관리자 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    // 수정 모드인 경우 데이터를 가져와서 폼에 채움
    useEffect(() => {
        if (id !== "0" && id !== 0) {
            // 여기서는 데이터를 가져오는 비동기 작업을 수행하고 formData를 채워야 함
            setMode('update');
            setMenuName(prevState => ({
                ...prevState,
                pageContent: '슈퍼 관리자 수정'
            }))
            getAdmin();
        }
    }, [id]);

    // 값 변경 시
    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        
        if (name === 'menus') {
            let newOptions;
            if (checked) {
                newOptions = [...formData.menus, value];
            } else {
                newOptions = formData.menus.filter(option => option !== value);
            }

            setFormData({
                ...formData,
                menus: newOptions
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        if(formData.name === ""){
            alert("관리자명을 입력해주세요.");
            return false;
        }
        if(formData.loginid === ""){
            alert("아이디를 입력해주세요.");
            return false;
        }
        if(mode == "insert"){
            if(formData.password === ""){
                alert("비밀번호를 입력해주세요.");
                return false;
            }
            if(formData.passwordchk === ""){
                alert("비밀번호를 재입력해주세요.");
                return false;
            }
            if(formData.password != formData.passwordchk){
                alert("비밀번호가 일치하지 않습니다.");
                return false;
            }
        }else{
            if(formData.password != ""){
                if(formData.passwordchk === ""){
                    alert("비밀번호를 재입력해주세요.");
                    return false;
                }

                if(formData.password != formData.passwordchk){
                    alert("비밀번호가 일치하지 않습니다.");
                    return false;
                }
            }
        }
        if(!formData.menus || formData.menus.length === 0){
            alert("권한을 선택해주세요.");
            return false;
        }
        
        if(mode === "insert"){
            insertAdmin();
        }else{
            updateAdmin();
        }
    };

    // 슈퍼 관리자 정보 가져오기
    const getAdmin = async () => {
        
        try {
            const response = await axios.get(apiUrl+'/admin/info/'+id, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                var menusArr = resData.menus.split(",");
                setFormData({
                    ...formData,
                    name: resData.name,
                    loginid: resData.loginid,
                    menus: menusArr,
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 슈퍼 관리자 등록
    const insertAdmin = async () => {
        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};

        try {
            const response = await axios.post(apiUrl+'/admin/insert', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("슈퍼 관리자 등록하였습니다.");
                navigate('/SuperAdmin');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 슈퍼 관리자 수정
    const updateAdmin = async () => {
        var params = {...formData};
        params.adminid = id;

        var menuArr = params.menus;
        var menuStr = "";
        for(var i = 0; i < menuArr.length; i++){
            if(i == 0){
                menuStr = menuArr[i];
            }else{
                menuStr += "," + menuArr[i];
            }
        }
        params.menus = menuStr;

        try {
            const response = await axios.put(apiUrl+'/admin/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("슈퍼 관리자 수정하였습니다.");
                navigate('/SuperAdmin');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const goBack = () => {
        navigate('/SuperAdmin');
    }

    return (
        <>
            <PageTitle motherMenu={"홈페이지관리"} grandMenu={"슈퍼 관리자 관리"} activeMenu={menuName.pageContent}  />
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="name">일반 관리자명</label>
                                            <input id="name" 
                                                name="name"
                                                value={formData.name} 
                                                onChange={handleChange}
                                                placeholder="일반 관리자명 입력" 
                                                type="text" 
                                                className="form-control" 
                                                required 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="ID">아이디</label>
                                            <input id="ID" 
                                                name="loginid"
                                                value={formData.loginid} 
                                                onChange={handleChange}
                                                placeholder="아이디 입력" 
                                                type="text" 
                                                className="form-control" 
                                                required 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="pw">비밀번호</label>
                                            <input id="pw" 
                                                name="password"
                                                value={formData.password} 
                                                onChange={handleChange}
                                                placeholder="비밀번호 입력" 
                                                type="password" 
                                                className="form-control" 
                                                required 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="pwRe-enter">비밀번호 재입력</label>
                                            <input id="pwRe-enter" 
                                                name="passwordchk"
                                                value={formData.passwordchk} 
                                                onChange={handleChange}
                                                placeholder="비밀번호 재입력" 
                                                type="password" 
                                                className="form-control" 
                                                required 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="authority">일반관리자 권한 재입력</label>
                                            {menuData.map((item, index) =>(
                                                <div key={index} className="form-check form-check-inline">
                                                    <input className="form-check-input" 
                                                    type="checkbox" name="menus"
                                                        onChange={handleChange}
                                                        checked={formData.menus.includes(`${item}`)}
                                                        id={`menus-${index}`} 
                                                        value={item} />
                                                    <label className="form-check-label" htmlFor={`menus-${index}`}>{item}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mt-5 col-lg-12 col-md-12 col-sm-12 ">
                                        <button type="button" onClick={goBack} className="btn btn-light me-1">취소</button>
                                        <button type="button" onClick={handleSubmit} className="btn btn-primary">등록</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SuperAdminRegi;