import React, {useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Collapse from 'react-bootstrap/Collapse';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var affiliations = [];
var dataList = [];

const theadData = [
    {heading: 'No.', sortingValue:"seq"},
    {heading: '이름', sortingValue:""},
    {heading: '강의유형', sortingValue:"lectureid"},
    {heading: '소속명', sortingValue:""},
    {heading: '회차', sortingValue:"roundno"},
    {heading: '시작일', sortingValue:"startdate"},
    {heading: '종료일', sortingValue:"enddate"},
];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const CourseConfirm = () => {

    useTitle("수강확인서 관리");
    
    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [sort, setSortData] = useState({ column: '', ascending: null});
    const [tableData, setTableData] = useState([...dataList]);
    const [open, setOpen] = useState(true); //filter

    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [affiliationsData, setAffiliations] = useState([...affiliations])
    const [formData, setFormData] = useState({
        lectureid: 0,
        affiliationid: '',
        searchtext: ''
    });

    // 강의유형 ASC 정렬
    const changeLectureType = () => {
        const sortedData = [...lecturetypes].sort((a, b) => {
            if (a.lectureid < b.lectureid) return -1;
            if (a.lectureid > b.lectureid) return 1;
            return 0;
        });
        setLecturetypeData(sortedData);
    }

    // 수강확인서 리스트 가져오기
    const getCourseConfirm = async () => {
        var lectureid = formData.lectureid;
        var affiliationid = formData.affiliationid;
        var searchtext = formData.searchtext;
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }

        try {
            const response = await axios.get(apiUrl+'/courseconfirm/list?lectureid='+lectureid+'&affiliations='+affiliationid+'&searchtext='+searchtext+'&page='+activePage.current+'&rows='+row+'&sort='+sortStr, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            var dataArr = [];
            //console.log(res);
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.roundno_tostring = info.roundno + "차";
                        info.startdate_tostring = info.startdate ? info.startdate.substring(0, 10) : "";
                        info.enddate_tostring = info.enddate ? info.enddate.substring(0, 10) : "";
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    }; 
    
    useEffect(() => {

        // 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype');
                //console.log(response.data.data);
                var resData = response.data.data;
                lecturetypes = resData;
                changeLectureType();
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

       // 소속 리스트 가져오기
       const getAffiliations = async () => {
            try {
                const response = await axios.get(apiUrl+'/affiliation');
                //console.log(response.data.data);
                var resData = response.data.data;
                affiliations = [];
                if(resData){
                    affiliations.push({label: "전체", value: "", seq: 0});
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.name;
                        info.value = info.affiliationid;
                        affiliations.push(info);
                    }
                }

                setAffiliations(affiliations);
            
            } catch (error) {
            console.error('Error:', error);
            }
        }; 

        getLectureTypes();

        getAffiliations();
        
    }, []);

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getCourseConfirm();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'lectureid') {  
           setFormData({
              ...formData,
              [name]: Number(value)
           });
        } else {
           setFormData({
              ...formData,
              [name]: value
           });
        }
    };

    // 소속 검색 변경 시
    const handleAffiliationChange = (e) => {
        //console.log(e);
        const value = e.value;

        setFormData({
            ...formData,
            affiliationid: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
          }
    };

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getCourseConfirm();
        }else{
            activePage.current = 1;
            getCourseConfirm();
        }
    }, [row]);

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
         getCourseConfirm();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
             getCourseConfirm();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages ) {
            activePage.current += 1; // 1페이지씩 이동
             getCourseConfirm();
        }
    };

    
    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages , startPage + maxButtons - 1);


        const paginationButtons = [];
    for (let i = startPage; i <= endPage; i++) {
        paginationButtons.push(
            <Link
                key={i}
                to='#'
                className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                onClick={() => onClick(i - 1)}
            >
                {i}
            </Link>
        );
    }

        return paginationButtons;
    };
    

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getCourseConfirm();
        }
    }, [sort]);

    return (
        <>
            <PageTitle activeMenu={"수강확인서 관리"} motherMenu={"홈페이지관리"} />
            <Row>
                <Col lg={12}>
                    <div className="filter cm-content-box box-primary">
                        <div className="content-title"
                         onClick={() => setOpen(!open)}
                         onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    setOpen(!open);
                                }
                            }}
                            role="button"
                            tabIndex="0"
                        >
                            <div className="cpa">
                                <i className="fas fa-filter me-2"></i>Filter
                            </div>
                            <div className="tools">
                                <Link to={"#"} className={`SlideToolHeader ${open ? 'collapse' : 'expand' }`}                                   
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        
                        <Collapse in={open}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row filter-row">
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className='form-label d-block'>강의유형</label>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor='lectureid-all'>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="lectureid"
                                                            id='lectureid-all'
                                                            value='0'
                                                            checked={formData.lectureid === 0}
                                                            onChange={handleChange}
                                                            />
                                                        전체</label>
                                                </div>
                                                {lecturetypeData.map((item, index) =>(
                                                    <div key={index} className="form-check form-check-inline">
                                                        <label className="form-check-label" htmlFor={`lectureid-${index}`}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="lectureid"
                                                            id={`lectureid-${index}`}
                                                            value={item.lectureid}
                                                            checked={formData.lectureid === item.lectureid}
                                                            onChange={handleChange}
                                                            />
                                                        {item.lecturetype}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-5">
                                            <div className="form-group">
                                                <label className='form-label'>소속</label>
                                                <Select
                                                    isSearchable = {false}
                                                    options={affiliationsData}
                                                    className="custom-react-select"
                                                    placeholder="선택"
                                                    name="affiliationid"
                                                    onChange={handleAffiliationChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="searchtext" className='form-label'>검색어</label>
                                                <input
                                                    type="text"
                                                    name="searchtext"
                                                    value={formData.searchtext}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeyPress}
                                                    className="form-control"
                                                    placeholder="검색어를 입력해주세요"
                                                    />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-sm-6 align-self-end">
                                            <div className="form-group"><button className="btn btn-primary rounded-sm me-2" title="검색어를 입력해주세요" type="submit" onClick={submitSearch}><i className="fa fa-search me-1" />검색</button></div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </Collapse>   
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('5')}>5</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            <tr>
                                                {theadData.map((item, ind) => (
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td><strong>{data.num}</strong> </td>
                                                    <td>{data.tutorname}</td>
                                                    <td>{data.lecturetype}</td>
                                                    <td>{data.affiliationname}</td>
                                                    <td>{data.roundno_tostring}</td>
                                                    <td>{data.startdate_tostring}</td>
                                                    <td>{data.enddate_tostring}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers' id='example5_paginate'>
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );

}

export default CourseConfirm;