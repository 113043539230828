import React, {useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Row, Col, Modal ,Button } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import PageTitle from '../../layouts/PageTitle';

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var affiliations = [];
var dataList = [];

const theadData = [
    {heading: '강의번호', sortingValue:"scheduleid"},
    {heading: '강의유형', sortingValue:""},
    {heading: '소속', sortingValue:""},
    {heading: '학생명', sortingValue:""},
    {heading: '날짜', sortingValue:"datetxt"},
    {heading: '요일', sortingValue:"weektxt"},
    {heading: '강사', sortingValue:""},
    {heading: '시간', sortingValue:""},
    {heading: '차시', sortingValue:"round"},
    {heading: '교재', sortingValue:""},
    {heading: '상태', sortingValue: "status" },
    {heading: '강의실', sortingValue:""},
    {heading: '출결', sortingValue:"attendance"},
    {heading: '자료', sortingValue:"",},
];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const AllLecture = () => {

    useTitle("강의현황");
    
    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [sort, setSortData] = useState({ column: 'scheduleid', ascending: ''});
    const totalPages = Math.ceil(totalCnt / row); // 총 페이지 수
    const [tableData, setTableData] = useState([...dataList]);
    const [open, setOpen] = useState(true); // filter 영역 on/off

    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes]);
    const [affiliationsData, setAffiliations] = useState([...affiliations]);
    const [formData, setFormData] = useState({
        lectureid: 0,
        status: '',
        affiliations: '',
        searchtext: ''
    });

    const lectureStatus = [//강의상태
        { value: '', label: '전체' },
        { value: '대기', label: '대기' },
        { value: '승인', label: '승인' },
        { value: '진행', label: '진행' },
        { value: '종료', label: '종료' }
    ]

    var today = new Date();
    const eyear = today.getFullYear();
    const emonth = String(today.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
    const eday = String(today.getDate()).padStart(2, '0');

    var sdate = new Date();
    sdate.setMonth(sdate.getMonth() - 3);

    const syear = sdate.getFullYear();
    const smonth = String(sdate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
    const sday = String(sdate.getDate()).padStart(2, '0');

    var sdate = `${syear}/${smonth}/${sday}`;
    var edate = `${eyear}/${emonth}/${eday}`;
    const [startDate, setStartDate] = useState(sdate);
    const [endDate, setEndDate] = useState(edate);

    const handleCallback = (start, end) => {
        setStartDate(start.format('YYYY/MM/DD'));
        setEndDate(end.format('YYYY/MM/DD'));
    };

    // 강의유형 ASC 정렬
    const changeLectureType = () => {
        const sortedData = [...lecturetypes].sort((a, b) => {
            if (a.lectureid < b.lectureid) return -1;
            if (a.lectureid > b.lectureid) return 1;
            return 0;
        });
        setLecturetypeData(sortedData);
    }

    // 소속 ASC 정렬
    const changeAffiliation = () => {
        const sortedData = [...affiliations].sort((a, b) => {
            if (a.seq < b.seq) return -1;
            if (a.seq > b.seq) return 1;
            return 0;
        });
        setAffiliations(sortedData);
    }

    // 강의 현황 리스트 가져오기
    const getMyClassSchedule = async () => {
        var lectureid = formData.lectureid;
        var status = formData.status;
        var affiliations = formData.affiliations;
        var searchtext = formData.searchtext;
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }
        
        try {
            const response = await axios.get(apiUrl+'/tutoring/MyClassSchedule?searchtext='+searchtext+'&lectureid='+lectureid+'&status='+status+'&affiliations='+affiliations+'&start='+startDate+'&end='+endDate+'&page='+activePage.current+'&rows='+row+'&sort='+sortStr, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 엑셀 다운로드
    const downloadExcel = async () => {
        var lectureid = formData.lectureid;
        var status = formData.status;
        var affiliations = formData.affiliations;
        var searchtext = formData.searchtext;
        var sortStr = "";
        if(sort.column !== ""){
            var sorting = "desc";
            if(sort.ascending){
                sorting = "asc";
            }
            sortStr = sort.column + "," + sorting;
        }
        
        try {
            const response = await axios.get(apiUrl+'/tutoring/excel?searchtext='+searchtext+'&lectureid='+lectureid+'&status='+status+'&affiliations='+affiliations+'&start='+startDate+'&end='+endDate+'&sort='+sortStr, {
                headers: {
                    token: accessToken
                },
                responseType: 'blob', // 응답 타입을 Blob으로 설정
            });
            var res = response.data;
            //console.log(res);
            
            // Blob 데이터를 그대로 저장
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, '강의현황.xlsx');

        } catch (error) {
           console.error('Error:', error);
        }
    }

    useEffect(() => {

        // 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype');
                //console.log(response.data.data);
                var resData = response.data.data;
                lecturetypes = resData;
                changeLectureType();
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        // 소속 리스트 가져오기
        const getAffiliations = async () => {
            try {
                const response = await axios.get(apiUrl+'/affiliation');
                //console.log(response.data.data);
                var resData = response.data.data;
                affiliations = [];
                if(resData){
                    affiliations.push({label: "전체", value: "", seq: 0});
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.name;
                        info.value = info.affiliationid;
                        affiliations.push(info);
                    }
                }

                changeAffiliation();
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

        getLectureTypes();

        getAffiliations();
        
    }, []);

    // 강의실 입장하기
    const enterClassroom = async (join_url) => {
        window.open(join_url, "_blank");        
    };

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getMyClassSchedule();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'lectureid') {  
            setFormData({
               ...formData,
               [name]: Number(value)
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // 상태 검색 변경 시
    const handleStatusChange = (e) => {
        //console.log(e);
        const value = e.value;

        setFormData({
            ...formData,
            status: value
        });
    };

    // 소속 검색 변경 시
    const handleAffiliationChange = (e) => {
        //console.log(e);
        const value = e.value;

        setFormData({
            ...formData,
            affiliations: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
        }
    };

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getMyClassSchedule();
        }else{
            activePage.current = 1;
            getMyClassSchedule();
        }
    }, [row]);
    

    // 페이지 변경 시
    const onClick = (i) => {
        activePage.current = i + 1;
        getMyClassSchedule();
    }

    // 이전 페이지
    const prevPage = () => {
        if (activePage.current > 1) {
            activePage.current -= 1;
            getMyClassSchedule();
        }
    };

    // 다음 페이지
    const nextPage = () => {
        if (activePage.current < totalPages) {
            activePage.current += 1; // 1페이지씩 이동
            getMyClassSchedule();
        }
    };

    // 페이징 버튼 렌더링
    const renderPaginationButtons = () => {
        const maxButtons = window.innerWidth < 576 ? 3 : 5; // 반응형 크기 따라 버튼 개수 다르게
        const currentPage = activePage.current; // 현재 페이지

        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);


        const paginationButtons = [];
        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Link
                    key={i}
                    to='#'
                    className={`paginate_button ${currentPage === i ? 'current' : ''}`}
                    onClick={() => onClick(i - 1)}
                >
                    {i}
                </Link>
            );
        }

        return paginationButtons;
    };

    // Sorting 기능
    const handleSorting = (column) => {
        const isascending = sort.column === column ? !sort.ascending : true;
        setSortData({ column: column, ascending: isascending });
    }

    // sort 기준 변경 시 리스트 다시 불러오기
    useEffect(() => {
        if (sort.column !== '' && sort.ascending !== null) {
            activePage.current = 1;
            getMyClassSchedule();
        }
    }, [sort]);

    // 전체 선택, 개별선택
    const [selectAll, setSelectAll] = useState(false);
    const [isChecked, setIsChecked] = useState(new Array(dataList.length).fill(false));
    const [checkedArr, setCheckedArr] = useState([]);

    // 전체 선택 체크박스를 클릭
    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        setIsChecked(new Array(tableData.length).fill(checked));
    };

    // 개별 선택 체크박스를 클릭
    const handleCheckboxChange = (index) => {
        const newIsChecked = [...isChecked];
        newIsChecked[index] = !newIsChecked[index];
        setIsChecked(newIsChecked);
        
        var checkedIndex = [];
        for(var i = 0; i < newIsChecked.length; i++){
            if(newIsChecked[i]){
                checkedIndex.push(i);
            }
        }
        setCheckedArr(checkedIndex);
    };

    // 수업일정 삭제
    const deleteRows = async () => {
        var selectedids = "";
        if(selectAll){
            for(var i = 0; i < tableData.length; i++){
                if(i == 0){
                    selectedids = tableData[i].scheduleid;
                }else{
                    selectedids += "," + tableData[i].scheduleid;
                }
            }
        }else{
            if(checkedArr.length == 0){
                alert("삭제할 항목을 선택해주세요.");
                return false;
            }else{
                for(var i = 0; i < checkedArr.length; i++){
                    if(i == 0){
                        selectedids = tableData[checkedArr[i]].scheduleid;
                    }else{
                        selectedids += "," + tableData[checkedArr[i]].scheduleid;
                    }
                }
            }
        }

        var result = window.confirm("선택된 항목을 삭제하시겠습니까?");
        if(result){
            
            try {
                const response = await axios.delete(apiUrl+'/tutoring/deleteSchedule?scheduleid=' + selectedids, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("삭제하였습니다.");
                    activePage.current = 1;
                    getMyClassSchedule();
                    setSelectAll(false);
                    setIsChecked(new Array(tableData.length).fill(false))
                    setCheckedArr([]);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 결석사유 모달
    const [modalCentered, setModalCentered] = useState(false);
    const [reason, setReason] = useState();
    const openReasonModal = (reason) => {
        setReason(reason);
        setModalCentered(true);
    };

    
    return (
        <>
            <PageTitle motherMenu={"수업관리"} activeMenu={"강의현황"}  />
            <Row>
             <Col lg={12}>
                 <div className="filter cm-content-box box-primary">
                        <div className="content-title"
                            onClick={() => setOpen(!open)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    setOpen(!open);
                                }
                            }}
                            role="button"
                            tabIndex="0"
                        >
                            <div className="cpa">
                                <i className="fas fa-filter me-2"></i>Filter
                            </div>
                            <div className="tools">
                                <Link to={"#"} className={`SlideToolHeader ${open ? 'collapse' : 'expand' }`}                                   
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        
                        <Collapse in={open}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row filter-row">
                                        <div className="col-xl-12 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label d-block" htmlFor="lectureid">강의유형</label>
                                                <div className='d-flex gap-2'>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <label className="form-check-label" htmlFor='lectureid-all'>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="lectureid"
                                                                    id='lectureid-all'
                                                                    value='0'
                                                                    checked={formData.lectureid === 0}
                                                                    onChange={handleChange}
                                                                    />
                                                                전체</label>
                                                        </div>
                                                        {lecturetypeData.map((item, index) =>(
                                                            <div key={index} className="form-check form-check-inline">
                                                                <label className="form-check-label" htmlFor={`lectureid-${index}`}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="lectureid"
                                                                    id={`lectureid-${index}`}
                                                                    value={item.lectureid}
                                                                    checked={formData.lectureid === item.lectureid}
                                                                    onChange={handleChange}
                                                                    />
                                                                {item.lecturetype}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <Link to="/lectureTypeRegi/0" className="btn btn-primary light">+
                                                    강의유형 추가</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <div className='form-group'>
                                                <label className='form-label'>강의상태</label>
                                                <Select
                                                    isSearchable = {false}
                                                    options={lectureStatus}
                                                    className="custom-react-select"
                                                    placeholder="선택"
                                                    aria-label='강의상태 선택'
                                                    onChange={handleStatusChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="form-group">
                                                <label className='form-label'>회원소속</label>
                                                <Select
                                                    isSearchable = {false}
                                                    options={affiliationsData}
                                                    className="custom-react-select"
                                                    placeholder="선택"
                                                    aria-label='회원소속 선택'
                                                    onChange={handleAffiliationChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 ">
                                             <div className="form-group">
                                                <label className='form-label' htmlFor="datePicker">날짜</label>
                                                <DateRangePicker 
                                                    initialSettings={{ 
                                                        startDate: startDate,
                                                        endDate: endDate,
                                                        locale: { format: 'YYYY/MM/DD' }, 
                                                    }}
                                                    onCallback={handleCallback}
                                                >
                                                    <input type="text" id="datePicker" className="form-control input-daterange-timepicker" aria-label="날짜 범위를 선택하세요" />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="searchtext" className='form-label'>검색어</label>
                                                <input
                                                    type="text"
                                                    name="searchtext"
                                                    value={formData.searchtext}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeyPress}
                                                    className="form-control"
                                                    placeholder="검색어를 입력해주세요"
                                                    />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6 align-self-end">
                                            <div className="form-group"><button className="btn btn-primary rounded-sm me-2" title="검색어를 입력해주세요" type="submit" onClick={submitSearch}><i className="fa fa-search me-1" />검색</button></div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </Collapse>   
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between flex-wrap gap-2">
                            <button type='button' onClick={downloadExcel} className="btn btn-primary btn-sm">EXCEL 다운로드</button>
                            <div className=' d-flex justify-content-end gap-2'>
                                <button type='button' onClick={deleteRows} className="btn btn-danger btn-sm">삭제</button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id='holidayList' className='dataTables_wrapper no-footer'>
                                    <div className='justify-content-between d-sm-flex'>
                                        <div className='dataTables_length'>
                                            <label className='d-flex align-items-center'>
                                                Total : {totalCnt} 건 &nbsp;
                                                <Dropdown className='search-drop'>
                                                    <Dropdown.Toggle as="div" className="search-drop-btn">
                                                        {row}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>setRowData('5')}>5</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('10')}>10</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('20')}>20</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setRowData('30')}>30</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                건씩 표시중
                                            </label>
                                        </div>
                                    </div>
                                    <table id="example4" className="table verticle-middle table-responsive-sm">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="checkAll"
                                                        checked={selectAll} 
                                                        onChange={handleSelectAll} 
                                                        aria-label="모두 선택"
                                                    />
                                                </th>
                                                {theadData.map((item, ind)=>(
                                                    <th
                                                        key={ind}
                                                        onClick={() => {
                                                            handleSorting(item.sortingValue);
                                                        }}
                                                        role="button"
                                                        tabIndex={0} 
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                handleSorting(item.sortingValue);
                                                            }
                                                        }}
                                                        aria-label={item.heading + (sort.column === item.sortingValue && sort.ascending ? " 내림차순" : " 오름차순")}
                                                    >
                                                        {item.heading}
                                                        <span>
                                                            {item.sortingValue && (
                                                            <span>
                                                                {sort.column === item.sortingValue &&
                                                                    (!sort.ascending ?
                                                                        <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                        :
                                                                        <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
                                                                    )
                                                                }
                                                                {sort.column !== item.sortingValue &&
                                                                    <i className="fa fa-sort ms-2 fs-12" style={{ opacity: '0.3' }} />
                                                                }
                                                            </span>
                                                        )}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, ind)=>(
                                                <tr key={ind}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={isChecked[ind]}
                                                            onChange={() => handleCheckboxChange(ind)} 
                                                            aria-label={`선택: ${data.scheduleid}`}
                                                        />
                                                    </td>
                                                    <td>{data.scheduleid}</td>
                                                    <td>{data.lecturetype}</td>
                                                    <td>{data.affiliationname}</td>
                                                    <td>{data.tuteename}</td>
                                                    <td>{data.datetxt}</td>
                                                    <td>{data.weektxt}</td>
                                                    <td>{data.tutorname}</td>
                                                    <td>{data.starttxt}</td>
                                                    <td>{data.round}</td>
                                                    <td>{data.booktitle}</td>
                                                    <td>
                                                        {data.status === "승인" ? (
                                                            <span className="badge badge-success light">
                                                                {data.status}
                                                            </span>
                                                        ) : data.status === "대기" ? (
                                                            <span className="badge badge-light light ">
                                                                {data.status}
                                                            </span>
                                                        ) : data.status === "진행" ? (
                                                            <span className="badge badge-primary light">
                                                                {data.status}
                                                            </span>
                                                        ) :
                                                            data.status === "종료" ? (
                                                            <span className="badge badge-danger light">
                                                                {data.status}
                                                            </span>
                                                        ) :  null}
                                                    </td>
                                                    <td>
                                                        {data.join_url ? (
                                                            <button type='button' onClick={() => enterClassroom(data.join_url)} className="btn btn-success light">
                                                                입장하기
                                                            </button>
                                                        ):  null}
                                                    </td>
                                                    <td>
                                                        {data.attendance === "출석" ? (
                                                            <span className="badge badge-primary light">
                                                            {data.attendance}
                                                            </span>
                                                        ) : data.attendance === "결석" ? (
                                                            <>
                                                                <span className="badge badge-danger light">
                                                                    {data.attendance}
                                                                </span>
                                                                {data.reason && (
                                                                    <button type='button' className="reasonbtn" onClick={() => openReasonModal(data.reason)}>
                                                                    결석사유 보기
                                                                    </button>
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </td>
                                                    <td className='btns'>
                                                        <Link to={"/LectureData/"+data.scheduleid} className="btn btn-primary me-1 light">이동</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div
                                        className='d-sm-flex text-center justify-content-center align-items-center mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'>
                                            <Link
                                                className={`paginate_button previous ${activePage.current === 1 ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={prevPage}
                                                aria-label="이전 페이지로 이동">
                                                이전
                                            </Link>
                                            <span>
                                                {renderPaginationButtons()}
                                            </span>
                                            <Link
                                                className={`paginate_button next ${activePage.current === totalPages ? 'disabled' : ''}`}
                                                to='#'
                                                onClick={nextPage}
                                                aria-label="다음 페이지로 이동"
                                            >
                                                다음
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

            {/* 결석 사유 모달창 */}
            <Modal className="fade reasonmodal" show={modalCentered} onHide={setModalCentered} centered>
                <Modal.Header>
                    <Modal.Title>결석 사유</Modal.Title>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="btn-close"
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <p className='content'>
                        {reason}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setModalCentered(false)}>확인</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AllLecture;