import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];

const CurriculumRegi = () => {
    
    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '커리큘럼 수정' : '커리큘럼 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        lectureid: '',
        title: ''
    });
    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    
    const [menuName, setMenuName] = useState({
        activeMenu: '커리큘럼 관리 등록',
        motherMenu: '커리큘럼 관리',
        pageContent: '커리큘럼 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    // 강의유형 ASC 정렬
    const changeLectureType = () => {
        const sortedData = [...lecturetypes].sort((a, b) => {
            if (a.lectureid < b.lectureid) return -1;
            if (a.lectureid > b.lectureid) return 1;
            return 0;
        });
        setLecturetypeData(sortedData);
    }

    // 수정 모드인 경우 데이터를 가져와서 폼에 채움
    useEffect(() => {

        // 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype');
                //console.log(response.data.data);
                var resData = response.data.data;
                lecturetypes = resData;
                changeLectureType();
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

        getLectureTypes();

        if (id != 0) {
            // 여기서는 데이터를 가져오는 비동기 작업을 수행하고 formData를 채워야 함
            setMode('update');
            setMenuName(prevState => ({
                ...prevState,
                activeMenu: '커리큘럼 관리 수정',
                motherMenu: '커리큘럼 관리',
                pageContent: '커리큘럼 수정'
            }))
            getCurriculum();
        }
    }, [id]);

    // 값 변경 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'lectureid') {  
           setFormData({
              ...formData,
              [name]: Number(value)
           });
        } else {
           setFormData({
              ...formData,
              [name]: value
           });
        }
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        if(formData.lectureid === ""){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.title === ""){
            alert("커리큘럼명을 입력해주세요.");
            return false;
        }
        
        if(mode === "insert"){
            insertCurriculum();
        }else{
            updateCurriculum();
        }
    };

    // 커리큘럼 정보 가져오기
    const getCurriculum = async () => {
            
        try {
            const response = await axios.get(apiUrl+'/curriculum/'+id);
            var res = response.data;
            //console.log(res);
            var resData = res.data;
            setFormData({
                ...formData,
                lectureid: resData.lectureid,
                title: resData.title
            });

            //console.log(formData);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 커리큘럼 등록
    const insertCurriculum = async () => {
        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        //console.log(params);

        try {
            const response = await axios.post(apiUrl+'/curriculum/insert', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("커리큘럼 등록하였습니다.");
                navigate('/curriculum');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 커리큘럼 수정
    const updateCurriculum = async () => {
        var params = {...formData};
        params.curriculumid = id;
        //console.log(params)

        try {
            const response = await axios.put(apiUrl+'/curriculum/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("커리큘럼 수정하였습니다.");
                navigate('/curriculum');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 커리큘럼 삭제
    const deleteCurriculum = async () => {
        var result = window.confirm("커리큘럼을 삭제하시겠습니까?");
        if(result){
            var curriculumid = id;
            try {
                const response = await axios.delete(apiUrl+'/curriculum/delete/' + curriculumid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("커리큘럼 삭제하였습니다.");
                    navigate('/curriculum');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const goBack = () => {
        navigate('/curriculum');
    }
  
    return (
        <>
            <Fragment>
                <PageTitle motherMenu={"콘텐츠 관리"} grandMenu={"커리큘럼 관리"} grandMenuLink="/curriculum" activeMenu={menuName.pageContent}/>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{menuName.pageContent}</h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={(e)=> e.preventDefault()} className="row">
                                    <div className="row">

                                        <div className="col-xl-5 col-lg-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="curriculumName">커리큘럼명</label>
                                                <input id="curriculumName" type="text" name="title"
                                                    value={formData.title} onChange={handleChange}
                                                    className="form-control input-default "
                                                    placeholder="커리큘럼명을 입력하세요." />
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="lectureType">강의유형</label>

                                                <div className='d-flex gap-2 flex-wrap'>
                                                    {lecturetypeData.map((item, index) =>(
                                                    <div key={index} className="form-check form-check-inline">
                                                        <label className="form-check-label"
                                                            htmlFor={`lectureid-${index}`}>
                                                            <input className="form-check-input" type="radio"
                                                                name="lectureid" id={`lectureid-${index}`}
                                                                value={item.lectureid}
                                                                checked={formData.lectureid===item.lectureid}
                                                                onChange={handleChange} />
                                                            {item.lecturetype}</label>
                                                    </div>
                                                    ))}

                                                    <Link to="/lectureTypeRegi/0" className="btn btn-primary light">+
                                                    강의유형 추가</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <button type="button" className="btn btn-light me-1"
                                                onClick={goBack}>취소</button>
                                            <button type="submit" className="btn btn-primary me-1"
                                                onClick={handleSubmit}>등록</button>
                                            {mode !== 'insert' && (
                                            <Button onClick={deleteCurriculum} variant="danger">
                                                삭제
                                            </Button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    );
}

export default CurriculumRegi;