import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker } from 'rsuite';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
//https://www.npmjs.com/package/react-multi-select-component

import PageTitle from '../../layouts/PageTitle';
import CkEditorBlog from '../../elements/CkEditor/CkEditorBlog';

import config from '../../../config';
import { set } from "rsuite/esm/utils/dateUtils";
const apiUrl = config.apiUrl;

var lecturetypes = [];

const TeacherRegi = () => {
    
    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '강사 수정' : '강사 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트
    
    var getTutorCnt = 0;

    const accessToken = useSelector(state => state.auth.auth.accessToken);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        tutorid: '',
        name: '',
        loginid: '',
        password: '',
        tel: '',
        email: '',
        disabilitytype: '',
        lectureid: '',
        status: '',
        notexposedate: new Date(),
        affiliations: [],
        iscontract: false,
        contractdate: new Date(),
        enddate: new Date(),
        note: '',
        file: '',
        originfile: '',
        fileurl: '',
        intro: '',
        career: '',
        zoom: '',
    });
    
    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [affiliationsData, setAffiliationsData] = useState([{label: "선택", value: ""}])
    const [zoomData, setZoomData] = useState([{label: "선택", value: ""}])

    const [menuName, setMenuName] = useState({
        activeMenu: '강사등록하기',
        pageContent: '강사 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정

    useEffect(() => {

        // 수정 모드인 경우 데이터를 가져와서 폼에 채움
        const getUpdateData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse, zoomResponse, tutorResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                    axios.get(apiUrl + '/zoom/meeting/users', {
                        headers: {
                            token: accessToken
                        }                    
                    }),
                    axios.get(apiUrl + '/tutor/' + id, {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);

                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

                const zoomUsers = zoomResponse.data.data.users.map(zoomUser => ({
                    label: zoomUser.first_name,
                    value: zoomUser.id,
                    email: zoomUser.email,
                }));

                setZoomData(zoomUsers);

                const tutorData = tutorResponse.data.data;

                var affiliationsArr;
                if(tutorData.affiliations.includes(",")){
                    affiliationsArr = tutorData.affiliations.split(",");
                }else{
                    affiliationsArr = [];
                    affiliationsArr.push(tutorData.affiliations);
                }
                var affArr = [];
                for(var i = 0; i < affiliationsArr.length; i++){
                    for(var j = 0; j < affiliations.length; j++){
                        if(affiliationsArr[i] == affiliations[j].value){
                            affArr.push({
                                label: affiliations[j].label,
                                value: affiliations[j].value
                            })
                        }
                    }
                }
                //console.log(affArr);

                var selectedZoom = null;
                for(var i = 0; i < zoomUsers.length; i++){
                    
                    if(tutorData.zoom_userid == zoomUsers[i].value){
                        selectedZoom = {
                            label: zoomUsers[i].label,
                            value: zoomUsers[i].value,
                            email: zoomUsers[i].email,
                        };
                    }
                }

                //console.log(tutorData);
                
                setFormData(prevFormData => ({
                    ...prevFormData,
                    name: tutorData.name,
                    loginid: tutorData.loginid,
                    tel: tutorData.tel,
                    email: tutorData.email,
                    disabilitytype: tutorData.disabilitytype,
                    lectureid: tutorData.lectureid,
                    status: tutorData.status,
                    notexposedate: new Date(tutorData.notexposedate),
                    affiliations: affArr,
                    iscontract: tutorData.iscontract,
                    contractdate: new Date(tutorData.contractdate),
                    enddate: new Date(tutorData.enddate),
                    note: tutorData.note,
                    file: tutorData.file,
                    originfile: tutorData.originfile,
                    fileurl: tutorData.fileurl,
                    intro: tutorData.intro,
                    career: tutorData.career,
                    zoom: selectedZoom
                }));

            } catch (error) {
                console.error('Error:', error);
            }
        };

        const getInsertData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse, zoomResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                    axios.get(apiUrl + '/zoom/meeting/users', {
                        headers: {
                            token: accessToken
                        }                    
                    }),
                ]);

                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

                const zoomUsers = zoomResponse.data.data.users.map(zoomUser => ({
                    label: zoomUser.first_name,
                    value: zoomUser.id,
                    email: zoomUser.email,
                }));
                //console.log(zoomUsers);

                setZoomData(zoomUsers);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id !== "0" && id !== 0) {
            if(getTutorCnt == 0){
                getTutorCnt++;
                setMode('update');
                setMenuName(prevState => ({
                    ...prevState,
                    activeMenu: '강사수정하기',
                    pageContent: '강사 수정'
                }))
                getUpdateData();
            }
        }else{
            getInsertData();
        }
        
    }, [id]);

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'availableno' || name === 'roundno' || name === 'lectureid') {  
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: Number(value)
            }));
        } else if(name === "iscontract"){
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: e.target.checked
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    // 강사소속 변경 시
    const handleAffiliationChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            affiliations: e
        }));
    };

    // 강사를 노출하지 않을 날짜 변경 시
    const handleNotExposeDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            notexposedate: e
        }));
    };

    // 줌 계정 변경 시
    const handleZoomUserChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            zoom: e
        }));
    };

    // 날짜 변경 시
    const handleStartDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            contractdate: e
        }));
    };

    // 날짜 변경 시
    const handleEndDateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            enddate: e
        }));
    };

    // 강사소개 변경 시
    const handleIntroChange = (event, editor) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            intro: editor.getData()
        }));
    };

    // 경력사항 변경 시
    const handleCareerChange = (event, editor) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            career: editor.getData()
        }));
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        
        if(mode === "insert"){
            insertTutor();
        }else{
            updateTutor();
        }
    };

    // 강사 등록
    const insertTutor = async () => {
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        var affArr = formData.affiliations;
        var affiliationIds = "";
        for(var i = 0; i < affArr.length; i++){
            if(i == 0){
                affiliationIds += affArr[i].value;
            }else{
                affiliationIds += "," + affArr[i].value;
            }
        }
        params.affiliations = affiliationIds;

        params.zoom_userid = formData.zoom.value;
        params.zoom_email = formData.zoom.email;
        if(params.status == "normal"){
            params.notexposedate = "2100-01-01";
        }
        //console.log(params);

        try {
            const response = await axios.post(apiUrl+'/tutor/insert', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("강사 등록하였습니다.");
                navigate('/AllTeacher');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 강사 수정
    const updateTutor = async () => {
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var params = {...formData};
        params.tutorid = id;

        var affArr = formData.affiliations;
        var affiliationIds = "";
        for(var i = 0; i < affArr.length; i++){
            if(i == 0){
                affiliationIds += affArr[i].value;
            }else{
                affiliationIds += "," + affArr[i].value;
            }
        }
        params.affiliations = affiliationIds;

        params.zoom_userid = formData.zoom.value;
        params.zoom_email = formData.zoom.email;
        if(params.status == "normal"){
            params.notexposedate = "2100-01-01";
        }
        //console.log(params);

        try {
            const response = await axios.put(apiUrl+'/tutor/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("강사 수정하였습니다.");
                navigate('/AllTeacher');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // form 유효성 검사
    const checkFormData = () => {
        if(formData.name == ""){
            alert("강사명을 입력해주세요.");
            return false;
        }
        if(formData.loginid == ""){
            alert("아이디를 입력해주세요.");
            return false;
        }
        if(mode == "insert"){
            if(formData.password == ""){
                alert("비밀번호를 입력해주세요.");
                return false;
            }
        }
        if(formData.tel == ""){
            alert("연락처를 입력해주세요.");
            return false;
        }
        if(formData.email == ""){
            alert("이메일을 입력해주세요.");
            return false;
        }
        if(formData.lectureid == ""){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.affiliations.length == 0){
            alert("강사소속을 선택해주세요.");
            return false;
        }
        if(formData.status == ""){
            alert("상태를 선택해주세요.");
            return false;
        }else{
            if(formData.status == "secession"){
                if(formData.notexposedate == "" || formData.notexposedate == null){
                    alert("노출하지 않을 날짜를 선택해주세요.");
                    return false;
                }
            }
        }
        if(formData.zoom == "" || formData.zoom == null){
            alert("줌 계정을 선택해주세요.");
            return false;
        }
        if(formData.file == ""){
            alert("프로필을 등록해주세요.");
            return false;
        }

        return true;
    }

    // 파일 업로드
    const uploadImage = async (e) => {
        //console.log(e);
        //console.log(e.target.files[0]);
        var fileInfo = e.target.files[0];
        fileInfo.filename = fileInfo.name;

        var fileData = new FormData();
        fileData.append('attachment', fileInfo);
        fileData.append('folder', 'tutor');

        //console.log(fileData);
        
        try {
            const response = await axios.post(apiUrl+'/file/single', fileData, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setFormData({
                    ...formData,
                    file: res.data.key,
                    originfile: fileInfo.filename,
                    fileurl: '',
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 에디터 이미지 업로드
    const uploadEditorImage = async (e, type) => {
        var fileInfo = e.target.files[0];
        fileInfo.filename = fileInfo.name;

        var fileData = new FormData();
        fileData.append('attachment', fileInfo);
        fileData.append('folder', 'editor');
        
        try {
            const response = await axios.post(apiUrl+'/file/single', fileData, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var encodedKey = encodeURIComponent(res.data.key);
                var imgTag = `<img src='${apiUrl}/file/getRedirectUrl/${encodedKey}'/>`;
                if(type == "intro"){
                    setFormData(prevData => ({
                        ...prevData,
                        intro: formData.intro + imgTag
                    }));
                }else{
                    setFormData(prevData => ({
                        ...prevData,
                        career: formData.career + imgTag
                    }));
                }
                
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 이전 페이지로
    const goBack = () => {
        navigate('/AllTeacher');
    }

    return (
        <>
            <PageTitle  motherMenu={"회원관리"} grandMenu={"강사관리"} grandMenuLink="/AllTeacher" activeMenu={menuName.activeMenu}/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.pageContent}</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="Enter_Name"><span className="danger">*</span> 강사명</label>
                                            <input id="Enter_Name" placeholder="강사명 입력" type="text" 
                                                name="name"
                                                value={formData.name} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="loginid"><span className="danger">*</span> 강사아이디</label>
                                            <input id="loginid" placeholder="강사아이디 입력" type="text" 
                                                name="loginid"
                                                value={formData.loginid} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="password">
                                                {mode === 'insert' && (
                                                <span className="danger">*</span> 
                                                )}
                                                강사 비밀번호
                                            </label>
                                            <input id="password" placeholder="강사 비밀번호 입력" type="password" 
                                                name="password"
                                                value={formData.password} onChange={handleChange}
                                                className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="tel"><span className="danger">*</span> 강사 연락처</label>
                                            <input id="tel" placeholder="강사 연락처 입력" type="text" 
                                                name="tel"
                                                value={formData.tel} onChange={handleChange}
                                                className="form-control phoneNum" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="email"><span className="danger">*</span> 강사 이메일</label>
                                            <input id="email" placeholder="강사 이메일 입력" type="email" 
                                                name="email"
                                                value={formData.email} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="disabilitytype"><span className="danger">*</span> 장애유형</label>
                                            <input id="disabilitytype" placeholder="장애유형 입력" type="text" 
                                                name="disabilitytype"
                                                value={formData.disabilitytype} onChange={handleChange}
                                                className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="lectureType"><span className="danger">*</span> 강의유형</label>
                                            <div className='d-flex gap-2 flex-wrap'>
                                                {lecturetypeData.map((item, index) =>(
                                                    <div key={index} className="form-check form-check-inline">
                                                        <label className="form-check-label"
                                                            htmlFor={`lectureid-${index}`}>
                                                            <input className="form-check-input" type="radio"
                                                                name="lectureid" id={`lectureid-${index}`}
                                                                value={item.lectureid}
                                                                checked={formData.lectureid===item.lectureid}
                                                                onChange={handleChange} />
                                                            {item.lecturetype}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label"><span className="danger">*</span> 강사소속</label>
                                            <div className='d-flex gap-2'>
                                                {affiliationsData.length > 0 && (
                                                    <MultiSelect
                                                        options={affiliationsData}
                                                        value={formData.affiliations}
                                                        onChange={handleAffiliationChange}
                                                        labelledBy="강사소속을 선택해주세요"
                                                        className="w-50 z-10"
                                                        overrideStrings={{
                                                            selectSomeItems: "원하는 항목을 선택해주세요",
                                                            allItemsAreSelected: "모두 선택됨",
                                                            selectAll: "모두 선택",
                                                            search: "검색",
                                                            clearSearch: "검색 지우기"
                                                        }}
                                                    />
                                                )}
                                                <Link to="/BelongRegi/0" className="btn btn-primary light">+ 소속추가</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="status"><span className="danger">*</span> 상태</label>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id='status-normal'
                                                    value='normal'
                                                    checked={formData.status==='normal'}
                                                    onChange={handleChange}
                                                />
                                                <label className="form-check-label" htmlFor='status-normal'>정상</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id='status-secession'
                                                    value='secession'
                                                    checked={formData.status==='secession'}
                                                    onChange={handleChange}
                                                />
                                                <label className="form-check-label" htmlFor='status-secession'>탈퇴</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-3 col-lg-3 col-sm-5">
                                        {formData.status === 'secession' && (
                                        <div className="form-group">
                                            <label className="form-label"><span className="danger">*</span> 노출하지 않을 날짜</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker placeholder="강사를 노출하지 않을 날짜 선택"
                                                    value={formData.notexposedate}
                                                    onChange={handleNotExposeDateChange}
                                                    name="notexposedate" className="picker-suit"
                                                    aria-label="강사를 노출하지 않을 날짜 선택" />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                   
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="disabilitytype"><span className="danger">*</span> 줌 계정</label>
                                            <Select 
                                                isSearchable={false}
                                                className="custom-react-select" 
                                                options={zoomData}
                                                value={formData.zoom}
                                                onChange={handleZoomUserChange}
                                                placeholder="선택"
                                                aria-label="줌 계정 선택"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-5 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label"><span className="danger">*</span> 계약일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="계약일 선택"
                                                    value={formData.contractdate} 
                                                    onChange={handleStartDateChange}
                                                    name="contractdate"
                                                    className="picker-suit"
                                                    aria-label="계약일 시작일 선택"
                                                />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-5">
                                        <div className="form-group">
                                            <label className="form-label"><span className="danger">*</span> 계약 종료일</label>
                                            <div className='d-flex gap-2'>
                                                <div className="col-sm-9 input-hasicon mb-xl-0 mb-3">
                                                    <DatePicker 
                                                        placeholder="계약 종료일 선택" 
                                                        value={formData.enddate} 
                                                        onChange={handleEndDateChange}
                                                        name="enddate"
                                                        className={`picker-suit ${formData.iscontract
                                                            ? 'disabled' : '' }`} 
                                                        disabled={formData.iscontract} 
                                                        aria-label="계약일 종료일 선택"/>
                                                    <div className="icon"><i className="far fa-calendar" /></div>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor='Nodeadline'>
                                                        <input className="form-check-input" type="checkbox"
                                                            name="iscontract" id='Nodeadline' checked={formData.iscontract}
                                                            onChange={handleChange} />
                                                        기한없음
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="note">비고</label>
                                            <textarea id="note" placeholder="비고 입력" 
                                                name="note"
                                                value={formData.note} onChange={handleChange}
                                                className="form-control" rows="5" 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-sm-12">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="file"><span className="danger">*</span> 강사 프로필</label>
                                            <input type="file" id='file' 
                                                className="form-control" 
                                                data-default-file="" 
                                                aria-label="강사 프로필 파일 첨부"
                                                onChange={uploadImage}
                                                required 
                                            />
                                            {formData.fileurl && (
                                                <img src={formData.fileurl} alt={formData.file} className="imagePreview" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="ekeditor">강사 소개</label>
                                            <div className="custom-ekeditor">
                                                <CkEditorBlog 
                                                    data={formData.intro} 
                                                    onChange={handleIntroChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-6 col-sm-12">
                                        <div className="form-group fallback ">
                                            <label className="form-label" htmlFor="editorImage1">강사 소개 에디터 이미지 업로드</label>
                                            <input type="file" id='editorImage1' 
                                                className="form-control" 
                                                data-default-file="" 
                                                onChange={(e) => uploadEditorImage(e, 'intro')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="ekeditor">경력사항</label>
                                            <div className="custom-ekeditor">
                                                <CkEditorBlog 
                                                    data={formData.career}
                                                    onChange={handleCareerChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-6 col-sm-12">
                                        <div className="form-group fallback ">
                                            <label className="form-label" htmlFor="editorImage2">경력사항 에디터 이미지 업로드</label>
                                            <input type="file" id='editorImage2' 
                                                className="form-control" 
                                                data-default-file="" 
                                                onChange={(e) => uploadEditorImage(e, 'career')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>등록</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeacherRegi;