import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DatePicker } from 'rsuite';
import { Link } from 'react-router-dom';
import axios from 'axios';

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;


var lecturetypes = [];

const SurveyRegi = () => {

    const accessToken = useSelector(state => state.auth.auth.accessToken);

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기
    const isEditing = id !== '0'; // ID가 '0'이 아니면 수정 모드, 그렇지 않으면 등록 모드

    useEffect(() => {
        document.title = isEditing ? '만족도 조사 수정' : '만족도 조사 등록'; // 페이지 제목 설정
    }, [isEditing]); // isEditing이 변경될 때마다 제목 업데이트

    var getResearchCnt = 0;

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        lectureid: '',
        affiliationid: '',
        startdate: new Date(),
        enddate: new Date(),
        title: '',
    });
    
    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [affiliationsData, setAffiliationsData] = useState([{label: "선택", value: ""}])

    const [menuName, setMenuName] = useState({
        activeMenu: '만족도 조사 등록하기',
        pageContent: '만족도 조사 등록'
    });

    const [mode, setMode] = useState('insert'); // 초기 모드는 등록 모드로 설정
    const [formExist, setFormExist] = useState(false); 

    useEffect(() => {

        // 수정 모드인 경우 데이터를 가져와서 폼에 채움
        const getUpdateData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse, researchResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                    axios.get(apiUrl + '/research/info/' + id, {
                        headers: {
                            token: accessToken
                        }
                    }),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

                const researchData = researchResponse.data.data;
                //console.log(researchData);

                var selectedAff = {};
                for(var i = 0; i < affiliations.length; i++){
                    if(researchData.affiliationid == affiliations[i].value){
                        selectedAff = {
                            label: affiliations[i].label,
                            value: affiliations[i].value
                        };
                    }
                }
                
                setFormData(prevFormData => ({
                    ...prevFormData,
                    lectureid: researchData.lectureid,
                    affiliationid: selectedAff,
                    startdate: new Date(researchData.startdate),
                    enddate: new Date(researchData.enddate),
                    title: researchData.title,
                }));

                if(researchData.quest.length > 0){
                    setFormExist(true);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        };

        const getInsertData = async () => {
            try {
                const [lecturetypeResponse, affiliationsResponse] = await Promise.all([
                    axios.get(apiUrl + '/lecturetype'),
                    axios.get(apiUrl + '/affiliation'),
                ]);
                
                lecturetypes = lecturetypeResponse.data.data;
                setLecturetypeData(lecturetypes);

                const affiliations = affiliationsResponse.data.data.map(affiliation => ({
                    label: affiliation.name,
                    value: affiliation.affiliationid
                }));

                setAffiliationsData(affiliations);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (id !== "0" && id !== 0) {
            if(getResearchCnt == 0){
                getResearchCnt++;
                setMode('update');
                setMenuName(prevState => ({
                    ...prevState,
                    activeMenu: '만족도 조사 수정하기',
                    pageContent: '만족도 조사 수정'
                }))
                getUpdateData();
            }
        }else{
            getInsertData();
        }
        
    }, [id]);

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'lectureid') {  
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: Number(value)
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    // 만족도 조사 소속 변경 시
    const handleAffiliationChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            affiliationid: e
        }));
    };

    // 날짜 변경 시
    const handleStartDateChange = (e) => {
        setFormData({
            ...formData,
            startdate: e
        });
    };

    // 날짜 변경 시
    const handleEndDateChange = (e) => {
        setFormData({
            ...formData,
            enddate: e
        });
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        
        if(mode === "insert"){
            insertResearch();
        }else{
            updateResearch();
        }
    };

    // 만족도 조사 등록
    const insertResearch = async () => {
        var checkResult = checkFormData();
        if(!checkResult){
            return false;
        }

        var regdate = new Date();
        regdate.setHours(regdate.getHours() + 9);
        regdate = new Date(regdate);

        var params = {...formData};
        params.regdate = regdate;

        var affiliationid = params.affiliationid.value;
        params.affiliationid = affiliationid;

        //console.log(params);
        
        try {
            const response = await axios.post(apiUrl+'/research/create', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("만족도 조사 등록하였습니다.");
                if(formExist){
                    navigate('/Survey');
                }else{
                    navigate('/SurveyForm/' + res.data.researchid);
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 만족도 조사 수정
    const updateResearch = async () => {
        var checkResult = checkFormData();

        if(!checkResult){
            return false;
        }

        var params = {...formData};
        params.researchid = id;

        var affiliationid = params.affiliationid.value;
        params.affiliationid = affiliationid;

        params.startdate = formatDate(params.startdate);
        params.enddate = formatDate(params.enddate);

        try {
            const response = await axios.put(apiUrl+'/research/update', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("만족도 조사 수정하였습니다.");
                navigate('/Survey');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // form 유효성 검사
    const checkFormData = () => {
        if(formData.lectureid == "" || !formData.lectureid){
            alert("강의유형을 선택해주세요.");
            return false;
        }
        if(formData.affiliationid == "" || formData.affiliationid.length == 0){
            alert("회원소속을 선택해주세요.");
            return false;
        }
        if(formData.startdate === null || formData.startdate === ""){
            alert("시작일을 선택해주세요.");
            return false;
        }
        if(formData.enddate === null || formData.enddate === ""){
            alert("종료일을 선택해주세요.");
            return false;
        }
        if(formData.title == ""){
            alert("제목을 입력해주세요.");
            return false;
        }
        return true;
    }

    // 날짜 포맷 변경
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
        const day = String(d.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };

    // 만족도 조사 폼으로 이동
    const moveToSurveyForm = () => {
        if(id != 0 && id != "0"){
            navigate('/SurveyForm/' + id);
        }else{
            insertResearch();
        }
    };

    // 만족도 조사 삭제
    const deleteResearch = async () => {
        var result = window.confirm("만족도 조사를 삭제하시겠습니까?");
        if(result){
            var researchid = id;
            try {
                const response = await axios.delete(apiUrl+'/research/delete/' + researchid, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    alert("만족도 조사 삭제하였습니다.");
                    navigate('/Survey');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 이전 페이지로
    const goBack = () => {
        navigate('/Survey');
    };

    return (
        <>
            <PageTitle  motherMenu={"수업관리"} grandMenu={"만족도 조사 관리"} grandMenuLink="/Survey" activeMenu={menuName.pageContent}/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{menuName.activeMenu}</h5>
                        </div>
                        <div className="card-body">
                            <form action="#" method="post">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label d-block" htmlFor="lectureType">강의유형</label>
                                            {lecturetypeData.map((item, index) =>(
                                                <div key={index} className="form-check form-check-inline">
                                                    <label className="form-check-label"
                                                        htmlFor={`lectureid-${index}`}>
                                                        <input className="form-check-input" type="radio"
                                                            name="lectureid" id={`lectureid-${index}`}
                                                            value={item.lectureid}
                                                            checked={formData.lectureid===item.lectureid}
                                                            onChange={handleChange} />
                                                        {item.lecturetype}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">소속</label>
                                            <div className="custom-react-select">
                                                <Select
                                                    isSearchable={false}
                                                    options={affiliationsData}
                                                    value={formData.affiliationid}
                                                    onChange={handleAffiliationChange}
                                                    placeholder="선택"
                                                    aria-label="회원소속 선택"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">시작일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="시작일 선택"
                                                    value={formData.startdate} 
                                                    onChange={handleStartDateChange}
                                                    name="startdate"
                                                    className="picker-suit"
                                                    aria-label="만족도 조사 시작일 선택"
                                                />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">종료일</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="종료일 선택"
                                                    value={formData.enddate} 
                                                    onChange={handleEndDateChange}
                                                    name="enddate"
                                                    className="picker-suit"
                                                    aria-label="만족도 조사 종료일 선택"
                                                />
                                                <div className="icon"><i className="far fa-calendar" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="title">제목</label>
                                            <input id="title"
                                                type="text" 
                                                className="form-control" 
                                                name="title"
                                                placeholder="제목 입력" 
                                                value={formData.title} onChange={handleChange}
                                                required />
                                            </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="ekeditor">설문지 폼</label>
                                            <div>
                                                <button type="button" onClick={moveToSurveyForm} className="btn btn-primary me-1">
                                                    {formExist ? "상세보기" : "+ 폼 생성"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-light me-1" onClick={goBack}>취소</button>
                                        <button type="button" className="btn btn-primary me-1" onClick={handleSubmit}>등록</button>
                                        {mode != 'insert' && (
                                            <button type="button" onClick={deleteResearch} className="btn btn-danger light me-1" variant="danger">
                                                삭제
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SurveyRegi;