import React, { Fragment, useState, useEffect } from "react";
import { Modal ,Button } from 'react-bootstrap';
import axios from 'axios';
import DaumPostcode from "react-daum-postcode"; // 우편번호 검색 

import PageTitle from '../../layouts/PageTitle';

import config from '../../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const BasicInformation = () => {

    useTitle("기본정보 관리");

    const [addressType, setAddressType] = useState('business');

    const [formData, setFormData] = useState({
        address: "",
        bisunessnumber: "",
        ceo: "",
        company: "",
        detailaddress: "",
        email: "",
        favicon: "",
        fax: "",
        logo: "",
        mailordersales: "",
        office_address: "",
        office_detailaddress: "",
        recipientemail: "",
        securitymanager: "",
        senderemail: "",
        sitename: "",
        siteurl: "",
        tel: "",
    });

    useEffect(() => {

        // 사이트 정보 가져오기
        const getSiteInfo = async () => {
            try {
                const response = await axios.get(apiUrl+'/site');
                //console.log(response.data.data);
                setFormData(response.data.data);
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

        getSiteInfo();
        
    }, []);

    // 우편번호 검색
    const [modalCentered, setModalCentered] = useState(false);
    const openPostSearch = (e) => {
        //console.log(e.target.name);
        setAddressType(e.target.name);
        setModalCentered(true);
    };

    // 주소 선택 시
    const handleAddress = (data) => {
        //console.log(data);
        let fullAddress = data.address;
        /*
        // 추가 정보
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress +=
                    extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }
        */

        var columnname = "";

        if(addressType === "business"){
            columnname = "address";
        }else{
            columnname = "office_address";
        }

        setFormData({
            ...formData,
            [columnname]: fullAddress
        });

        setModalCentered(false);
    };

    // formdata 변경 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'lectureid') {  
           setFormData({
              ...formData,
              [name]: Number(value)
           });
        } else {
           setFormData({
              ...formData,
              [name]: value
           });
        }
    };

    // 저장
    const handleSubmit = async (e) => {
        //console.log(id)
        if(formData.sitename === ""){
            alert("사이트 이름을 입력해주세요.");
            return false;
        }
        if(formData.siteurl === ""){
            alert("사이트주소를 입력해주세요.");
            return false;
        }
        if(formData.company === ""){
            alert("회사명을 입력해주세요.");
            return false;
        }
        if(formData.bisunessnumber === ""){
            alert("사업자등록번호를 입력해주세요.");
            return false;
        }
        if(formData.ceo === ""){
            alert("대표자 성명을 입력해주세요.");
            return false;
        }
        updateSiteInfo();
    };

    // 기본정보 수정
    const updateSiteInfo = async () => {
        var params = {...formData};
        //console.log(params)

        try {
            const response = await axios.put(apiUrl+'/site/update', params);
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("기본정보 수정하였습니다.");
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <PageTitle  motherMenu={"홈페이지관리"} activeMenu={"기본정보 설정"}/>
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header flex-column align-items-baseline">
                            <h5 className="card-title">기본설정</h5>
                            <p>사이트의 기본정보를 입력하는 곳입니다.</p>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="sitename">사이트 이름</label>
                                            <input id="sitename" placeholder="사이트 이름 입력" type="text" 
                                                value={formData.sitename} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="siteurl">사이트 주소</label>
                                            <input id="siteurl" name="siteurl" placeholder="사이트 주소 입력" type="text" 
                                                value={formData.siteurl} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="senderemail">발신자 이메일</label>
                                            <input id="senderemail" name="senderemail" placeholder="발신자 이메일 입력" type="email" 
                                                value={formData.senderemail} onChange={handleChange} className="form-control" required />
                                            <p>*자동으로 이메일이 발송될 때 발송자로 지정됩니다.</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="recipientemail">수신자 이메일</label>
                                            <input id="recipientemail" name="recipientemail" placeholder="수신자 이메일 입력" type="email" 
                                                value={formData.recipientemail} onChange={handleChange} className="form-control" required />
                                            <p>*이메일 문의의 수신자로 지정됩니다.</p>
                                        </div>
                                    </div>
                                    {/* 
                                    <div className="col-sm-6 ">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="logo">로고 이미지</label>
                                            <input type="file" id="logo" name="logo" className="form-control" 
                                                data-default-file="" required aria-label="로고 이미지 파일 첨부" accept="image/jpeg, image/png"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group fallback w-100">
                                            <label className="form-label" htmlFor="favicon">파비콘 이미지</label>
                                            <input type="file" id="favicon" name="favicon" className="form-control" 
                                                data-default-file="" required aria-label="파비콘 이미지 파일 첨부" accept="image/jpeg, image/png, image/x-icon"/>
                                        </div>
                                    </div>
                                    */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header flex-column align-items-baseline">
                            <h5 className="card-title">회사정보</h5>
                            <p>사이트 화면 하단의 카피라이트 부문에 표시 되는 정보입니다.</p>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="company">회사명</label>
                                            <input id="company" name="company" placeholder="회사명 입력" type="text" 
                                                value={formData.company} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="bisunessnumber">사업자등록번호</label>
                                            <input id="bisunessnumber" name="bisunessnumber" placeholder="사업자등록번호 입력" type="text" 
                                                value={formData.bisunessnumber} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="address">주소 (법인)</label>
                                            <div className="d-flex gap-2 mb-2">
                                                <input id="address" placeholder="우편번호 검색버튼을 클릭해주세요" 
                                                    name="address" value={formData.address} onChange={handleChange} 
                                                    type="text" className="form-control w-50" required readOnly/>
                                                <button type="button" name="business" className="btn btn-primary me-1" onClick={openPostSearch}>우편번호 검색</button>
                                            </div>
                                            <input id="detailaddress" name="detailaddress" placeholder="상세주소 입력" type="text" 
                                                value={formData.detailaddress} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="office_address">주소 (사무국)</label>
                                            <div className="d-flex gap-2 mb-2">
                                                <input id="office_address" placeholder="우편번호 검색버튼을 클릭해주세요" 
                                                    name="office_address" value={formData.office_address} onChange={handleChange} 
                                                    type="text" className="form-control w-50" required readOnly/>
                                                <button type="button" name="office" className="btn btn-primary me-1" onClick={openPostSearch}>우편번호 검색</button>
                                            </div>
                                            <input id="office_detailaddress" name="office_detailaddress" placeholder="상세주소 입력" type="text" 
                                                value={formData.office_detailaddress} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="ceo">대표자 성명</label>
                                            <input id="ceo" name="ceo" placeholder="대표자 성명 입력" type="text" 
                                                value={formData.ceo} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="securitymanager">개인정보담당자</label>
                                            <input id="securitymanager" name="securitymanager" placeholder="개인정보담당자 입력" type="text" 
                                                value={formData.securitymanager} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="tel">전화번호</label>
                                            <input id="tel" name="tel" placeholder="전화번호 입력" type="text" 
                                                value={formData.tel} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fax">팩스번호</label>
                                            <input id="fax" name="fax" placeholder="팩스번호 입력" type="text" 
                                                value={formData.fax} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="email">이메일</label>
                                            <input id="email" name="email" placeholder="이메일 입력" type="email" 
                                                value={formData.email} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="mailordersales">통신판매업</label>
                                            <input id="mailordersales" name="mailordersales" placeholder="통신판매업 입력" type="text" 
                                                value={formData.mailordersales} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
                <button type="button" className="btn btn-primary me-1" onClick={handleSubmit}>등록</button>
            </div>

            {/* 우편번호 검색 모달창 */}
            <Modal className="fade reasonmodal" show={modalCentered} onHide={setModalCentered} centered>
                <Modal.Header>
                    <Modal.Title>우편번호 검색</Modal.Title>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="btn-close"
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <DaumPostcode onComplete={handleAddress} height="100%"/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BasicInformation;